import React, { useState, useEffect } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import { getAllContractInformationById } from '../../../../controllers/ContractController'
import { Holder, HolderWork, Beneficiary, PaymentSupport, PaymentModality, Contract, NotePayable, Reference, VerificationReport, User } from '../../../../interface/interface'
import { genericErrorAlert, genericSuccessAlert } from '../../../../services/alertService'
import VerificationHolderSection from '../verification-form-sections/VerificationHolderSection'
import VerificationHolderWorkSection from '../verification-form-sections/VerificationHolderWorkSection'
import VerificationBeneficiarySection from '../verification-form-sections/VerificationBeneficiarySection'
import VerificationReferenceSection from '../verification-form-sections/verification-reference/VerificationReferenceSection'
import VerificationPaymentModality from '../verification-form-sections/VerificationPaymentModality'
import VerificationFinalReport from '../verification-form-sections/VerificationFinalReport'
import NotePayableForm from '../../../contract/contract-sections/NotePayableForm'
import PaymentSupportForm from '../../../contract/contract-sections/PaymentSupportForm'
import { getCookieValue } from '../../../../services/cookieService'
import PaymentSupportCard from '../../../contract/cards/PaymentSupportCard'
import NotePayableCard from '../../../contract/cards/NotePayableCard'
import { verifyContract } from '../../../../controllers/VerifyAssigmentController'
import Swal from 'sweetalert2'

interface Props {
  selectedContract: string,
  userResponsibleName: string
}

const VerificationForm: React.FC<Props> = ({ selectedContract, userResponsibleName }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [user, setUser] = useState<User | null>(null);

  const [holder, setHolder] = useState<Holder>();
  const [holderWork, setHolderWork] = useState<HolderWork>();
  const [beneficiaryList, setBeneficiaryList] = useState<Array<Beneficiary>>([])
  const [paymentSupport, setPaymentSupport] = useState<PaymentSupport>();
  const [paymentModality, setPaymentModality] = useState<PaymentModality>();
  const [contract, setContract] = useState<Contract>();

  const [notePayable, setNotePayable] = useState<NotePayable>({
    city_id: 0,
    holder_signature: "",
  })

  const [referenceOne, setReferenceOne] = useState<Reference>()
  const [referenceTwo, setReferenceTwo] = useState<Reference>()

  const [editModality, setEditModality] = useState<boolean>(true);

  const [defaultPaymentSupport, setDefaultPaymentSupport] = useState<PaymentSupport>();
  const [defaultPaymentModality, setDefaultPaymentModality] = useState<PaymentModality>();
  const [defaultNotePayable, setDefaultNotePayable] = useState<NotePayable>({
    city_id: 0,
    holder_signature: "",
  });

  const [verificationReport, setVerificationReport] = useState<VerificationReport>({ observation: "", holder_signature: "", status: false });



  const handleEditModality = () => {

    //Reinicia los valores a los valores del contracto 
    if (!editModality) {

      setPaymentSupport(defaultPaymentSupport);
      setNotePayable(defaultNotePayable);
      setPaymentModality({ ...defaultPaymentModality as PaymentModality, id: "Personalizado" });

    } else {
      setPaymentSupport({ ...defaultPaymentSupport as PaymentSupport, responsible_user_signature: "", description: "" });
      setNotePayable({ ...defaultNotePayable, holder_signature: "" });
    }

    console.log(paymentModality)

    setEditModality(!editModality);
  }

  const [fullName, setFullName] = useState("");

  useEffect(() => {

    const cookie = getCookieValue("nbp-management-auth");

    const { user } = cookie;
    setFullName(user?.first_name + " " + user?.second_name + " " + user?.last_name + " " + user?.second_last_name);

  }, [])

  useEffect(() => {

    if (!selectedContract) return

    loadContractInformation();
    setEditModality(true);
  }, [selectedContract])


  useEffect(() => {

    const cookie = getCookieValue("nbp-management-auth");

    const { user } = cookie;
    setUser(user);

  }, [])

  const loadContractInformation = async () => {

    try {

      setIsLoading(true);

      const contractInformation = await getAllContractInformationById(selectedContract);
      const { holder, holder_work, beneficiary, payment_support, reference, payment_modality, contract, note_payable } = contractInformation.data;

      setHolder(holder[0]);
      setHolderWork(holder_work[0]);
      setBeneficiaryList(beneficiary)
      setPaymentSupport(payment_support[0]);
      setReferenceOne(reference[0]);
      setReferenceTwo(reference[1])
      setPaymentModality(payment_modality[0]);
      setContract(contract[0]);

      if (note_payable[0]) {
        setNotePayable(note_payable[0]);
        setDefaultNotePayable(note_payable[0]);
      } else {
        setNotePayable({
          city_id: 0,
          holder_signature: "",
        });
        setDefaultNotePayable({
          city_id: 0,
          holder_signature: "",
        });
      }

      setDefaultPaymentModality(payment_modality[0]);
      setDefaultPaymentSupport(payment_support[0]);

    } catch (error: any) {

      const { title, message } = error.response.data;
      genericErrorAlert(title, message);

    } finally {

      setIsLoading(false);

    }

  }

  const verifyAllSignature = () => {

    const noCompleteSignature = []

    if (verificationReport.holder_signature === "") {

      noCompleteSignature.push("Firma del verificación (Titular)")

    }

    if (paymentSupport?.responsible_user_signature.trim() === "") {
      noCompleteSignature.push("Firma del seporte del pago  (Empleado)")
    }

    if (contract?.type === "Normal" && (paymentModality as PaymentModality).installments_number > 1 && notePayable.holder_signature.trim() === "") {
      noCompleteSignature.push("Firma del pagaré (Titular)")

    }

    if (noCompleteSignature.length > 0) {
      completeAllSignature(noCompleteSignature.join(" - "))
      return false
    }

    return true

  }

  const completeAllSignature = (information: string) => {

    Swal.fire({
      icon: 'error',
      title: 'Completa todas las <b>firmas</b> antes de enviar el formulario',
      text: information,
    })
  }


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    try {

      setIsSaving(true);

      if (!verifyAllSignature()) {
        return
      }

      await verifyContract(user as User, holder as Holder, holderWork as HolderWork, contract as Contract, 0, beneficiaryList, referenceOne as Reference, referenceTwo as Reference, paymentModality as PaymentModality, paymentSupport as PaymentSupport, notePayable as NotePayable, verificationReport)

      genericSuccessAlert("Verificación realizada", "La información fue verificada correctamente");

      setTimeout(() => {
        window.location.reload()
      }, 2000)

    } catch (error: any) {

      console.log(error);

      genericErrorAlert("Error al verificar el contrato", "Se presento un problema al intentar verificar el contrato");
      setIsSaving(false);
    }

  }

  const handleSubmitTest = async () => {


    try {

      setIsSaving(true);

      if (!verifyAllSignature()) {
        return
      }

      await verifyContract(user as User, holder as Holder, holderWork as HolderWork, contract as Contract, 0, beneficiaryList, referenceOne as Reference, referenceTwo as Reference, paymentModality as PaymentModality, paymentSupport as PaymentSupport, notePayable as NotePayable, verificationReport)

    } catch (error: any) {
      console.log(error)

      genericErrorAlert("Error", error + " ")
    } finally {
      setIsSaving(false);
    }

  }

  return (
    <Card className={CARD_BODY + "my-2 m-2"}>

      <CardHeader className={CARD_TITLE + "text-center"} tag="h3">
        <b className='p-0 m-0'>CONTRATO SELECCIONADO</b>
        {/*<Button color='warning' onClick={() => handleSubmitTest()}>Testing</Button> */}
      </CardHeader>

      <CardBody>

        {!isLoading ? (<div>
          <form onSubmit={handleSubmit}>

            {holder && <VerificationHolderSection holder={holder} setHolder={setHolder} />}
            {holderWork && <VerificationHolderWorkSection holderWork={holderWork} setHolderWork={setHolderWork} />}
            {beneficiaryList && <VerificationBeneficiarySection beneficiaries={beneficiaryList} setBeneficiaries={setBeneficiaryList} />}
            {referenceOne && referenceTwo && <VerificationReferenceSection referenceOne={referenceOne} setReferenceOne={setReferenceOne} referenceTwo={referenceTwo} setReferenceTwo={setReferenceTwo} />}

            {paymentModality && defaultPaymentModality &&
              <VerificationPaymentModality
                paymentModality={paymentModality}
                setPaymentModality={setPaymentModality}
                editModality={editModality}
                handleEditModality={handleEditModality}
                setDefaultPaymentModality={setDefaultPaymentModality}
                defaultPaymentModality={defaultPaymentModality} />
            }

            {
              contract?.type === "Normal" ? (null) : (<div> {editModality ? (
                <div>
                  {defaultPaymentModality && defaultPaymentSupport && <PaymentSupportCard userFullName={userResponsibleName} paymentModality={defaultPaymentModality} paymentSupport={defaultPaymentSupport} />}
                  {defaultNotePayable && defaultPaymentSupport && defaultPaymentModality && ((defaultPaymentModality as PaymentModality).installments_number > 1) ? <NotePayableCard notePayable={defaultNotePayable} paymentModality={defaultPaymentModality} /> : null}
                </div>
              ) : (<div>

                {paymentSupport && paymentModality && <PaymentSupportForm paymentSupport={paymentSupport} setPaymentSupport={setPaymentSupport} userFullName={fullName} amount={paymentModality.installments_number === 1 ? (paymentModality.enrollment) : (paymentModality.installments_value)} />}
                {paymentModality && (paymentModality.installments_number > 1) && notePayable && <NotePayableForm notePayable={notePayable} paymentModality={paymentModality} setNotePayable={setNotePayable} />}

              </div>)}</div>)
            }

            <VerificationFinalReport verificationReport={verificationReport} setVerificationReport={setVerificationReport} />

            <Button type='submit' color='warning' className='col-12' disabled={isSaving}><b>{isSaving ? "ENVIANDO VERIFICACIÓN" : "ENVIAR VERIFICACÓN"}</b></Button>

          </form>
        </div>) :
          (<div>
            <Alert color="warning" className="text-center m-3">
              <b className="h2">{!selectedContract ? "Seleccione un contrato a verificar" : "Cargando contrato..."}</b>
            </Alert>
          </div>)}

      </CardBody>

    </Card >
  )
}

export default VerificationForm
