import React from 'react'
import { Row, Col, Spinner } from 'reactstrap'

interface Props {
    status: boolean
    text: string,
    loadingText: string,
    color?: string
    size?: string
}

const LoadingStatus: React.FC<Props> = ({ status, text, loadingText, color = "light", size = "sm" }) => {
    return (
        <Row>
            <Col>
                {status ? <div>  <Spinner className='mx-2' color={color} size={size} /><b>{loadingText}</b> </div> : <div><b>{text}</b></div>}
            </Col>
        </Row>
    )
}

export default LoadingStatus
