import { useEffect, useState } from 'react'
import LeadsForm from '../leads-form/leads-form-main/LeadsForm'
import { Container } from 'reactstrap'
import { Leads } from '../../../interface/interface'
import { getAllUnssignmentLeads } from '../../../controllers/LeadsController'
import LeadsAssignmentReport from '../leads-report/leads-report-type/leads-assignment/LeadsAssignmentReport'

const LeadsManager = () => {

    const [leadsList, setLeadsList] = useState<Array<Leads>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {


        loadUnssignmentLeads();

    }, [])

    const loadUnssignmentLeads = async () => {

        const loadList = await getAllUnssignmentLeads();
        setLeadsList(loadList.data);
        setIsLoading(false);

    }


    return (
        <Container fluid className='my-3'>
            <LeadsForm loadUnssignmentLeads={loadUnssignmentLeads} />
            <LeadsAssignmentReport leadsList={leadsList} isLoading={isLoading} loadUnssignmentLeads={loadUnssignmentLeads} />
        </Container>
    )
}

export default LeadsManager