export const contractStatusDictionary: { [key: string]: string } = {
    'Validar-Pago': 'bg-danger ',
    'Crear-Usuario': 'bg-success ',
    'Pendiente-Verificación': 'bg-warning ',
    'Activo': 'bg-indigo',
    'Información-Incompleta': 'bg-orange ',
    'Terminado': 'bg-primary ',
    'Anulado': 'bg-dark ',
    'Desactivado': 'bg-magenta ',
    'Verificación-Aprobada':'bg-light-purple ',
    'Verificación-Rechazada':'bg-light-orange ',
};

