import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, FormText, Input } from 'reactstrap';
import { contractStatusDictionary } from '../../../dictionary/Dictionary';
import { changeContractStatus } from '../../../controllers/ContractController';
import Swal from 'sweetalert2';
import { getAllCheckerUser, verificationStatusInit } from '../../../controllers/VerifyAssigmentController';
import { genericErrorAlert, handleErrorResponseAlert } from '../../../services/alertService';
import { User } from '../../../interface/interface';
import { getCookieValue } from '../../../services/cookieService';
import LoadingStatus from '../../utils/LoadingStatus';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  contractId: string;
  contractSerial: string;
  contractStatus: string;
  checkerList?: Array<any>;
  contractList?: Array<any>;
  setContractList?: (contractList: Array<any>) => void;
}

const ContractModalChangeStatus: React.FC<Props> = ({ isOpen, toggle, contractId, contractSerial, contractStatus, checkerList = [], contractList = [], setContractList = () => { } }) => {

  const [contractStatusValue, setContractStatusValue] = useState({ contractCurrentStatus: contractStatus, checkerSelected: "" });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>();


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContractStatusValue({ ...contractStatusValue, [name]: value });
  };

  useEffect(() => {

    const cookie = getCookieValue("nbp-management-auth");
    const { user } = cookie;
    setCurrentUser(user);

  }, [])

  const updatedVisualContract = (contract: any) => {

    const updatedAttendanceIndex = contractList.findIndex((updatedAttendance) => updatedAttendance.id == contractId);

    if (updatedAttendanceIndex === -1) {
      return contractList;
    }


    const updatedAttendance = [...contractList];
    updatedAttendance.splice(updatedAttendanceIndex, 1, contract);
    setContractList(updatedAttendance);

  }

  const handleSaveStatus = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    try {

      setIsSaving(true)

      if (contractStatusValue.contractCurrentStatus === 'Pendiente-Verificación') {

        const user = contractStatusValue.checkerSelected.split(",");
        await verificationStatusInit(contractId, user[0], user[1]);

      } else {

        await changeContractStatus(contractId, contractStatusValue.contractCurrentStatus);

      }

      const value = contractList.filter(contract => contract.id == contractId);
      updatedVisualContract({ ...value[0], status: contractStatusValue.contractCurrentStatus })


      Swal.fire(
        'Actualizado',
        'Estado del contrato actualizado...',
        'success'
      )


    } catch (error) {
      console.log(error)
      handleErrorResponseAlert(error, "Error al cambiar estado de contrato", "Intentelo nuevamente");

    } finally {
      setIsSaving(false)
    }

  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <form onSubmit={handleSaveStatus}>

        <ModalHeader className={contractStatusDictionary[contractStatusValue.contractCurrentStatus] + " text-white"} toggle={toggle}>
          Contrato N° - {contractSerial}
        </ModalHeader>
        
        <ModalBody>
          <FormGroup>
            <Input type="select" name="contractCurrentStatus" id="contractStatus" value={contractStatusValue.contractCurrentStatus} onChange={handleInputChange} >
              <option value='Validar-Pago'>Validar Pago</option>
              <option value='Crear-Usuario'>Crear Usuario</option>
              <option value='Pendiente-Verificación'>Pendiente Verificación</option>
              <option value='Activo'>Activo</option>
              <option value='Información-Incompleta'>Información Incompleta</option>
              <option value='Terminado'>Terminado</option>
              <option value='Anulado'>Anulado</option>
              <option value='Desactivado'>Desactivado</option>
            </Input>
            <FormText>
              Estado del contrato
            </FormText>
          </FormGroup>
          {contractStatusValue.contractCurrentStatus == 'Pendiente-Verificación' ? (<FormGroup>
            <Input type="select" name="checkerSelected" id="contractStatus" value={contractStatusValue.checkerSelected} onChange={handleInputChange} required >

              <option value=''>{checkerList.length !== 0 ? ("Seleccione un verificador") : ("No hay verificadores registrados")}</option>
              <option value={currentUser?.document_type + "," + currentUser?.document_number}>{"(" + currentUser?.document_type + " - " + currentUser?.document_number + " ) - " + currentUser?.first_name + " " + currentUser?.second_name + " " + currentUser?.last_name + " " + currentUser?.second_last_name}</option>

              {checkerList.map((user, index) =>
                <option key={index} value={user.document_type + "," + user.document_number}>{"(" + user.document_type + " - " + user.document_number + " ) - " + user.first_name + " " + user.second_name + " " + user.last_name + " " + user.second_last_name}</option>)}

            </Input>
            <FormText>
              Verificador
            </FormText>
          </FormGroup>) : (null)}
        </ModalBody>
        <ModalFooter className='text-center'>
          <Button type="submit" color="primary" disabled={isSaving}>
            <LoadingStatus status={isSaving} text='Guardar' loadingText='Cambiando estado'></LoadingStatus>
          </Button>
          <Button type='button' color='secondary' onClick={toggle} disabled={isSaving} >
            <b>Cancelar</b>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ContractModalChangeStatus
