import React, { useEffect, useState } from 'react'
import { validateToken } from '../../../../services/authService';
import { getCookieValue } from '../../../../services/cookieService';
import { useNavigate } from 'react-router-dom';
import PaymentModalityOptions from '../options-section/PaymentModalityOptions';

const ContractOptionsManager = () => {

    const navigate = useNavigate();

    return (
        <div>
            <PaymentModalityOptions />
        </div>
    )
}

export default ContractOptionsManager