import React from 'react'
import { Card, CardHeader, CardBody, Row, Button, Col, Table, Alert } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'
import { ProspectMeeting } from '../../../interface/interface';
import PBOPill from '../pbo-custom-components/PBOPill';
import { dateformatISO } from '../../../utils/dateUtils';

interface Props {
    prospectMeetingList: Array<ProspectMeeting>;
}

const VirtualMeeting: React.FC<Props> = ({ prospectMeetingList }) => {
    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>REUNIONES VIRTUALES</b>
            </CardHeader>
            <CardBody>
                <Row>

                    {(prospectMeetingList.length === 0) ? (
                        <Col>
                            <Alert color="warning" className="text-center m-3">
                                <b className="h2">No tiene ninguna reunión registrada </b>
                            </Alert>
                        </Col>) : (<Col>

                            <Table striped responsive className='text-center' hover>
                                <thead>
                                    <tr>
                                        <th className='text-center'>#</th>
                                        <th className='text-center'><b>Modalidad</b></th>
                                        <th className='text-center'><b>Fecha</b></th>
                                        <th className='text-center'><b>Hora</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prospectMeetingList.map((meeting, index) => (
                                        <tr key={index}>
                                            <td className='text-center align-middle'>{index + 1}</td>
                                            <td className='text-center align-middle'>{meeting.modality}</td>
                                            <td className='text-center align-middle'>{dateformatISO(meeting.date)}</td>
                                            <td className='text-center align-middle'>{meeting.hour}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>)}

                </Row>
            </CardBody>
        </Card>
    )
}

export default VirtualMeeting
