import React, { useState } from 'react'
import { Container, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, Button } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../services/classService'
import LoadingStatus from '../utils/LoadingStatus'
import { completeAllInputsAlert, genericSuccessAlert, handleErrorResponseAlert } from '../../services/alertService'
import Swal from 'sweetalert2'
import { generictEmailTemplate } from '../../email-templates/GenericEmail'
import DOMPurify from 'dompurify';
import { sendMasiveBeneficiaryActiveEmail, sendTestingGenericEmail } from '../../controllers/EmailController'

const EmailManager = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [emailValues, setEmailValues] = useState({ to: "", subject: "", title: "", description: "", subDescription: "", permissionCode: "" });
  const [sendingTest, setSendingTest] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    Swal.fire({
      title: '¿Esta seguro de realizar el envio del correo electronico?',
      text: "Una vez confirmado, no se podra cancelar el proceso.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);

          switch (emailValues.to) {

            case "active-beneficiary":
              await sendMasiveBeneficiaryActiveEmail(emailValues.subject, emailValues.title, emailValues.description, emailValues.subDescription, emailValues.permissionCode);

              break;
          }

          genericSuccessAlert("Correo enviado con exito", "")

        } catch (error: any) {

          handleErrorResponseAlert(error, "Error al enviar el correo electronico", "Intentelo nuevamente");
          console.log(error);

        } finally {
          setLoading(false);
        }
      }
    })
  }

  const handleSendTestEmail = async () => {

    if (!verifyConfirmEmailValues()) return

    Swal.fire({
      title: '¿Esta seguro de realizar el envio del correo de confirmación?',
      text: "Una vez enviado, no se podra cancelar el proceso.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {

          setSendingTest(true);

          await sendTestingGenericEmail(emailValues.subject, emailValues.title, emailValues.description, emailValues.subDescription);

          genericSuccessAlert("Correo de confirmación enviado con exito", "Ingrese a su correo y obtenga en código suministrado")

        } catch (error) {
          console.log(error);
          handleErrorResponseAlert(error, "Error al enviar correo de confirmación", "Intentelo nuevamente");
        } finally {

          setSendingTest(false);

        }
      }
    })


  }

  const verifyConfirmEmailValues = () => {

    const noCompleteInputs = [];

    if (emailValues.subject.trim() === "") {
      noCompleteInputs.push("Asunto");
    }

    if (emailValues.title.trim() === "") {
      noCompleteInputs.push("Título");
    }

    if (emailValues.description.trim() === "") {
      noCompleteInputs.push("Descripción");
    }

    if (noCompleteInputs.length > 0) {
      completeAllInputsAlert("Envio de correo de confirmación", noCompleteInputs.join(" - "))
    }

    if (noCompleteInputs.length === 0) {
      return true
    }

    return false;
  }

  const completeAllInputsAlert = (title: string, information: string) => {
    title = "<b>" + title + "</b>"
    Swal.fire({
      icon: 'error',
      title: 'Completa todos campos del ' + title,
      text: information,
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Usa DOMPurify para eliminar código HTML no permitido
    const sanitizedValue = DOMPurify.sanitize(value);

    if (value.includes("<") || value.includes(">")) return

    if (name === "description" || name === "subDescription") {
      if (sanitizedValue.length <= 360) {
        setEmailValues({ ...emailValues, [name]: sanitizedValue });
      }
    } else {
      setEmailValues({ ...emailValues, [name]: sanitizedValue });
    }
  };






  return (
    <Container fluid className='pb-2'>

      <Card className={CARD_BODY + "mt-3"}>
        <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
          <b>GENERACIÓN DE CORREOS</b>
        </CardHeader>

        <CardBody>
          <Row>
            <Col className='col-12'>
              <form onSubmit={handleSubmit}>
                <Row className='mb-3'>
                  <Col md={4} sm={12} className='d-flex justify-content-center align-items-center'>
                    <Row>

                      <Col className='col-12 text-center'>
                        <b className='text-white' style={{ fontSize: '24px' }}>INFORMACIÓN DEL CORREO</b>
                        <hr style={{ background: 'white', color: 'white', borderColor: 'white', height: '3px' }} />
                      </Col>

                      <Col className='col-12'>
                        <FormGroup>

                          <Input type='text' name='subject' value={emailValues.subject} onChange={handleInputChange} required />
                          <FormText>
                            Asunto del correo (*)
                          </FormText>

                        </FormGroup>
                      </Col>

                      <Col className='col-12'>
                        <FormGroup>

                          <Input type='text' name='title' value={emailValues.title} onChange={handleInputChange} required />
                          <FormText>
                            Título(*)
                          </FormText>

                        </FormGroup>
                      </Col>

                      <Col className='col-12'>
                        <FormGroup>

                          <Input type='textarea' name='description' value={emailValues.description} onChange={handleInputChange} required />
                          <FormText>
                            Descripción (*) Caracteres restantes: {360 - emailValues.description.length}
                          </FormText>
                        </FormGroup>
                      </Col>

                      <Col className='col-12'>
                        <FormGroup>

                          <Input type='textarea' name='subDescription' value={emailValues.subDescription} onChange={handleInputChange} />
                          <FormText>
                            Sub Descripción - Caracteres restantes: {360 - emailValues.subDescription.length}
                          </FormText>
                        </FormGroup>
                      </Col>

                      <Col className='col-12 mb-2' >
                        <Button type="button" color="success" className='col-12' disabled={sendingTest} onClick={() => handleSendTestEmail()}>
                          <LoadingStatus status={sendingTest} text='Enviar correo de confirmación' loadingText='Enviando correo de confirmación'></LoadingStatus>
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={8}>
                    <Card>
                      <CardBody style={{ overflow: 'hidden', maxHeight: '500px', overflowY: 'auto', overflowX: 'auto' }}>
                        <div dangerouslySetInnerHTML={{ __html: generictEmailTemplate(emailValues.title, emailValues.description, emailValues.subDescription) }} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr style={{ background: 'white', color: 'white', borderColor: 'white', height: '3px' }} />
                <Row>

                  <FormGroup>

                    <Col className='col-12'>
                      <Input type='select' name='to' value={emailValues.to} onChange={handleInputChange} required>
                        <option value="">Seleccione un destinatario</option>
                        <option value="active-beneficiary">Beneficiarios activos</option>
                      </Input>
                      <FormText>
                        Destinatario (*)
                      </FormText>
                    </Col>

                  </FormGroup>

                  <Col className='col-12'>
                    <FormGroup>
                      <Input type='text' name='permissionCode' value={emailValues.permissionCode} onChange={handleInputChange} required />
                      <FormText>
                        Código de confirmación(*)
                      </FormText>
                    </FormGroup>
                  </Col>

                  <Col className='col-12'>
                    <Button type="submit" color="warning" className='col-12' disabled={loading}>
                      <LoadingStatus status={loading} text='Enviar correo' loadingText='Enviando correo'></LoadingStatus>
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </CardBody>
      </Card>


    </Container>
  )
}

export default EmailManager