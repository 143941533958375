import React from 'react'
import { Leads } from '../../../../../../interface/interface'
import { Button, Col, Label, Row } from 'reactstrap'
interface Props {
    index: number,
    leads: Leads,
    setSelectedLead: (leadId: number) => void
}

const ComercialLeadsRowTable: React.FC<Props> = ({ index, leads, setSelectedLead }) => {

    const handleSelectedLead = (id: number) => {

        setSelectedLead(id)
        console.log(leads)

    }

    return (
        <tr key={index}>
            <td>
                <p><b>{index + 1}</b></p>
            </td>
            <td>
                <p>{leads.first_name + " " + leads.second_name + " " + leads.last_name + " " + leads.second_last_name}</p>
            </td>
            <td>
                <p>{leads.phone_number}</p>
            </td>
            <td>
                <p>{leads.email}</p>
            </td>
            <td>
                <p>{leads.origin}</p>
            </td>
            <td>
                <p>{leads.observation}</p>
            </td>
            <td>
                <Button color="primary" title='Crear prospecto' onClick={() => handleSelectedLead(leads.id as number)}>
                    <i className="bi bi-clipboard-fill"></i>
                </Button>
            </td>
        </tr>
    )
}

export default ComercialLeadsRowTable
