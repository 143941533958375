import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Container } from 'reactstrap';
import { Beneficiary, Contract, Holder, HolderWork, NotePayable, PaymentModality, PaymentSupport, Reference } from '../../../interface/interface';
import HolderCard from '../cards/HolderCard';
import BeneficiaryCard from '../cards/BeneficiaryCard';
import PaymentSupportCard from '../cards/PaymentSupportCard';
import PaymentModalityCard from '../cards/PaymentModalityCard';
import ContractCard from '../cards/ContractCard';
import HolderWorkCard from '../cards/HolderWorkCard';
import ReferenceCard from '../cards/ReferenceCard';
import ContractStatusInfo from '../contract-status/ContractStatusInfo';
import { genericErrorAlert } from '../../../services/alertService';
import { getAllContractInformationById } from '../../../controllers/ContractController';
import NotePayableCard from '../cards/NotePayableCard';

interface Props {
  contractId: string;
  contractSerial: string
  isOpen: boolean;
  toggleModal: () => void;
  userFullName: string
  contractStatus: string
  canEdit?: boolean
}

const ContractModalInformation: React.FC<Props> = ({ contractSerial, contractId, isOpen, toggleModal, userFullName, contractStatus, canEdit = false }) => {

  const [isLoading, setIsLoading] = useState(false);

  const [holder, setHolder] = useState<Holder>();
  const [holderWork, setHolderWork] = useState<HolderWork>();
  const [beneficiaryList, setBeneficiaryList] = useState<Array<Beneficiary>>([])
  const [paymentSupport, setPaymentSupport] = useState<PaymentSupport>();
  const [paymentModality, setPaymentModality] = useState<PaymentModality>();
  const [contract, setContract] = useState<Contract>();
  const [notePayable, setNotePayable] = useState<NotePayable>();
  const [referenceList, setReferenceList] = useState<Array<Reference>>([]);


  useEffect(() => {

    loadContractInformation();

  }, [])

  const loadContractInformation = async () => {

    try {

      setIsLoading(true);

      const contractInformation = await getAllContractInformationById(contractId);
      const { holder, holder_work, beneficiary, payment_support, reference, payment_modality, contract, note_payable } = contractInformation.data;

      setHolder(holder[0]);
      setHolderWork(holder_work[0]);
      setBeneficiaryList(beneficiary)
      setPaymentSupport(payment_support[0]);
      setReferenceList(reference);
      setPaymentModality(payment_modality[0]);
      setContract(contract[0]);
      setNotePayable(note_payable[0]);

    } catch (error: any) {

      const { title, message } = error.response.data;
      genericErrorAlert(title, message);
      toggleModal()

    } finally {
      setIsLoading(false);
    }

  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} style={{ maxWidth: "1200px" }}>
      <ModalHeader toggle={toggleModal} className='bg-warning'> CONTRATO - {contractSerial} {contract && (" - " + contract?.type)}</ModalHeader>

      {isLoading ? (<ModalBody>
        <Alert color="warning" className="text-center m-3">
          <b className="h2">Cargando contrato N° {contractSerial}</b>
        </Alert>
      </ModalBody>) : (
        <ModalBody>

          <Container>
            <ContractStatusInfo contractStatus={contractStatus} contractId={contractId} contractSerial={contractSerial} canEdit={canEdit} />
          </Container>

          {holder && <HolderCard holder={holder} />}

          {holderWork && <HolderWorkCard holderWork={holderWork} />}

          <Row>
            {referenceList?.map((reference, index) =>
            (<Col key={index} >
              <ReferenceCard reference={reference} index={index + 1} />
            </Col>)
            )}
          </Row>

          {beneficiaryList?.map((beneficiary, index) =>
            (<BeneficiaryCard key={index} beneficiary={beneficiary} index={index + 1} />)
          )}

          {paymentModality && <PaymentModalityCard paymentModality={paymentModality} />}
          {contract && <ContractCard contract={contract} />}
          {paymentSupport && paymentModality && <PaymentSupportCard paymentSupport={paymentSupport} userFullName={userFullName} paymentModality={paymentModality} />}
          {paymentModality?.installments_number !== 1 && notePayable && <NotePayableCard notePayable={notePayable} paymentModality={paymentModality as PaymentModality} />}
        </ModalBody>)}

      <ModalFooter>
        <Button color="danger" className="col-12" onClick={toggleModal}>Cerrar</Button>
      </ModalFooter>
    </Modal>
  );

}

export default ContractModalInformation