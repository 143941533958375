import React, { useEffect, useState } from 'react'
import { Leads } from '../../../../../../interface/interface'
import { Table } from 'reactstrap'
import '../../../../../../styles/verificationStyles.css'
import AssignmentLeadsRowTable from './AssignmentLeadsRowTable'



interface Props {
    leadsList: Array<Leads>,
    checkedList: Array<number>,
    setCheckedList: (checkedList: Array<number>) => void
    
}


const AssignmentLeadsTableReport: React.FC<Props> = ({ leadsList, checkedList, setCheckedList}) => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        setCheckedList([]);

    }, [leadsList]);

    return (
        <Table striped responsive className='text-center' hover>

            <thead>
                <tr>

                    <th className='text-center'>#</th>
                    <th className='text-center'>Nombre</th>
                    <th className='text-center'>Número de contacto</th>
                    <th className='text-center'>Correo</th>
                    <th className='text-center'>Origen</th>
                    <th className='text-center'>Observación</th>
                    <th className='text-center'>Seleccionar</th>

                </tr>
            </thead>

            <tbody>

                {leadsList.map((leads, index) => (
                    <AssignmentLeadsRowTable key={index} index={index} leads={leads} checkedList={checkedList} setCheckedList={setCheckedList} />
                ))}

            </tbody>

        </Table>)
}

export default AssignmentLeadsTableReport