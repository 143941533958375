import React, { useState } from 'react'
import { PaymentModality } from '../../../../interface/interface'
import { Button, Col, FormGroup, FormText, Input, InputGroup, InputGroupText } from 'reactstrap'
import { getMoneyNumberFormat } from '../../../../utils/numberUtils'
import Swal from 'sweetalert2'
import { genericErrorAlert, genericSuccessAlert } from '../../../../services/alertService'
import { NumericFormat } from 'react-number-format'
import { updatePaymentModalityById } from '../../../../controllers/PaymentModality'
import "../../../../styles/pboStyles.css"

interface Props {
    index: number,
    planModality: PaymentModality
    updateDefaultPlanModality(planModality: PaymentModality): void
}

const PaymentModalityRow: React.FC<Props> = ({ index, planModality, updateDefaultPlanModality }) => {

    const [editMode, setEditMode] = useState(false);
    const [planModalityEdit, setPlanModalityEdit] = useState<PaymentModality>(planModality)
    const [isSaving, setIsSaving] = useState<boolean>(false);


    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Eliminar puntos y comas y convertirlo en un número decimal
        const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
        setPlanModalityEdit({
            ...planModalityEdit,
            [name]: numericValue
        });

    };

    const handleInputChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name} = e.target;
        setPlanModalityEdit({ ...planModalityEdit, [name]: !planModalityEdit.is_active });
    };

    const handleCancel = () => {


        Swal.fire({
            title: 'Confirmación de cancelación',
            text: "¿Estas seguro de cancelar la edición actual?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                setEditMode(!editMode);
                setPlanModalityEdit(planModality);
                genericSuccessAlert("Edición cancelada", "La edición fue cancelada exitosamente");
            }
        })

    }

    const handleSubmit = async () => {

        Swal.fire({
            title: 'Confirmación de modificación',
            text: "¿Estas seguro de modificar la modalidad de pago actual?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Modificar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setIsSaving(true);
                    await updatePaymentModalityById(planModalityEdit);
                    genericSuccessAlert("Modificación exitosa", "Se han guardado los cambios")
                    updateDefaultPlanModality(planModalityEdit);
                } catch (error: any) {
                    const { title, message } = error.response.data;
                    genericErrorAlert(title, message);
                } finally {
                    setEditMode(!editMode)
                    setIsSaving(false);
                }
            }
        })


    }

    return (

        <tr key={index} style={{ pointerEvents: !isSaving ? 'auto' : 'none' }}>

            <td className='align-middle'>
                <p><b>{index + 1}</b></p>
            </td>

            <td className='align-middle'>
                <p>{planModality.plan_type}</p>
            </td>

            <td className='align-middle'>
                {editMode ? (
                    <Col lg={12}>
                        <FormGroup>
                            <InputGroup>

                                <Input name="is_active" type='select' value={planModalityEdit.is_active ? "true" : "false"} onChange={handleInputChangeStatus}>
                                    <option value="true">Activo</option>
                                    <option value="false">Desactivado</option>
                                </Input>

                            </InputGroup>
                        </FormGroup>
                    </Col>) : (<div className='mx-auto' style={{ minWidth: '180px', maxWidth: '180px' }}>
                        <span className={`pill col-6 my-1 ${planModality.is_active ? 'green' : 'red'}`}>{planModality.is_active ? 'Activo' : 'Desactivado'}</span>
                    </div>)}

            </td>

            <td className='align-middle'>
                {editMode ? (
                    <Col lg={12}>
                        <FormGroup>
                            <InputGroup>

                                <NumericFormat className='form-control text-center'
                                    name='total'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={planModalityEdit.total + ",00"}
                                    allowNegative={false}
                                    onChange={handleNumberChange}
                                    required />
                            </InputGroup>
                        </FormGroup>
                    </Col>) : (<p>{getMoneyNumberFormat(planModality.total)}</p>)}

            </td>
            <td className='align-middle'>

                {editMode ? (
                    <Col lg={12}>
                        <FormGroup>
                            <InputGroup>

                                <NumericFormat className='form-control text-center'
                                    name='enrollment'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={planModalityEdit.enrollment + ",00"}
                                    allowNegative={false}
                                    onChange={handleNumberChange}
                                    required />
                            </InputGroup>
                        </FormGroup>
                    </Col>) : (<p>{getMoneyNumberFormat(planModality.enrollment)}</p>)}
            </td>
            <td className='align-middle'>

                {editMode ? (
                    <Col lg={12}>
                        <FormGroup>
                            <InputGroup>

                                <NumericFormat className='form-control text-center'
                                    name='balance'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={planModalityEdit.balance + ",00"}
                                    allowNegative={false}
                                    onChange={handleNumberChange}
                                    required />
                            </InputGroup>
                        </FormGroup>
                    </Col>) : (<p>{getMoneyNumberFormat(planModality.balance)}</p>)}
            </td>
            <td className='align-middle'>


                {editMode ? (
                    <Col lg={12}>
                        <FormGroup>
                            <InputGroup>

                                <NumericFormat className='form-control text-center'
                                    name='installments_number'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={0}
                                    value={planModalityEdit.installments_number}
                                    allowNegative={false}
                                    onChange={handleNumberChange}
                                    maxLength={2}
                                    required />
                            </InputGroup>
                        </FormGroup>
                    </Col>) : (<p>{planModality.installments_number}</p>)}

            </td>
            <td className='align-middle'>
                {editMode ? (
                    <Col >
                        <FormGroup>
                            <InputGroup>

                                <NumericFormat className='form-control text-center'
                                    name='installments_value'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={planModalityEdit.installments_value + ",00"}
                                    allowNegative={false}
                                    onChange={handleNumberChange}
                                    required />
                            </InputGroup>
                        </FormGroup>
                    </Col>) : (<p>{getMoneyNumberFormat(planModality.installments_value)}</p>)}
            </td>
            <td className='align-middle'>
                <div className='mx-auto' style={{ minWidth: '100px', maxWidth: '100px' }}>
                    {
                        !editMode ? (<Button color='warning' className='mx-1' title='Editar planes' onClick={() => setEditMode(!editMode)}>
                            <i className="bi bi-pencil-fill"></i>
                        </Button>) : (<div>

                            <Button color='primary' className='mx-1' title='Cancelar Edición' onClick={() => handleSubmit()}>
                                <b>
                                    <i className="bi bi-archive-fill"></i>
                                </b>
                            </Button>
                            <Button color='danger' title='Guardar' onClick={() => handleCancel()}>
                                <b>
                                    <i className="bi bi-x-circle"></i>
                                </b>
                            </Button>

                        </div>)
                    }
                </div>
            </td>
        </tr>

    )
}

export default PaymentModalityRow