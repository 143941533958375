import React, { useState } from 'react'
import { Card, CardHeader, CardBody, Row, Button, Col, Label } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import VerificationFormGroupText from '../verification-form-inputs/VerificationFormGroupText'
import { VerificationReport } from '../../../../interface/interface'
import SignatureInput from '../../../contract/contract-inputs/SignatureInput'

interface Props {
  verificationReport: VerificationReport
  setVerificationReport: (verificationReport: VerificationReport) => void
}

const VerificationFinalReport: React.FC<Props> = ({ verificationReport, setVerificationReport }) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVerificationReport({
      ...verificationReport, [name]: value
    });
  };

  const handleApproved = () => {
    setVerificationReport({
      ...verificationReport, status: true
    })
  }

  const handleRejected = () => {
    setVerificationReport({
      ...verificationReport, status: false
    })
  };

  const handleHolderSignature = (signature: string) => {

    setVerificationReport({
      ...verificationReport, holder_signature: signature
    });

  }

  return (
    <Card className={CARD_BODY + 'm-3 contract-backgroud'}>

      <CardHeader tag="h4" className={CARD_TITLE} >
        <b>REPORTE DE VERIFICACIÓN</b>
      </CardHeader>

      <CardBody>
        <Row>

          <VerificationFormGroupText
            type='textarea'
            md={12} lg={12}
            name='observation'
            value={verificationReport.observation}
            onChange={handleInputChange}
            formText='Observaciones'
            required
          />

          <Row>
            <Col>
              <Button className='col-12' color="success" onClick={handleApproved} >
                <b>Aprobado</b>
              </Button>
            </Col>
            <Col>
              <Button className='col-12' color="danger" onClick={handleRejected} >
                <b>Rechazado</b>
              </Button>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col className='col-12 text-white  text-center'>

              {verificationReport.status ? (<p><b>¡Aprobado!</b></p>) : (<p><b>¡Rechazado!</b></p>)}

            </Col>
          </Row>
          
          <Row className='d-flex d-flex justify-content-center align-items-center'>

            <Col sm={12} md={6}>

              <Label tag="h5" className='text-center text-white'><b>FIRMA DEL TITULAR</b></Label>
              <SignatureInput signature={verificationReport.holder_signature} setSignature={handleHolderSignature}></SignatureInput>
            
            </Col>

          </Row>

        </Row>

      </CardBody>
    </Card>
  )
}

export default VerificationFinalReport
