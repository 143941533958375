import React, { useState } from 'react'
import { Card, CardHeader, CardBody, Container, Col, Row, FormGroup, FormText, Input, Button, Alert, Table } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'
import { genericErrorAlert, genericSuccessAlert, handleErrorResponseAlert, internalServerErrorAlert } from '../../../services/alertService';
import { getDailyClassReport, sendDailyClassReportEmail } from '../../../controllers/ClassController';
import { DailyClassReport } from '../../../interface/interface';
import LoadingStatus from '../../utils/LoadingStatus';
import Swal from 'sweetalert2';
import { getCookieValue } from '../../../services/cookieService';

const DailyScheduleReport = () => {

    const [report, setReport] = useState({ date: "" });
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [classReport, setClassReport] = useState<Array<DailyClassReport>>([]);
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);


    const handleSendEmail = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        const { user } = getCookieValue("nbp-management-auth")

        if(report.date.trim()===""){
            return genericErrorAlert("Fecha del reporte no proporcionada","Seleccione una fecha para enviar el reporte")
        }

        Swal.fire({
            title: `¿Está seguro de enviar el reporte de la fecha (${report.date}) a su correo a electrónico?`,
            html:
                `El reporte sera enviado a este correo <b>${user.email}</b> , si se encuentra equivocado colocarse en contacto con sistemas.`,
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',

        }).then(async (result) => {
            if (result.isConfirmed) {

                try {

                    setSendingEmail(true);
                    await sendDailyClassReportEmail(report.date);
                    genericSuccessAlert("Correo enviado exitosamente", "Ingresa a tu correo electrónico y verifica el mensaje")

                } catch (error: any) {

                    handleErrorResponseAlert(error, "Error al enviar el correo de reporte de clases", "Intentelo de nuevo")
                    console.log(error);

                } finally {
                    setSendingEmail(false);
                }

            }
        })



    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;

        setReport({
            ...report, [name]: value
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        try {
            setIsGenerating(true);
            const classReport = await getDailyClassReport(report.date);

            setClassReport(classReport.data.dailyReport);

            if (classReport.data.dailyReport.length === 0) {
                genericErrorAlert("Información no encontrada", "No existen clases asignadas para la fecha (" + report.date + ")");
            }

        } catch (error: any) {

            const { title, message } = error.response.data;
            internalServerErrorAlert(title, message)
            console.log(error);

        } finally {
            setIsGenerating(false);
        }
    }

    return (
        <Container fluid>
            <Card className={CARD_BODY + "mt-3"}>
                <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
                    <b>REPORTE DE CLASES</b>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col className='col-12'>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={8} >
                                        <FormGroup>


                                            <Input type='date' name='date'
                                                value={report.date}
                                                onChange={handleInputChange}
                                                required
                                            />

                                            <FormText>
                                                Fecha del reporte
                                            </FormText>

                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <Button type="submit" color="warning" className='col-12' disabled={isGenerating}><b>{isGenerating ? "Generando..." : "Generar"}</b></Button>
                                    </Col>

                                </Row>
                            </form>
                        </Col>
                        <Col>
                            <Col md={12} className='mt-2'>
                                <form onSubmit={handleSendEmail}>
                                    <Button className='btn col-12 mb-2'
                                        color='warning'
                                        type='submit'
                                        disabled={sendingEmail}>
                                        <LoadingStatus text='Enviar reporte al correo' loadingText='Enviando reporte...' status={sendingEmail} />
                                    </Button>
                                </form>
                            </Col></Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className={CARD_BODY + "my-2 nbp-background-logo "}>
                <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
                    <b className='p-0 m-0'>LISTADO DE CLASES</b>
                </CardHeader>
                {
                    classReport.length === 0 ? (
                        <Alert color="warning" className="text-center m-3">
                            <b className="h2">Realizar consulta primero</b>
                        </Alert>) :
                        <CardBody className="m-3">
                            <Row>


                            </Row>
                            <Card className='py-3'>

                                <Row>
                                    <Col>
                                        <Table striped responsive className='text-center' hover>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>#</th>
                                                    <th className='text-center'>Clase N°</th>
                                                    <th className='text-center'>Hora</th>
                                                    <th className='text-center'>Modalidad</th>
                                                    <th className='text-center'>Curso</th>
                                                    <th className='text-center'>Profesor</th>
                                                    <th className='text-center'>Beneficiario</th>
                                                    <th className='text-center'>Lección</th>
                                                    <th className='text-center'>Vivencial</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {classReport?.map((dailyClass, index) =>
                                                (<tr key={index}>
                                                    <td>
                                                        <p><b>{index + 1}</b></p>
                                                    </td>
                                                    <td>
                                                        <p>{dailyClass.class_id}</p>
                                                    </td>
                                                    <td>
                                                        <p>{dailyClass.hour}</p>
                                                    </td>
                                                    <td>
                                                        <p>{dailyClass.modality}</p>
                                                    </td>
                                                    <td>
                                                        <p>{dailyClass.classroom_name}</p>
                                                    </td>
                                                    <td>
                                                        <p>{dailyClass.teacher_first_name + " " + dailyClass.teacher_last_name}</p>
                                                    </td>
                                                    <td>
                                                        <p>{dailyClass.beneficiary_first_name + " " + dailyClass.beneficiary_last_name}</p>
                                                    </td>
                                                    <td>
                                                        <p>{dailyClass.lesson_name}</p>
                                                    </td>

                                                    <td>
                                                        <p>{dailyClass.repetition ? (dailyClass.repetition) : ("Sin registros")}</p>
                                                    </td>
                                                </tr>)
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>

                                </Row>
                            </Card>
                        </CardBody>
                }
            </Card>
        </Container>
    )
}

export default DailyScheduleReport