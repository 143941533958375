import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { checkClassAttendance, getAttendanceCodeClass } from '../../../controllers/ClassController';
import { genericErrorAlert, genericSuccessAlert, handleErrorResponseAlert } from '../../../services/alertService';
import { Button, Input } from 'reactstrap';
import LoadingStatus from '../../utils/LoadingStatus';
import { DailyAttendance } from '../../../interface/interface';

interface Props {
    classId: number,
    classAttendace: Array<DailyAttendance>
    setClassAttendace: (classAttendance: Array<DailyAttendance>) => void
}

const CheckAttendance: React.FC<Props> = ({ classId, classAttendace, setClassAttendace }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [attendanceCode, setAttendanceCode] = useState<string>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setAttendanceCode(value);
    };

    const handleCheckClassAttendance = async () => {

        if (attendanceCode.trim() === "") return genericErrorAlert("Complete el código de asistencia", "Intentelo nuevamente");

        try {

            setLoading(true);

            await checkClassAttendance(classId, attendanceCode);

            const attendance = classAttendace.filter(attendance => attendance.class_id == classId)[0]

            updatedVisualAttendance({ ...attendance, attendance_check: 1 })

            genericSuccessAlert("Asistencia marcada con exito", "Su asistencia a la clase N° " + classId + " fue confirmada.")

        } catch (error) {

            handleErrorResponseAlert(error, "Error al el código de asistencia", "Intentelo nuevamente");

        } finally {
            setLoading(false)
        }

    }

    const updatedVisualAttendance = (attendance: DailyAttendance) => {

        const updatedAttendanceIndex = classAttendace.findIndex((updatedAttendance) => updatedAttendance.class_id === attendance.class_id);

        if (updatedAttendanceIndex === -1) {
            return classAttendace;
        }

        const updatedAttendance = [...classAttendace];
        updatedAttendance.splice(updatedAttendanceIndex, 1, attendance);

        setClassAttendace(updatedAttendance);

    }

    return (

        <div>

            <Input type="text" name='phone_number'
                value={attendanceCode}
                onChange={handleInputChange}
                style={{ border: '2px solid black' }}
                autoComplete='false'
                required />

            <Button color='primary' onClick={() => handleCheckClassAttendance()} className='mt-1'>
                <LoadingStatus status={loading} text='Marcar asistencia' loadingText='Marcando asistencia'></LoadingStatus>
            </Button>
        </div>

    )
}

export default CheckAttendance