import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format';
import { Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import VerificationFormChecked from './VerificationFormChecked';

interface Props {
    type?: "select",
    value: string;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    formText: string;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const VerificationFormGroupGender: React.FC<Props> = ({ type = "select", name, value, onChange, required = false, formText, md = 12, lg = 12 }) => {

    const [disabled, setDisabled] = useState<boolean>(false);

    const handleCheckboxChange = () => {
        setDisabled(!disabled);
    };

    return (
        <Col md={md} lg={lg}>
            <FormGroup className="mb-4">
                <Row>
                <Col xs={9} sm={10} md={11}>
                        <Input type={type} name={name} value={value} onChange={onChange} required={required} disabled={disabled}>
                            <option value="">Seleccione un género</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino">Femenino</option>
                            <option value="No Binario">No Binario</option>
                            <option value="Otro">Otro</option>
                        </Input>
                    </Col>

                    <VerificationFormChecked disabled={disabled} handleCheckboxChange={handleCheckboxChange} />

                </Row>

                <FormText>
                    {formText} {required ? (" (*)") : ("")}
                </FormText>
            </FormGroup>
        </Col>

    )
}

export default VerificationFormGroupGender