import { MDBCollapse, MDBContainer, MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarItem, MDBNavbarLink, MDBNavbarNav, MDBNavbarToggler } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react'
import { Form, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { User } from '../../../interface/interface';
import NavbarRoleSelected from './NavbarRoleSelected';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText, Button, Input, Row } from 'reactstrap';
import '../../../styles/navbarStyles.css';

interface Props {
  token: string | null
  user: User
}

const NavbarManager: React.FC<Props> = ({ token, user }) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {

    Swal.fire({
      title: '¿Esta seguro que desea cerrar sesión?',
      text: "Estas apunto de salir de tu cuenta",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Salir'
    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire(
          'Sesión cerrada',
          'Hasta pronto...',
          'success'
        )
        setTimeout(() => {
          document.cookie = "nbp-management-auth=; max-age=0";
          window.location.href = '/';
        }, 2000)
      }
    })
  };

  return (
    <div>
      {token ? (
        <Navbar className='navbar' expand="lg">

          <NavbarBrand><b>CEA - MANAGEMENT</b></NavbarBrand>
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <NavbarRoleSelected handleLogout={handleLogout} roleId={user?.role_id as number}></NavbarRoleSelected>
          </Collapse>

        </Navbar>
      ) : null}
    </div>
  )
}

export default NavbarManager




