import React from 'react'
import "../../../styles/pboStyles.css"

interface Props {
    text: string
    status: boolean
    minWidth?: number
    maxWidth?: number
}

const VerificationPill: React.FC<Props> = ({ text, status, minWidth = 120, maxWidth = 120 }) => {

    return <span style={{ minWidth: minWidth + 'px', maxWidth: maxWidth + 'px' }} className={`pill col-6 my-1 ${status ? 'green' : 'red'}`}>{text}</span>

}

export default VerificationPill
