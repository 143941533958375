import React, { useState, useEffect } from 'react';
import { FormGroup, Input, Button, CardHeader, Card, CardBody, FormText, Alert, Col, Row, CardTitle } from 'reactstrap';
import { IRegisterValues, User } from '../../interface/interface';
import Swal from 'sweetalert2';
import { getAllRoles } from '../../controllers/RoleController';
import { registerUser } from '../../controllers/AuthController';
import { existUser } from '../../controllers/UserController';
import { genericErrorAlert } from '../../services/alertService';
import { CARD_BODY, CARD_TITLE } from '../../services/classService';
import PasswordInputs from './cutom-inputs/PasswordInputs';

const Register = () => {

    const defaultRegisterValues: IRegisterValues = {
        document_number: "",
        document_type: "CC",
        document_number_confirm: "",
        document_type_confirm: "CC",
        role: "",
        gender: "Masculino",
        first_name: "",
        second_name: "",
        last_name: "",
        second_last_name: "",
        birthdate: "",
        phone_number: "",
        address: "",
        email: "",
        password: "",
        confirmPassword: ""
    }

    const [registerValues, setRegisterValues] = useState<IRegisterValues>(defaultRegisterValues);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        loadAllRoles();

    }, [])

    const [roleList, setRoleList] = useState<Array<{ id: "", name: "" }>>();

    const [error, setError] = useState<string | null>(null);

    const handleDenyCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
        e.preventDefault();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRegisterValues({ ...registerValues, [name]: value });
    };

    const loadAllRoles = async () => {

        try {

            const roles = await getAllRoles()
            setRoleList(roles.data.roles);

        } catch (error) {
            console.log(error);
            genericErrorAlert("Error al cargar los roles", "Recargar la página")
        }

    }

    function isSecurePassword(password: string) {

        // Verificar si la contraseña tiene al menos 8 caracteres de longitud
        if (password.length < 8) {
            return false;
        }

        // Verificar si la contraseña contiene al menos una letra mayúscula
        if (!/[A-Z]/.test(password)) {
            return false;
        }

        // Verificar si la contraseña contiene al menos una letra minúscula
        if (!/[a-z]/.test(password)) {
            return false;
        }

        // Verificar si la contraseña contiene al menos un número
        if (!/\d/.test(password)) {
            return false;
        }

        // Verificar si la contraseña contiene al menos un carácter especial
        if (!/[\!@#\$%\^&\*\(\)_\+~`\|{\[\}:;<>?,./\-="]/.test(password)) {
            return false;
        }

        // La contraseña cumple con todos los criterios de seguridad
        return true;
    }

    const handleSubmit = async (event: React.FormEvent) => {

        event.preventDefault();

        if (!verifyUserValues()) {
            return
        }

        if (registerValues.document_number.trim() !== registerValues.document_number_confirm.trim()) {
            genericErrorAlert("Los números de documento suministrados deben ser iguales", "Modifiquelos y intentelo de nuevo.")
            return;
        }

        if (registerValues.document_type.trim() !== registerValues.document_type_confirm.trim()) {
            genericErrorAlert("Los tipos de documentos suministrados deben ser iguales", "Modifiquelos y intentelo de nuevo.")
            return;
        }

        if (registerValues.password.trim() !== registerValues.confirmPassword.trim()) {
            genericErrorAlert("Las contraseñas son diferentes", "Escriba dos contraseñas iguales")
            return;
        }

        if (!isSecurePassword(registerValues.password) || !isSecurePassword(registerValues.confirmPassword)) {
            Swal.fire({
                title: '<strong> Contraseña no segura </strong>',
                icon: 'error',
                html:
                    `<p>Por favor, asegúrate de que tu contraseña cumpla con estos criterios:</p>
                    <ul>
                      <li>Al menos 8 caracteres de longitud.</li>
                      <li>Una letra mayúscula.</li>
                      <li>Una letra minúscula.</li>
                      <li>Un número.</li>
                      <li>Un carácter especial (!@#$%^&*).</li>
                    </ul>`,
                showCloseButton: true,

                focusConfirm: false,

                cancelButtonText:
                    '<i class="fa fa-thumbs-down"></i>',
                cancelButtonAriaLabel: 'Thumbs down'
            })
            return;
        }

        try {

            Swal.fire({
                title: '¿Esta seguro de Agregar al usuario?',
                text: "Verifica todos los campos, antes de confirmar",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Agregar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {

                setIsSaving(true);

                if (result.isConfirmed) {


                    const existUserByDocumentNumber = await existUser(registerValues.document_number, registerValues.document_type);

                    if (existUserByDocumentNumber.data.exist) {

                        setError(existUserByDocumentNumber.data.message);

                        setIsSaving(false);
                        
                        setTimeout(() => {
                            setError(null)
                        }, 8000)
                        return
                    }

                    const registerResponse = await registerUser(registerValues);
                    registerSuccessAlert(registerValues.document_number, registerValues.first_name, registerValues.last_name)

                    const { title, message } = registerResponse.data;

                    setRegisterValues(defaultRegisterValues)

                    Swal.fire(
                        title,
                        message,
                        'success'
                    )
                }

            })

        } catch (error) {

            console.log(error + "")
            genericErrorAlert("Error al guardar nuevo usario", "Intentelo nuevamente");

        } finally {
            setIsSaving(false);
        }

    }

    const registerSuccessAlert = (documentNumber: string, firstName: string, lastName: string) => {

        const customWelcome = "Nuevo usuario creado: <b>" + documentNumber + " - " + firstName + " - " + lastName + "<b/>"

        Swal.fire(
            'Registro de usuario exitoso',
            customWelcome,
            'success'
        )
    }

    const completeAllInputsAlert = (title: string, information: string) => {
        title = "<b>" + title + "</b>"
        Swal.fire({
            icon: 'error',
            title: 'Completa todos los campos para el ' + title,
            text: information,
        })
    }

    const verifyUserValues = () => {

        const noCompleteInputs = [];

        if (registerValues.document_number.trim() === "") {
            noCompleteInputs.push("Número de Identificación");
        }
        if (registerValues.document_type.trim() === "") {
            noCompleteInputs.push("Tipo de Identificación");
        }

        if (registerValues.first_name.trim() === "") {
            noCompleteInputs.push("Primer nombre");
        }

        if (registerValues.last_name.trim() === "") {
            noCompleteInputs.push("Primer apellido");
        }

        if (registerValues.second_last_name.trim() === "") {
            noCompleteInputs.push("Segundo apellido");
        }

        if (registerValues.password.trim() === "") {
            noCompleteInputs.push("Contraseña");
        }

        if (registerValues.phone_number.trim() === "") {
            noCompleteInputs.push("Número de telefono");
        }

        if (registerValues.email.trim() === "") {
            noCompleteInputs.push("Dirección de residenciao");
        }

        if (noCompleteInputs.length > 0) {
            completeAllInputsAlert("Registro de Usuario", noCompleteInputs.join(" - "))
        }

        if (noCompleteInputs.length === 0) {
            return true
        }

        return false;
    }

    return (

        <div className="p-3 d-flex d-flex justify-content-center align-items-center">
            <Card className={CARD_BODY + "m-3 col-12  nbp-background-logo"}>
                <CardHeader className={CARD_TITLE} tag="h5" >
                    <b className='p-0 m-0'>Registro de Usuario</b>
                </CardHeader>
                <CardBody className='text-white'>

                    {error ? <Alert color="danger" className="text-center ">
                        <b>{error}</b>
                    </Alert> : null}

                    <form onSubmit={handleSubmit}>

                        <Row>
                            <Col lg={12}> <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                                Información Personal
                            </CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input name='first_name'
                                        value={registerValues.first_name}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Primer Nombre (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='second_name'
                                        value={registerValues.second_name}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                    />
                                    <FormText>
                                        Segundo Nombre
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='last_name'
                                        value={registerValues.last_name}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Primer Apellido (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='second_last_name'
                                        value={registerValues.second_last_name}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Segundo Apellido (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <CardTitle className='col-12 contract-subtitle' tag="h6">
                                    Información personal
                                </CardTitle>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="date" name='birthdate'
                                        value={registerValues.birthdate}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Fecha de nacimiento (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="select" name="gender" value={registerValues.gender} onChange={handleInputChange}>
                                        <option value="Masculino">Masculino</option>
                                        <option value="Femenino">Femenino</option>
                                        <option value="No Binario">No Binario</option>
                                        <option value="Otro">Otro</option>
                                    </Input>
                                    <FormText>
                                        Género (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col lg={12}> <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                                Información de contacto
                            </CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='phone_number'
                                        value={registerValues.phone_number}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Número de teléfono (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="email" name='email'
                                        value={registerValues.email}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Correo Electronico (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='address'
                                        value={registerValues.address}
                                        onChange={handleInputChange}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Dirección de residencia (*)
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                                    Información de inicio de sesión
                                </CardTitle>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='document_number'
                                        value={registerValues.document_number}
                                        onChange={handleInputChange}
                                        onCopy={handleDenyCopyPaste}
                                        onPaste={handleDenyCopyPaste}
                                        onCut={handleDenyCopyPaste}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Número de documento (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="select" name="document_type" id="documentType" value={registerValues.document_type} onChange={handleInputChange}>
                                        <option value="CC">(C.C) Cédula de Ciudadania</option>
                                        <option value="CE">(C.E) Cédula de Extranjería</option>
                                        <option value="TI">(T.I) Tarjeta de Identidad</option>
                                        <option value="PA">(P.A) Pasaporte</option>
                                    </Input>
                                    <FormText>
                                        Tipo de documento(*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col lg={12}>
                                <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                                    Confirmar Información de Inicio de sesión
                                </CardTitle>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='document_number_confirm'
                                        value={registerValues.document_number_confirm}
                                        onChange={handleInputChange}
                                        onCopy={handleDenyCopyPaste}
                                        onPaste={handleDenyCopyPaste}
                                        onCut={handleDenyCopyPaste}
                                        autoComplete='false'
                                        required />
                                    <FormText>
                                        Número de documento (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="select" name="document_type_confirm" id="documentTypeConfirm" value={registerValues.document_type_confirm} onChange={handleInputChange}>
                                        <option value="CC">(C.C) Cédula de Ciudadania</option>
                                        <option value="CE">(C.E) Cédula de Extranjería</option>
                                        <option value="TI">(T.I) Tarjeta de Identidad</option>
                                        <option value="PA">(P.A) Pasaporte</option>
                                    </Input>
                                    <FormText>
                                        Tipo de documento(*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                        </Row>


                        <hr />

                        <Row style={{ minWidth: '100px' }}>
                            < PasswordInputs handleDenyCopyPaste={handleDenyCopyPaste} registerValues={registerValues} handleInputChange={handleInputChange} />
                        </Row>

                        {error ? <Alert color="danger" className="text-center ">
                            <b>{error}</b>
                        </Alert> : null}

                        <Row>
                            <Col md={12} >
                                <FormGroup className="form-outline mb-4">
                                    <Input type="select" name="role" id="role" value={registerValues.role} onChange={handleInputChange} required>
                                        <option value="">Seleccione un tipo de usuario</option>
                                        {roleList?.map((role, index) =>
                                            <option key={index} value={role.id}>{role.name}</option>
                                        )}
                                    </Input>
                                    <FormText>
                                        Tipo de Usuario (*)
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button className="col-12" color="success" type="submit" disabled={isSaving}>
                            {!isSaving ? (<b>Registrar Usuario</b>) : (<b>Registrando Usuario...</b>)}
                        </Button>

                    </form>

                </CardBody>

            </Card>

        </div>

    );
};

export default Register;