import axiosInstance from '../services/axiosInstance';

import { BASE_URL as mainRoute } from '../services/axiosInstance';

export const sendTestingGenericEmail = async (subject: string, title: string, description: string, subDescription: string) => {
    return await axiosInstance.post(mainRoute + 'email/sendTestingGenericEmail', { subject: subject, title: title, description: description, subDescription: subDescription })
}

export const sendMasiveBeneficiaryActiveEmail = async (subject: string, title: string, description: string, subDescription: string, permissionCode: string) => {
    return await axiosInstance.post(mainRoute + 'email/sendMasiveBeneficiaryActiveEmail', { subject: subject, title: title, description: description, subDescription: subDescription, permissionCode: permissionCode })
}

