import React, { useEffect, useState } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { getCookieValue } from "../services/cookieService";
import { validateToken } from "../services/authService";
import { AllowedRole, roleDictionary } from "../constans/Roles";
import { Row, Label, Col, Alert } from "reactstrap";
import { genericErrorAlert } from "../services/alertService";

interface Props {
    allowedRole: Array<AllowedRole>
}

const ProtectedRoute: React.FC<Props> = ({ allowedRole }) => {
    const [isLoading, setLoading] = useState(true);
    const [isLogin, setLogin] = useState(false);
    const [error, setError] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);

    const location = useLocation();

    const validateAuth = async () => {
        allowedRole.push("ADMIN");

        try {
            await validateToken("Router Main - Protection");
            const cookie = getCookieValue("nbp-management-auth");

            if (cookie) {
                const { role_id } = cookie.user;
                if (allowedRole.some((role) => roleDictionary[role] === role_id)) {
                    setHasAccess(true);
                } else {
                    setHasAccess(false);
                }
                setLogin(true);
            }
        } catch (error: any) {
            const { title, message } = error.response.data;
            setError(true);
            genericErrorAlert(title, message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        validateAuth();

    }, [location]);

    if (error) {
        return <Navigate to="/login" />
    }

    if (isLoading) {
        return (
            <Row className="m-2">
                <Col className='text-center'>
                    <Alert color='warning'><b>CARGANDO SECCIÓN...</b></Alert>
                </Col>
            </Row>
        );
    } else {
        if (!isLogin) {
            return (
                <Row className="m-2">
                    <Col className='text-center'>
                        <Alert color='danger'><b>ACCESO DENEGADO... INICIAR SESIÓN</b></Alert>
                    </Col>
                </Row>
            );
        } else {
            if (hasAccess) {
                return <Outlet />;
            } else {
                return (
                    <Row className="m-2">
                        <Col className='text-center'>
                            <Alert color='danger'>
                                <Row>
                                    <b>NO TIENE LOS PERMISOS NECESARIOS PARA ESTA VISTA</b>
                                </Row>
                                <Row className="justify-content-center">
                                    <Link to="/home" className="btn btn-danger col-6">
                                        Regresar al Inicio
                                    </Link>
                                </Row>
                            </Alert>
                        </Col>
                    </Row>
                );
            }
        }
    }
};

export default ProtectedRoute;
