import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText } from 'reactstrap'
import { Reference } from '../../../interface/interface'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'

interface Props {
    reference: Reference
    index?:number
}

const ReferenceCard: React.FC<Props> = ({ reference,index}) => {
    return (
        <Card className={CARD_BODY+'m-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>REFERENCIA {index}</b>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input name='first_name'
                                value={reference.first_name}
                                readOnly
                            />
                            <FormText>
                                Primer nombre 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='second_name'
                                value={reference.second_name}
                                readOnly
                            />
                            <FormText>
                                Segundo nombre
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='last_name'
                                value={reference.last_name}
                                readOnly

                            />
                            <FormText>
                                Primer Apellido 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='second_last_name'
                                value={reference.second_last_name}
                                readOnly
                            />
                            <FormText>
                                Segundo Apellido
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='profession'
                                value={reference.profession}
                                readOnly
                            />
                            <FormText>
                                Profesión 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='phone_number'
                                value={reference.phone_number}
                                readOnly
                            />
                            <FormText>
                                 Número de teléfono 
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col lg={12}>
                        <FormGroup className="mb-4">
                            <Input type="text" name='gender'
                                value={reference.gender}
                                readOnly
                            />
                            <FormText>
                                Genero 
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ReferenceCard