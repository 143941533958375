import { useState, useEffect } from "react";
import { Alert, Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { getAllContracts, getContractById, getPersonalUserContracts } from "../../../controllers/ContractController";
import ContractModalInformation from "../contract-modal/ContractModalInformation";
import { User } from "../../../interface/interface";
import { useNavigate } from "react-router-dom";
import ContractStatusInfo from "../contract-status/ContractStatusInfo";
import { getCookieValue } from "../../../services/cookieService";
import { validateToken } from "../../../services/authService";
import { CARD_BODY, CARD_TITLE } from "../../../services/classService";
import { genericErrorAlert } from "../../../services/alertService";


interface Props {
    token: string | null
    setToken: (token: string | null) => void
    user: User | null
    setUser: (user: User | null) => void
}

const PersonalContract: React.FC<Props> = ({ user }) => {
    const [contractList, setContractList] = useState<Array<any>>([]);
    const [modalOpenState, setModalOpenState] = useState<boolean>(false);
    const [modalValues, setModalValues] = useState({ contractSerial: "", contractId: "", contractStatus: "" });
    const [loadding, setLoadding] = useState<boolean>(true);

    const navigate = useNavigate();

    function formatISODate(inputDate: string) {

        const dateObject = new Date(inputDate);

        const year = dateObject.getUTCFullYear();
        const month = `0${dateObject.getUTCMonth() + 1}`.slice(-2);
        const day = `0${dateObject.getUTCDate()}`.slice(-2);

        return `${year}-${month}-${day}`;
    }


    useEffect(() => {
        if (user?.document_type && user?.document_number) {
            loadContractList();
        }
    }, [user?.document_type, user?.document_number]);


    const loadContractList = async () => {
        try {

            const contractListData = await getPersonalUserContracts();
            const contracts = contractListData.data.contracts;

            setContractList(contracts);

        } catch (error) {
            genericErrorAlert("Error al cargar contratos","Intentelo nuevamente");
            console.log("Error al cargar los contratos");
        }finally{
            setLoadding(false);
        }
    };

    const toggleModal = async () => {

        setModalOpenState(!modalOpenState);

    };

    const setValues = (contractId: string, contractSerial: string, contractStatus: string) => {

        setModalValues({ contractId: contractId, contractSerial: contractSerial, contractStatus: contractStatus })

        toggleModal()
    }


    return (
        <Card className={CARD_BODY + "my-2 nbp-background-logo m-2"}>
            <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
                <b className='p-0 m-0'>LISTADO DE CONTRATOS</b>
            </CardHeader>


            {
                loadding ? (<Row>
                    <Col>
                        <Alert color="warning" className="text-center m-3">
                            <b className="h2">Cargando contratos...</b>
                        </Alert>
                    </Col>
                </Row>
                ) : (<Row>
                    {contractList.length === 0 ? (<Col>
                        <Alert color="warning" className="text-center m-3">
                            <b className="h2">No tiene registro de ningún contrato </b>
                        </Alert>
                    </Col>) : (<CardBody className="m-3">
                        <Card className="py-3">
                            <Row>
                                <Col>
                                    <Table striped responsive className='text-center' hover>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th className='text-center'>N° de contrato</th>
                                                <th className='text-center'>Estado</th>
                                                <th className='text-center'>Fecha</th>
                                                <th className='text-center'>Identificación (Empleado)</th>
                                                <th className='text-center'>Empleado</th>
                                                <th className='text-center'>Identificación (Titular)</th>
                                                <th className='text-center'>Titular</th>
                                                <th className='text-center'>Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {contractList?.map((contract, index) =>
                                            (<tr key={index}>
                                                <td>
                                                    <p><b>{index + 1}</b></p>
                                                </td>
                                                <td>
                                                    <p>{contract.serial}</p>
                                                </td>
                                                <td>
                                                    {<ContractStatusInfo contractStatus={contract.status} contractId={contract.id} contractSerial={contract.serial} />}
                                                </td>
                                                <td>
                                                    {formatISODate(contract.created_at)}
                                                </td>
                                                <td>
                                                    <p>{contract.user_document_number + "- (" + contract.user_document_type + ")"}</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        {contract.user_first_name + " " + contract.user_second_name + " " + contract.user_last_name + " " + contract.user_second_last_name}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>{contract.holder_document_number}</p>
                                                </td>
                                                <td>
                                                    <p>{contract.holder_first_name + " " + contract.holder_second_name + " " + contract.holder_last_name + " " + contract.holder_second_last_name}</p>
                                                </td>
                                                <td>
                                                    <Button color="primary" id={contract.id} onClick={() => setValues(contract.id, contract.serial, contract.status)}>
                                                        <i className="bi bi-zoom-in"></i>
                                                    </Button>
                                                </td>
                                            </tr>)
                                            )}
                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                        </Card>
                    </CardBody>)

                    }

                </Row>)

            }

            {modalOpenState ? <ContractModalInformation contractStatus={modalValues.contractStatus} userFullName={user?.first_name + " " + user?.second_name + " " + user?.last_name + " " + user?.second_last_name} toggleModal={toggleModal} contractId={modalValues.contractId} contractSerial={modalValues.contractSerial} isOpen={modalOpenState} /> : null}

        </Card>
    );
};

export default PersonalContract 
