import { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../../controllers/UserController';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import { User } from '../../../interface/interface';
import UserDetailsModal from '../user-modal/UserDetailsModal';
import EditUserModal from '../user-modal/EditUserModal';

const UserList = () => {

    const [userList, setUserList] = useState<Array<User>>([]);
    const [editUserModalState, setEditUserModalState] = useState<boolean>(false);
    const [userDetailsModalState, setUserDetailsModalState] = useState<boolean>(false);
    const [modalValues, setModalValues] = useState({ contractSerial: "", contractId: "", contractStatus: "" });
    const [loadding, setLoadding] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<User>();

    const navigate = useNavigate();

    useEffect(() => {
        loadUserList();
    }, []);

    const toggleUserDetailsModal = () => {

        setUserDetailsModalState(!userDetailsModalState);

    };

    const toggleEditUserModal = () => {

        setEditUserModalState(!editUserModalState);

    };

    const loadUserList = async () => {
        try {
            const userListData = await getAllUsers();
            const users = userListData.data.users;
            setUserList(users);
            setLoadding(false);

        } catch (error) {
            console.log(error);
            console.log(error + "");
        }
    };

    const handleUserDetails = (user: User) => {
        setSelectedUser(user);
        toggleUserDetailsModal()
        console.log("test")
    }

    const handlePasswordReset = (user: User) => {
        setSelectedUser(user);
        toggleEditUserModal()
    }

    return (
        <Card className={CARD_BODY + "my-2 nbp-background-logo m-2"}>
            <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
                <b className='p-0 m-0'>LISTADO DE USUARIOS REGISTRADOS</b>
            </CardHeader>
            {
                loadding ? (
                    <Alert color="warning" className="text-center m-3">
                        <b className="h2">Cargando usuarios...</b>
                    </Alert>) :
                    <CardBody className="m-3">
                        <Card className='py-3'>
                            <Row>
                                <Col>
                                    <Table striped responsive className='text-center' hover>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th className='text-center'>Identificación</th>
                                                <th className='text-center'>Empleado</th>
                                                <th className='text-center'>Role</th>
                                                <th className='text-center'>Estado</th>
                                                <th className='text-center'>Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {userList?.map((user, index) =>
                                            (<tr key={index}>
                                                <td>
                                                    <p><b>{index + 1}</b></p>
                                                </td>

                                                <td>
                                                    <p>{user.document_number + " - (" + user.document_type + ")"}</p>
                                                </td>

                                                <td>
                                                    {user.first_name + " " + user.second_name + " " + user.last_name + " " + user.second_last_name}
                                                </td>

                                                <td>
                                                    {user.role_name + ""}
                                                </td>

                                                <td>
                                                    {user.is_active ? (<span className={'bg-lime p-2 col-12 custom-pill'}>Activo</span>) : (<span className={'bg-danger p-2 col-12 custom-pill '}>Desactivado</span>)}
                                                </td>

                                                <td>
                                                    <div className='mx-auto' style={{ minWidth: '100px', maxWidth: '100px' }}>

                                                        <Button color="primary" title='Detalle de Usuario' onClick={() => handleUserDetails(user)}>
                                                            <i className="bi bi-zoom-in"></i>
                                                        </Button>
                                                        <Button color='warning' className='mx-1' title='' onClick={() => handlePasswordReset(user)}>
                                                            <i className="bi bi-pencil-fill"></i>
                                                        </Button>
                                                    </div>


                                                </td>
                                            </tr>)
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>

                                {editUserModalState ? <EditUserModal user={selectedUser as User} isOpen={editUserModalState} toggle={toggleEditUserModal} userList={userList}  setUserList={ setUserList}/> : null}
                                {userDetailsModalState ? <UserDetailsModal user={selectedUser as User} isOpen={userDetailsModalState} toggle={toggleUserDetailsModal} /> : null}

                            </Row>
                        </Card>
                    </CardBody>
            }

        </Card>
    )
}

export default UserList
