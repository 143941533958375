import React from 'react'
import "../../../styles/pboStyles.css"

interface Props {
    skill: string
    status: boolean
}

const PBOPill: React.FC<Props> = ({ skill, status }) => {

    return (
        <span className={`pill col-6 my-1 ${!status ? 'green' : 'red'}`}>{skill}</span>
    )

}

export default PBOPill
