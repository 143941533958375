import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import { Prospect, ProspectMeeting, User } from '../../../../interface/interface';
import ManualCloseAlert from '../../../alerts/ManualCloseAlert';
import { genericErrorAlert, genericSuccessAlert } from '../../../../services/alertService';
import { createProspect } from '../../../../controllers/ProspectController';
import PBOInputOrigin from '../pbo-form-inputs/PBOInputOrigin';
import PBOInputStatus from '../pbo-form-inputs/PBOInputStatus';

interface Props {
    leadsId?: number | null
    loadPersonalLeads?: () => void
    setSelectedLead?: (selectedLead: number | null) => void
}

const PBOForm: React.FC<Props> = ({ leadsId = null, loadPersonalLeads = () => { }, setSelectedLead = () => { } }) => {

    const defaultProspectValue: Prospect = {
        origin: "",
        first_name: "",
        second_name: "",
        last_name: "",
        second_last_name: "",
        phone_number: "",
        whatsapp_number: "",
        email: "",
        profession: "",
        decision: 1,
        english_level: 0,
        limitations: "",
        civil_status: "",
        status: "",
        writing: false,
        reading: false,
        speaking: false,
        listening: false
    }

    const defaultProspectDate: ProspectMeeting = {
        modality: "",
        place: "",
        address: "",
        hour: "",
        date: ""
    }

    const [prospect, setProspect] = useState<Prospect>(defaultProspectValue);
    const [prospectMeeting, setProspectDate] = useState<ProspectMeeting>(defaultProspectDate);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [observation, setObservation] = useState("");

    useEffect(() => {

        setProspect(defaultProspectValue);
        setProspectDate(defaultProspectDate);

    }, [leadsId])

    const handleInputChangeProspects = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "status") {
            setProspectDate(defaultProspectDate);
        }

        setProspect({
            ...prospect, [name]: value
        });
    };

    const handleInputChangeObservation = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setObservation(value)
    };



    const handleInputChangeProspectsDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "modality") {
            setProspectDate({
                ...prospectMeeting, place: "", address: "", modality: value as "Presencial" | "Virtual"
            })
            return
        }

        if (name === "place") {
            setProspectDate({
                ...prospectMeeting, address: "", place: value as "Empresa" | "Domicilio"
            })
            return
        }

        setProspectDate({
            ...prospectMeeting, [name]: value
        });

    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        try {

            setIsSaving(true);

            const response = await createProspect(leadsId, prospect, prospectMeeting, prospect.status === "Agendado", observation);

            const { title, message } = response.data;

            setProspect(defaultProspectValue);
            setProspectDate(defaultProspectDate);
            setObservation("");
            loadPersonalLeads();
            genericSuccessAlert(title, message);
            setSelectedLead(null);

        } catch (error) {

            console.log(error);
            genericErrorAlert("Error al guardar prospecto", "Intentelo nuevamente...");

        } finally {
            setIsSaving(false);
        }

    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const skillName = event.target.name;
        setProspect({
            ...prospect,
            [skillName]: event.target.checked,
        });
    };

    return (

        <Container fluid className='pb-1'>
            <form onSubmit={handleSubmit}>
                <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
                    <CardHeader tag="h4" className={CARD_TITLE} >
                        <b>CREACIÓN DE PROSPECTO</b>
                    </CardHeader>
                    <CardBody className='text-white'>

                        {/*
                         <ManualCloseAlert color='warning' text='La creación de prospectos se encuentra en desarollo...'></ManualCloseAlert>
                         */}

                        <CardTitle className='underline' tag="h5" lg={12}>
                            Origen
                        </CardTitle>

                        <Row>
                            <Col md={12} lg={12}>
                                <FormGroup>
                                    <PBOInputOrigin name='origin' value={prospect.origin} onChange={handleInputChangeProspects} required />
                                    <FormText >
                                        Origen (*)
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                        <CardTitle className='underline' tag="h5" lg={12}>
                            Información Personal
                        </CardTitle>

                        <Row>
                            <Col md={12} >
                                <FormGroup>
                                    <Input name='first_name'
                                        value={prospect.first_name}
                                        onChange={handleInputChangeProspects}
                                        required />
                                    <FormText >
                                        Primer Nombre (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} >
                                <FormGroup>
                                    <Input type="text" name='second_name'
                                        value={prospect.second_name}
                                        onChange={handleInputChangeProspects}
                                    />
                                    <FormText>
                                        Segundo Nombre
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} >
                                <FormGroup>
                                    <Input type="text" name='last_name'
                                        value={prospect.last_name}
                                        onChange={handleInputChangeProspects}
                                        required />
                                    <FormText>
                                        Primer Apellido (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} >
                                <FormGroup>
                                    <Input type="text" name='second_last_name'
                                        value={prospect.second_last_name}
                                        onChange={handleInputChangeProspects}
                                    />
                                    <FormText>
                                        Segundo Apellido
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                        <CardTitle className='underline' tag="h5" lg={12} />

                        <Row>
                            <Col md={12} >
                                <FormGroup>
                                    <Input type="text" name='phone_number'
                                        value={prospect.phone_number}
                                        onChange={handleInputChangeProspects}
                                        required />
                                    <FormText>
                                        Número de contacto (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} >
                                <FormGroup>
                                    <Input type="text" name='whatsapp_number'
                                        value={prospect.whatsapp_number}
                                        onChange={handleInputChangeProspects}
                                        required />
                                    <FormText>
                                        Número de WhatsApp (*)
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col md={12} >
                                <FormGroup>
                                    <Input type="email" name='email'
                                        value={prospect.email}
                                        onChange={handleInputChangeProspects}
                                    />
                                    <FormText>
                                        Correo Electronico
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col md={12} >
                                <FormGroup>
                                    <Input type="text" name='profession'
                                        value={prospect.profession}
                                        onChange={handleInputChangeProspects}
                                    />
                                    <FormText>
                                        Profesión
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col md={12} >
                                <FormGroup>
                                    <Input type="select" name='civil_status' value={prospect.civil_status} onChange={handleInputChangeProspects} required >
                                        <option value="">Seleccione un estado civil</option>
                                        <option value="Soltero">Soltero</option>
                                        <option value="Casado">Casado</option>
                                        <option value="Unión Libre">Unión Libre</option>
                                        <option value="Otro">Otro</option>
                                    </Input>

                                    <FormText>
                                        Estado Civil (*)
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} >
                                <CardTitle className='underline' tag="h5" lg={12}>
                                    Decisión académica: {prospect.decision}
                                </CardTitle>
                                <Input
                                    type="range"
                                    min="1"
                                    max="10"
                                    name='decision'
                                    value={prospect.decision}
                                    onChange={handleInputChangeProspects}
                                />
                            </Col>
                        </Row>

                        <CardTitle className='underline' tag="h5" lg={12}>
                            Nivel de Ingles: {prospect.english_level}
                        </CardTitle>

                        <Row>
                            <Col md={12} >
                                <FormGroup>
                                    <Input
                                        type="range"
                                        min="0"
                                        max="100"
                                        name='english_level'
                                        value={prospect.english_level}
                                        onChange={handleInputChangeProspects}
                                    />


                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <CardTitle className='underline' tag="h5" lg={12}>
                                    Falencias
                                </CardTitle>
                                <Row>

                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="writing"
                                            checked={prospect.writing}
                                            onChange={handleCheckboxChange}
                                        />
                                        Escribir
                                    </Label>

                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="reading"
                                            checked={prospect.reading}
                                            onChange={handleCheckboxChange}
                                        />
                                        Leer
                                    </Label>

                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="speaking"
                                            checked={prospect.speaking}
                                            onChange={handleCheckboxChange}
                                        />
                                        Hablar
                                    </Label>

                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="listening"
                                            checked={prospect.listening}
                                            onChange={handleCheckboxChange}
                                        />
                                        Escuchar
                                    </Label>

                                </Row>
                            </Col>

                            <Col md={12}>
                                <FormGroup>

                                    <Input type="text" className="mt-2" name="limitations" value={prospect.limitations} onChange={handleInputChangeProspects} required />

                                    <FormText>
                                        Limitaciones  (*)
                                    </FormText>

                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <PBOInputStatus name="status" value={prospect.status} onChange={handleInputChangeProspects} required />
                                    <FormText>
                                        Estado  (*)
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        name="observation"
                                        value={observation}
                                        onChange={handleInputChangeObservation}
                                        required
                                    />
                                    <FormText>Observaciones (*)</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                {prospect.status === "Agendado" ? (<Card className={CARD_BODY + 'm-3 contract-backgroud'}>
                    <CardHeader tag="h4" className={CARD_TITLE} >
                        <b>AGENDAR REUNIÓN</b>
                    </CardHeader>

                    <CardBody>

                        <Row>
                            <Col md={12}>
                                <FormGroup>

                                    <Input type="select" name="modality" value={prospectMeeting.modality} onChange={handleInputChangeProspectsDate} required>
                                        <option value="">Seleccione una modalidad</option>
                                        <option value="Presencial">Presencial</option>
                                        <option value="Virtual">Virtual</option>
                                    </Input>

                                    <FormText>
                                        Modalidad (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            {prospectMeeting.modality === 'Presencial' ? (<Col md={12}>
                                <FormGroup>

                                    <Input type="select" name="place" value={prospectMeeting.place} onChange={handleInputChangeProspectsDate} required>
                                        <option value="">Seleccione ubicación</option>
                                        <option value="Empresa">Empresa</option>
                                        <option value="Domicilio">Domicilio</option>
                                    </Input>

                                    <FormText>
                                        Lugar (*)
                                    </FormText>
                                </FormGroup>
                            </Col>) : (null)}

                            {prospectMeeting.place === "Domicilio" ? (<Col md={12}>
                                <FormGroup>

                                    <Input type="text" className="mt-2" name="address" value={prospectMeeting.address} onChange={handleInputChangeProspectsDate} required />

                                    <FormText>
                                        Dirección  (*)
                                    </FormText>

                                </FormGroup>
                            </Col>) : (null)}


                            {prospectMeeting.modality !== "" ? (
                                <div>
                                    <Col md={12}>
                                        <FormGroup>

                                            <Input type="date" className="mt-2" name="date" value={prospectMeeting.date} onChange={handleInputChangeProspectsDate} required />

                                            <FormText>
                                                Fecha  (*)
                                            </FormText>

                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>

                                            <Input type="time" className="mt-2" name="hour" value={prospectMeeting.hour} onChange={handleInputChangeProspectsDate} required />

                                            <FormText>
                                                Hora  (*)
                                            </FormText>

                                        </FormGroup>
                                    </Col>
                                </div>) : (null)}
                        </Row>
                    </CardBody>
                </Card >) : (null)}

                <Row>
                    <Col className='mx-3'>
                        <Button type='submit' color='warning' className=' col-12 text-center mb-4' disabled={isSaving}><b>{!isSaving ? "Guardar prospecto" : "Guardando prospecto..."}</b></Button>
                    </Col>
                </Row>

            </form>

        </Container>
    )
}

export default PBOForm
