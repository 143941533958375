import { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, Button } from "reactstrap";
import Swal from "sweetalert2";
import { getAllInductionAllowSchedule } from "../../../../controllers/InductionAllowScheduleController";
import { InductionAllowSchedule } from "../../../../interface/interface";
import { genericErrorAlert } from "../../../../services/alertService";
import { CARD_BODY, CARD_TITLE } from "../../../../services/classService";

interface Props {
    beneficiarySelected: string
}

const ScheduleInduction: React.FC<Props> = ({ beneficiarySelected }) => {

    const [scheduleAllowList, setScheduleAllowList] = useState<Array<InductionAllowSchedule>>([]);
    const [inductionOptions, setInductionOptions] = useState({ schedulle_allow_selected: "", modality: "", date: "" });
    const [isLoadingSaving, setIsLoadingSaving] = useState<boolean>(false);
    const [isLoadingInformation, setIsLoadingInformation] = useState<boolean>(true);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInductionOptions({ ...inductionOptions, [name]: value });
    };

    useEffect(() => {
        loadReservationInformation()
    }, [])

    const loadReservationInformation = async () => {

        try {

            setIsLoadingInformation(true);
            const schedule = await getAllInductionAllowSchedule();
            setScheduleAllowList(schedule.data.schedule);

        } catch (error: any) {

            const { title, message } = error.response.data;

            genericErrorAlert(title, message)
            setIsLoadingInformation(false);
        }

    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        Swal.fire({
            title: '¿Esta seguro de agendar la inducción?',
            html:
                'Una vez enviada, se le <b>notificará</b> al beneficiario sobre la inducción, ',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    setIsLoadingSaving(true);


                } catch (error: any) {

                    const { title, message } = error.response.data;
                    genericErrorAlert(title, message);
                    console.log(error)

                } finally {
                    setIsLoadingSaving(false);
                }
            }
        })

    }

    return (
        <Card className={CARD_BODY + "contract-backgroud"}>

            <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
                <b>AGENDAR INDUCCIÓN</b>
            </CardHeader>

            <CardBody>
                <form onSubmit={handleSubmit}>

                    <Row>

                        <Col md={12}>
                            <FormGroup className="form-outline mb-4">

                                <Input type="select" name="modality" value={inductionOptions.modality} onChange={handleOnChange} >
                                    <option value="">Seleccionar modalidad</option>
                                    <option value="Presencial">Presencial</option>
                                    <option value="Virtual">Virtual</option>
                                </Input>

                                <FormText>
                                    Modalidad (*)
                                </FormText>

                            </FormGroup>
                        </Col>

                    </Row>
                    {inductionOptions.modality !== "" ? (
                    
                    <Row>

                        {inductionOptions.modality === "Presencial" ? (<Col md={6}>
                            <FormGroup className="form-outline mb-4">

                                <Input type="select" name="schedulle_allow_selected" value={inductionOptions.schedulle_allow_selected} onChange={handleOnChange} >
                                    <option value="">Seleccionar horario de Inducción</option>
                                    {scheduleAllowList.map((schedule, index) => (
                                        <option key={index} value={schedule.id}>{schedule.week_day} - {schedule.hour}</option>
                                    ))}
                                </Input>

                                <FormText>
                                    Horarios de Inducción (*)
                                </FormText>

                            </FormGroup>
                        </Col>):(null)}

                        <Col md={6}>
                            <FormGroup className=" mb-4">
                                <Input type="date" name="date" value={inductionOptions.date} onChange={handleOnChange} readOnly={inductionOptions.modality === "Presencial"} required />
                                <FormText>
                                    Fecha(*)
                                </FormText>
                            </FormGroup>
                        </Col>

                        <Col className='col-12'>
                            <hr className='border-top' />
                        </Col>

                        <Col md={12} >
                            <Button className='btn col-12' color='success' type='submit'><b>Guardar Inducción</b></Button>
                        </Col>
                    </Row>) : (null)}

                </form>

            </CardBody>
        </Card>
    )
}

export default ScheduleInduction