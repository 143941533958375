
import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';


export const existHolderByDocumentNumber = async (holder_document_type: string, holder_document_number: string) => {
    const exist = await axiosInstance.post(mainRoute + 'holder/existHolderByDocumentNumber', { holder_document_type: holder_document_type, holder_document_number: holder_document_number })
    return exist;
}

export const getHolderByContractNumber = async (contract_id: string) => {
    const exist = await axiosInstance.post(mainRoute + 'holder/getHolderByContractNumber', { contract_id: contract_id })
    return exist;
}

export const getHolderByDocument = async (document_type: string, document_number: string) => {
    const exist = await axiosInstance.get(mainRoute + 'holder/getHolderByDocument/' + document_type + '/' + document_number)
    return exist;
}



