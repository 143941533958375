import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, Alert, Label, InputGroup, InputGroupText } from 'reactstrap'
import { getTranscriptionNumbers } from '../../../utils/numberUtils'
import { PaymentModality, PaymentSupport } from '../../../interface/interface'
import { NumericFormat } from 'react-number-format'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'
import { dateformatISO } from '../../../utils/dateUtils'

interface Props {
    paymentSupport: PaymentSupport
    paymentModality: PaymentModality
    userFullName: string
}

const PaymentSupportCard: React.FC<Props> = ({ paymentSupport, userFullName,  paymentModality }) => {

    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>SOPORTE DE PAGO</b>
            </CardHeader>
            <CardBody>

                <Row>

                    <Col sm={6} md={6} lg={6}>
                        <FormGroup>

                            <Input type="text" name="user_full_name"
                                value={userFullName}
                                readOnly>
                            </Input>

                            <FormText>
                                Pagado a
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col sm={6} md={6} lg={6}>
                        <FormGroup>

                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='amount'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={paymentSupport.amount+ ",00"}
                                    allowNegative={false}
                                    readOnly />
                            </InputGroup>

                            <FormText>
                                Monto
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col sm={12}>
                        <FormGroup>

                            <Input type="text" name="text_amount"
                                value={getTranscriptionNumbers(paymentSupport.amount as number)}
                                readOnly>
                            </Input>

                            <FormText>
                                Monto en texto
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                        <FormGroup>

                            <Input type="text" name="description"
                                value={paymentSupport.description}
                                readOnly
                            ></Input>

                            <FormText>
                                Por concepto de
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                        <FormGroup>

                            <Input type="date" name="current_date"
                                value={dateformatISO(paymentSupport.created_at as string)}
                                readOnly>
                            </Input>

                            <FormText>
                                Fecha de Pago
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                        <FormGroup>
                            <Input type="text" name="payment_method"
                                value={paymentSupport.payment_method}
                                readOnly>
                            </Input>
                            <FormText>
                                Tipo de pago
                            </FormText>
                        </FormGroup>
                    </Col>


                    {paymentSupport.card_type ?
                        <Col md={12} lg={6}>
                            <FormGroup>
                                <Input type="text" name="card_type"
                                    value={paymentSupport.card_type}
                                    readOnly>
                                </Input>
                                <FormText>
                                    Tipo de tarjeta
                                </FormText>
                            </FormGroup>
                        </Col> : null
                    }

                </Row>

                <Row className='d-flex justify-content-center align-items-center'>
                    <Label tag="h5" className='text-center text-white'><b>FIRMA DEL EJECUTIVO CEA S.A.S</b></Label>
                    <Col className='col-12 d-flex justify-content-center align-items-center'>
                        <Alert color='warning'><img src={paymentSupport.responsible_user_signature} className='img-fluid' alt='Firma del usuario responsable' /></Alert>
                    </Col>
                </Row>

            </CardBody>
        </Card>
    )
}

export default PaymentSupportCard