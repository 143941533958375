/**
 * 
 * Número de teléfono utilizado para el contacto de beneficiarios/clientes
 * 
 */

export const CONTACT_PHONE_NUMBER = "315 927 2929"

/**
 * 
 * Correo electrónico utilizado para el contacto de beneficiarios/clientes
 * 
 */
  
export const CONTACT_EMAIL = "info@nbpteam.com"