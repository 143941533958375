import React, { useEffect, useState } from 'react'
import { Leads } from '../../../../../../interface/interface'
import { Table } from 'reactstrap'
import '../../../../../../styles/verificationStyles.css'
import NormalLeadsRowTable from './NormalLeadsRowTable'

interface Props {
    leadsList: Array<Leads>,
}


const NormalLeadsTableReport: React.FC<Props> = ({ leadsList}) => {

    return (
        <Table striped responsive className='text-center' hover>

            <thead>
                <tr>
                    <th className='text-center'>#</th>
                    <th className='text-center'>Nombre</th>
                    <th className='text-center'>Número de contacto</th>
                    <th className='text-center'>Correo</th>
                    <th className='text-center'>Origen</th>
                    <th className='text-center'>Observación</th>
                </tr>
            </thead>

            <tbody>

                {leadsList.map((leads, index) => (
                    <NormalLeadsRowTable key={index} index={index} leads={leads} />
                ))}

            </tbody>

        </Table>)
}

export default NormalLeadsTableReport