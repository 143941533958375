import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format';
import { Card, CardHeader, CardBody, CardTitle, Label, Row, Col, Alert, FormGroup, Input, FormText, InputGroup, InputGroupText, UncontrolledCollapse, Button } from 'reactstrap';
import { getActiveDefaultPlans} from '../../../../controllers/DefaultPlansController';
import { Contract, DefaultPlans, PaymentModality } from '../../../../interface/interface';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import SignatureInput from '../../../contract/contract-inputs/SignatureInput';
import VerificationFormGroupText from '../verification-form-inputs/VerificationFormGroupText';
import VerificationFormGroupMonetary from '../verification-form-inputs/VerificationFormGroupMonetary';

interface Props {

    paymentModality: PaymentModality
    setPaymentModality: (paymentModality: PaymentModality) => void
    editModality: boolean
    handleEditModality: () => void
    setDefaultPaymentModality: (paymentModality: PaymentModality) => void
    defaultPaymentModality: PaymentModality

}

const VerificationPaymentModality: React.FC<Props> = ({ paymentModality, setPaymentModality, editModality, handleEditModality, defaultPaymentModality, setDefaultPaymentModality }) => {

    const [defaultPlans, setDefaultPlans] = useState<Array<DefaultPlans>>([]);
    const [currentPlan, setCurrentPlan] = useState<string>(paymentModality.plan_type)
 
    useEffect(()=>{
        
    })

    useEffect(() => {
        loadDefaultPlans()
    }, [])

    const handleInputSelect = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;
        setCurrentPlan(value);

        if (value !== "Personalizado") {
            const planSelected = getPlanSelected(parseInt(value))
            setPaymentModality(planSelected)
        } else {

            setPaymentModality(defaultPaymentModality)
        }
    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;

        setPaymentModality({
            ...paymentModality, [name]: value
        });

    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;

        // Eliminar puntos y comas y convertirlo en un número decimal
        const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));

        if (!isNaN(numericValue)) {
            setPaymentModality({
                ...paymentModality, [name]: numericValue
            });
        }

    };

    const handleInputChangeInteger = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;

        const numericValue = parseFloat(value);

        if (!isNaN(numericValue)) {
            setPaymentModality({
                ...paymentModality, [name]: numericValue
            });
        }


    };

    const getPlanSelected = (id: number): PaymentModality => {

        const planSelected = defaultPlans.filter(plan => plan.id === id)[0]

        const { plan_type, total, enrollment, balance, installments_number, installments_value } = planSelected;

        return {
            plan_type: plan_type,
            total: total,
            enrollment: enrollment,
            balance: balance,
            installments_number: installments_number,
            installments_value: installments_value
        }
    }

    const loadDefaultPlans = async () => {
        try {

            const defaultPlansValues = await getActiveDefaultPlans()
            setDefaultPlans(defaultPlansValues.data.plans)

        } catch (error) {
            console.log(error + "")
        }
    }

    return (

        <Card className={CARD_BODY + 'm-3 '}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>MODALIDAD DE PAGO</b>
            </CardHeader>
            <CardBody>
                <CardTitle className='underline text-center tex-white' lg={12}>
                    <Label className='text-white' tag="h4"><b>PRIME ENGLISH PROGRAM</b></Label>
                </CardTitle>
                <Row>
                    <Col>
                        <Alert color="warning" className='m-3 text-center' tag="h4">
                            <b> PLATAFORMA INTERACTIVA MOBILE LEARNING PARA EL APRENDIZAJE DEL IDIOMA INGLÉS CON PROGRAMACIÓN NEUROLINGÜÍSTICA</b>
                        </Alert>
                    </Col>
                </Row>

                <CardTitle className='underline text-center' lg={12}>
                    <Label className='text-white' tag="h4"><b>MODALIDAD DE PAGO</b></Label>
                </CardTitle>

                <Button type='button' color={"danger"} className='col-12' onClick={() => handleEditModality()}> <b>Modificar Modalidad</b> </Button>
                <Row>
                    <Col md={editModality ? (12) : (6)}>
                        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
                            <CardHeader tag="h4" className={CARD_TITLE} >
                                <b>PLAN ACTUAL</b>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Row>
                                        <VerificationFormGroupText
                                            type='text'
                                            md={12} lg={12}
                                            name='plan_type'
                                            value={defaultPaymentModality.plan_type}
                                            onChange={handleInputChange}
                                            readOnly
                                            formText='Tipo de plan'
                                        />

                                        <VerificationFormGroupMonetary
                                            type='text'
                                            md={12} lg={12}
                                            name='total'
                                            value={defaultPaymentModality.total + ",00"}
                                            onChange={handleNumberChange}
                                            formText='Total'
                                            readOnly
                                        />

                                        <VerificationFormGroupMonetary
                                            type='text'
                                            md={12} lg={12}
                                            name='enrollment'
                                            value={defaultPaymentModality.enrollment + ",00"}
                                            onChange={handleNumberChange}
                                            formText='Matricula'
                                            readOnly />

                                        <VerificationFormGroupMonetary
                                            type='text'
                                            md={12} lg={12}
                                            name='balance'
                                            value={defaultPaymentModality.balance + ",00"}
                                            onChange={handleNumberChange}
                                            formText='Saldo'
                                            readOnly />

                                        <VerificationFormGroupText
                                            type='number'
                                            md={12} lg={12}
                                            name='installments_number'
                                            value={defaultPaymentModality.installments_number}
                                            onChange={handleInputChangeInteger}
                                            formText='Número de cuotas'
                                            readOnly />


                                        <VerificationFormGroupMonetary
                                            type='text'
                                            md={12} lg={12}
                                            name='installments_value'
                                            value={defaultPaymentModality.installments_value + ",00"}
                                            onChange={handleNumberChange}
                                            formText='Valor de cuotas'
                                            readOnly />
                                    </Row>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    {!editModality ? (<Col md={editModality ? (6) : (6)}>
                        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
                            <CardHeader tag="h4" className={CARD_TITLE} >
                                <b>EDICIÓN DE MODALIDAD</b>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12} lg={12}>

                                        <FormGroup className="form-outline mb-4">
                                            <Input type="select" name="default_plans" value={currentPlan} onChange={handleInputSelect}>

                                                <option value="Personalizado">Personalizado</option>

                                                {defaultPlans.map((plan, index) => (
                                                    <option key={index} value={plan.id}>{plan.plan_type} ( {plan.installments_number } - Pagos - {plan.id})</option>
                                                ))}
                                            </Input>

                                            <FormText>
                                                Tipo de plan (*)
                                            </FormText>

                                        </FormGroup>
                                    </Col>

                                    <Col md={12} lg={12}>

                                        <FormGroup>

                                            <InputGroup>
                                                <InputGroupText>$</InputGroupText>
                                                <NumericFormat className='form-control'
                                                    name='total'
                                                    type='text'
                                                    thousandSeparator="."
                                                    decimalSeparator=','
                                                    value={paymentModality.total + ",00"}
                                                    onChange={handleNumberChange}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    disabled={currentPlan !== "Personalizado"}
                                                    required />
                                            </InputGroup>

                                            <FormText>
                                                Total (*)
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} lg={12}>
                                        <FormGroup>

                                            <InputGroup>
                                                <InputGroupText>$</InputGroupText>
                                                <NumericFormat className='form-control'
                                                    name='enrollment'
                                                    type='text'
                                                    thousandSeparator="."
                                                    decimalSeparator=','
                                                    value={paymentModality.enrollment + ",00"}
                                                    onChange={handleNumberChange}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    disabled={currentPlan !== "Personalizado"}
                                                    required />
                                            </InputGroup>

                                            <FormText>
                                                Valor Matricula (*)
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} lg={12}>


                                        <FormGroup>

                                            <InputGroup>
                                                <InputGroupText>$</InputGroupText>
                                                <NumericFormat className='form-control'
                                                    name='balance'
                                                    type='text'
                                                    thousandSeparator="."
                                                    decimalSeparator=','
                                                    value={paymentModality.balance + ",00"}
                                                    onChange={handleNumberChange}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    disabled={currentPlan !== "Personalizado"}
                                                    required />
                                            </InputGroup>


                                            <FormText>
                                                Saldo (*)
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} lg={12}>
                                        <FormGroup>
                                            <Input name='installments_number'
                                                type='number'
                                                min={1}
                                                value={paymentModality.installments_number}
                                                disabled={currentPlan !== "Personalizado"}
                                                onChange={handleInputChange}
                                                required />
                                            <FormText>
                                                N° de cuotas (*)
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} lg={12}>
                                        <FormGroup >

                                            <InputGroup>
                                                <InputGroupText>$</InputGroupText>
                                                <NumericFormat className='form-control'
                                                    name='installments_value'
                                                    type='text'
                                                    thousandSeparator="."
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    value={paymentModality.installments_value + ",00"}
                                                    onChange={handleNumberChange}
                                                    allowNegative={false}
                                                    disabled={currentPlan !== "Personalizado"}
                                                    required />
                                            </InputGroup>
                                            <FormText>
                                                Valor de cuotas (*)
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>


                            </CardBody>
                        </Card>
                    </Col>) : (null)}
                </Row>



            </CardBody>

        </Card>
    )
}

export default VerificationPaymentModality
