import React, { useState } from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, Button } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import { ProspectMeeting, User } from '../../../../interface/interface'
import { genericErrorAlert, genericSuccessAlert } from '../../../../services/alertService'
import { createMeetingAdmin, createMeetingComercial } from '../../../../controllers/ProspectMeetingController'


interface Props {
    user: User
    isAdmin?: boolean
    prospectId: string
    prospectMeeting: ProspectMeeting
    setProspectMeeting: (prospectMeeting: ProspectMeeting) => void;
    defaultProspectMeeting: ProspectMeeting
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PBOMeetingForm: React.FC<Props> = ({ prospectMeeting, setProspectMeeting, defaultProspectMeeting, prospectId, onChange, user, isAdmin = false }) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        try {
            setIsSaving(true)

            if (isAdmin) {
                //Agendar cita para administradores
                await createMeetingAdmin(prospectId, prospectMeeting);
            } else {
                //Agendar cita solamente para prospecto propio
                await createMeetingComercial(prospectId, prospectMeeting, user.document_type, user.document_number);
            }

            genericSuccessAlert("Información guardada", "La reunión fue agendada satisfactoriamente.");
            setProspectMeeting(defaultProspectMeeting)

        } catch (error) {

            genericErrorAlert("Error", error + "");
            console.log(error)

        } finally {
            setIsSaving(false);
        }
    }

    return (
        <Card className={CARD_BODY + 'mt-4 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>AGENDAR REUNIÓN</b>
            </CardHeader>

            <CardBody>

                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <FormGroup>

                                <Input type="select" name="modality" value={prospectMeeting.modality} onChange={onChange} required>
                                    <option value="">Seleccione una modalidad</option>
                                    <option value="Presencial">Presencial</option>
                                    <option value="Virtual">Virtual</option>
                                </Input>

                                <FormText>
                                    Modalidad (*)
                                </FormText>
                            </FormGroup>
                        </Col>

                        {prospectMeeting.modality === 'Presencial' ? (<Col md={12}>
                            <FormGroup>

                                <Input type="select" name="place" value={prospectMeeting.place} onChange={onChange} required>
                                    <option value="">Seleccione ubicación</option>
                                    <option value="Empresa">Empresa</option>
                                    <option value="Domicilio">Domicilio</option>
                                </Input>

                                <FormText>
                                    Lugar (*)
                                </FormText>
                            </FormGroup>
                        </Col>) : (null)}

                        {prospectMeeting.place === "Domicilio" ? (<Col md={12}>
                            <FormGroup>

                                <Input type="text" className="mt-2" name="address" value={prospectMeeting.address} onChange={onChange} required />

                                <FormText>
                                    Dirección  (*)
                                </FormText>

                            </FormGroup>
                        </Col>) : (null)}


                        {prospectMeeting.modality !== "" ? (
                            <div>
                                <Col md={12}>
                                    <FormGroup>

                                        <Input type="date" className="mt-2" name="date" value={prospectMeeting.date} onChange={onChange} required />

                                        <FormText>
                                            Fecha  (*)
                                        </FormText>

                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>

                                        <Input type="time" className="mt-2" name="hour" value={prospectMeeting.hour} onChange={onChange} required />

                                        <FormText>
                                            Hora  (*)
                                        </FormText>

                                    </FormGroup>
                                </Col>
                            </div>) : (null)}


                        <Col className='col-12'>
                            <Button type='submit' color='warning' className=' col-12 text-center mb-4' disabled={isSaving}><b>{!isSaving ? "Guardar reunión" : "Guardando reunión..."}</b></Button>
                        </Col>

                    </Row>

                </form>


            </CardBody>
        </Card >
    )
}

export default PBOMeetingForm


