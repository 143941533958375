import React from 'react'
import { Col, Label, Input } from 'reactstrap'
import '../../../../styles/verificationStyles.css'

interface Props {
    disabled: boolean
    handleCheckboxChange: () => void
}

const VerificationFormChecked: React.FC<Props> = ({ disabled, handleCheckboxChange }) => {
    return (
        <Col className="col-1 d-flex align-items-center pb-1">
            <Label className="custom-checkbox mb-0">
                <Input type="checkbox" checked={disabled} onChange={handleCheckboxChange} className="custom-input" required />
                <span className="checkmark"></span>.
            </Label>
        </Col>
    )
}

export default VerificationFormChecked
