import React from 'react'
import { Leads } from '../../../../../../interface/interface'
import { Table } from 'reactstrap'
import NormalLeadsRowTable from '../../leads-normal/leads-normal-table/NormalLeadsRowTable'
import ComercialLeadsRowTable from './ComercialLeadsRowTable'

interface Props {
    leadsList: Array<Leads>,
    setSelectedLead: (leadId: number) => void
}

const ComercialLeadsTableReport: React.FC<Props> = ({ leadsList,setSelectedLead}) => {
    return (
        <Table striped responsive className='text-center' hover>

            <thead>
                <tr>
                    
                    <th className='text-center'>#</th>
                    <th className='text-center'>Nombre</th>
                    <th className='text-center'>Número de contacto</th>
                    <th className='text-center'>Correo</th>
                    <th className='text-center'>Origen</th>
                    <th className='text-center'>Observación</th>
                    <th className='text-center'>Opciones</th>

                </tr>
            </thead>

            <tbody>

                {leadsList.map((leads, index) => (
                    <ComercialLeadsRowTable key={index} index={index} leads={leads} setSelectedLead={setSelectedLead}/>
                ))}

            </tbody>

        </Table>
    )
}

export default ComercialLeadsTableReport
