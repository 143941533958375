/**
 *  Lista de roles
 */

export const ADMIN = "ADMIN";
export const COMERCIAL = "COMERCIAL";
export const ACCESS = "ACCESS";
export const TEACHER = "TEACHER";
export const RECEPTION = "RECEPTION";
export const COMERCIAL_ADMIN = "COMERCIAL_ADMIN";
export const VERIFICATION = "VERIFICATION"
export const MARKETING = "MARKETING"

/**
 * 
 * 
 * Al crear un role se debe agregar a AllowedRole 
 */

export type AllowedRole = typeof ADMIN |
    typeof COMERCIAL |
    typeof ACCESS |
    typeof TEACHER |
    typeof RECEPTION |
    typeof COMERCIAL_ADMIN |
    typeof VERIFICATION |
    typeof MARKETING

/*
 Diccionario de Roles
 */

export const roleDictionary: { [role: string]: number } = {
    [ADMIN]: 1,
    [COMERCIAL]: 2,
    [ACCESS]: 3,
    [TEACHER]: 4,
    [RECEPTION]: 5,
    [COMERCIAL_ADMIN]: 6,
    [VERIFICATION]: 7,
    [MARKETING]: 8
};
