import { useState } from 'react'
import { Col, FormGroup, Input, FormText, Button, InputGroup, InputGroupText, Row } from 'reactstrap';
import { IRegisterValues } from '../../../interface/interface';

interface Props {
    registerValues: IRegisterValues;
    handleDenyCopyPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordInputs: React.FC<Props> = ({ registerValues, handleInputChange, handleDenyCopyPaste }) => {
    const [showPasswordMain, setShowPasswordMain] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const toggleShowPasswordMain = () => {
        setShowPasswordMain(!showPasswordMain);
    };

    const toggleShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    return (
        <>
            <Col md={6} lg={6}>
                <FormGroup >
                    <Row>

                        <Col sm={10} >
                            <Input
                                type={showPasswordMain ? 'text' : 'password'}
                                name='password'
                                onCopy={handleDenyCopyPaste}
                                onPaste={handleDenyCopyPaste}
                                onCut={handleDenyCopyPaste}
                                value={registerValues.password}
                                onChange={handleInputChange}
                                className='mb-1'
                                required
                            />
                        </Col>

                        <Col sm={2} md={2}>
                            <Button onClick={toggleShowPasswordMain} className='col-12'>
                                {showPasswordMain ? <i className="bi bi-eye-fill" ></i> : <i className="bi bi-eye-slash-fill"></i>}
                            </Button>
                        </Col>
                        <FormText>Contraseña (*)</FormText>

                    </Row>

                </FormGroup>
            </Col>


            <Col md={6} lg={6} >
                <FormGroup>
                    <Row>
                    <Col sm={10} >
                            <Input
                                type={showPasswordConfirm ? 'text' : 'password'}
                                name='confirmPassword'
                                value={registerValues.confirmPassword}
                                onCopy={handleDenyCopyPaste}
                                onPaste={handleDenyCopyPaste}
                                onCut={handleDenyCopyPaste}
                                onChange={handleInputChange}
                                className='mb-1'
                                required
                            />
                        </Col>
                        <Col sm={2} md={2}>
                            <Button onClick={toggleShowPasswordConfirm} className='col-12'>
                                {showPasswordConfirm ? <i className="bi bi-eye-fill"></i> : <i className="bi bi-eye-slash-fill"></i>}
                            </Button>
                        </Col>
                    </Row>


                    <FormText>Confirmar Contraseña</FormText>
                </FormGroup>
            </Col>


        </>
    );
}

export default PasswordInputs
