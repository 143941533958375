import React from 'react'
import AdminNavbar from '../role-navbar/AdminNavbar'
import ComercialNavbar from '../role-navbar/ComercialNavbar'
import { Button } from 'reactstrap'
import ReceptionNavbar from '../role-navbar/ReceptionNavbar'
import TeacherNavbar from '../role-navbar/TeacherNavbar'
import NotRoleNavbar from '../role-navbar/NotRoleNavbar'
import ComercialAdminNavbar from '../role-navbar/ComercialAdminNavbar'
import VerificationNavbar from '../role-navbar/VerificationNavbar'
import MarketingNavbar from '../role-navbar/MarketingNavbar';

interface Props {
    handleLogout: () => void
    roleId: number
}

const NavbarRoleSelected: React.FC<Props> = ({ handleLogout, roleId }) => {

    const getCurrentNavbarRole = () => {
        switch (roleId) {
            case 1:
                return <AdminNavbar handleLogout={handleLogout}></AdminNavbar>
            case 2:
                return <ComercialNavbar handleLogout={handleLogout}></ComercialNavbar>
            case 4:
                return <TeacherNavbar handleLogout={handleLogout}></TeacherNavbar>
            case 5:
                return <ReceptionNavbar handleLogout={handleLogout}></ReceptionNavbar>
            case 6:
                return <ComercialAdminNavbar handleLogout={handleLogout}></ComercialAdminNavbar>
            case 7:
                return <VerificationNavbar handleLogout={handleLogout}></VerificationNavbar>
            case 8:
                return <MarketingNavbar handleLogout={handleLogout}></MarketingNavbar>
            default:
                return <NotRoleNavbar handleLogout={handleLogout}></NotRoleNavbar>
        }
    }

    return <div>{getCurrentNavbarRole()}</div>
}

export default NavbarRoleSelected
