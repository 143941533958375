import axiosInstance from '../services/axiosInstance';

import { BASE_URL as mainRoute } from '../services/axiosInstance';

export const createBeneficiaryClassOriginal = async (beneficiary_id: number | string, level_id: number | string, lesson_id: number | string, date: string, classroom_id: number | string, schedule_time_id: number | string, modality: string) => {
    const classBeneficiary = await axiosInstance.post(mainRoute + 'class/createBeneficiaryClass', { beneficiary_id: beneficiary_id, level_id: level_id, lesson_id: lesson_id, date: date, classroom_id: classroom_id, schedule_time_id: schedule_time_id, modality: modality })
    return classBeneficiary;
}

export const createBeneficiaryClass = async (beneficiary_id: number | string, level_id: number | string, lesson_id: number | string, date: string, classroom_id: number | string, schedule_time_id: number | string, modality: string, teacher_document_type: string, teacher_document_number: string) => {
    const classBeneficiary = await axiosInstance.post(mainRoute + 'class/createBeneficiaryClass', { beneficiary_id: beneficiary_id, level_id: level_id, lesson_id: lesson_id, date: date, classroom_id: classroom_id, schedule_time_id: schedule_time_id, modality: modality, teacher_document_type: teacher_document_type, teacher_document_number: teacher_document_number })
    return classBeneficiary;
}

export const cancelBeneficiaryClass = async (beneficiary_id: string | number, class_id: string | number) => {

    return await axiosInstance.delete(mainRoute + 'class/cancelBeneficiaryClass/' + beneficiary_id + "/" + class_id)
}


export const getAllClass = async () => {
    const classList = await axiosInstance.get(mainRoute + 'class/getAllClass')
    return classList;
}

export const getTeacherClass = async (document_type: string, document_number: string) => {
    const classList = await axiosInstance.get(mainRoute + 'class/getTeacherClass/' + document_type + '/' + document_number)
    return classList;
}

export const getTeacherByClassId = async (class_id: string | number) => {
    const teacherInformation = await axiosInstance.get(mainRoute + 'class/getTeacherByClassId/' + class_id)
    return teacherInformation;
}

export const getBeneficiaryByClassId = async (class_id: string | number) => {
    const beneficiaryList = await axiosInstance.get(mainRoute + 'class/getBeneficiaryByClassId/' + class_id)
    return beneficiaryList;
}
export const getClassScheduleById = async (class_id: string | number) => {
    const beneficiaryList = await axiosInstance.get(mainRoute + 'class/getClassScheduleById/' + class_id)
    return beneficiaryList;
}

export const getClassInformationDetails = async (class_id: string | number) => {
    return await axiosInstance.get(mainRoute + 'class/getClassInformationDetails/' + class_id)
}

export const getDailyClassReport = async (date: string) => {
    return await axiosInstance.get(mainRoute + 'class/getDailyClassReport/' + date)
}

export const getBeneficiaryClassHistory = async (beneficiaryId: string | number) => {
    return await axiosInstance.get(mainRoute + 'class/getBeneficiaryClassHistory/' + beneficiaryId)
}

export const getBeneficiaryCanceledClassHistory = async (beneficiaryId: string | number) => {
    return await axiosInstance.get(mainRoute + 'class/getBeneficiaryCanceledClassHistory/' + beneficiaryId)
}

export const getFreeTeacherListBySchedule = async (date: string, schedule_time_id: number, classroom_id: string) => {
    return await axiosInstance.get(mainRoute + 'class/getFreeTeacherListBySchedule/' + date + '/' + schedule_time_id + '/' + classroom_id)
}

export const sendDailyClassReportEmail = async (date: string) => {
    return await axiosInstance.post(mainRoute + 'class/sendDailyClassReportEmail/' + date)
}

export const getTodayAttendance = async () => {
    return await axiosInstance.get(mainRoute + 'class/getTodayAttendance');
}

export const getAttendanceCodeClass = async (classId: string | number) => {
    return await axiosInstance.get(mainRoute + 'class/getAttendanceCodeClass/' + classId);
}

export const getAttendanceByDate = async (date: string) => {
    return await axiosInstance.get(mainRoute + 'class/getAttendanceByDate/' + date);
}

export const getTeacherAttendanceByDate = async (date: string) => {
    return await axiosInstance.get(mainRoute + 'class/getTeacherAttendanceByDate/' + date);
}

export const checkClassAttendance = async (classId: string|number, attendanceCode: string) => {
    return await axiosInstance.post(mainRoute + 'class/checkClassAttendance/' + classId + '/' + attendanceCode);
}

