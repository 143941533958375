import React from 'react'
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, Row } from 'reactstrap'
import { HolderWork } from '../../../interface/interface'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'

interface Props {
    holderWork: HolderWork
    setHolderWork: (holderWork: HolderWork) => void
    enabled: boolean
}

const HolderWorkForm: React.FC<Props> = ({ holderWork, setHolderWork,enabled }) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setHolderWork({
            ...holderWork, [name]: value
        });
    };

    return (
        <Card className={CARD_BODY +'m-3 contract-backgroud'} style={{pointerEvents:(enabled?'auto':'none')}}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>Información Laboral del Titular - {(enabled?'Nuevo':'Ya registrado')}</b>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input name='name'
                                value={holderWork.name}
                                onChange={handleInputChange}
                                required />
                            <FormText>
                                Nombre de la empresa (*)
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='position'
                                value={holderWork.position}
                                onChange={handleInputChange}
                            />
                            <FormText>
                                Cargo (*)
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='phone_number'
                                value={holderWork.phone_number}
                                onChange={handleInputChange}
                                required />
                            <FormText>
                                Número Teléfonico de la Empresa (*)
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='address'
                                value={holderWork.address}
                                onChange={handleInputChange}
                                required />
                            <FormText>
                                Dirección de la empresa (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default HolderWorkForm