import React from 'react'
import { useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, FormText, Input, Row, Table } from 'reactstrap'
import { Beneficiary, Holder, HolderWork } from '../../../interface/interface'
import Swal from 'sweetalert2';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';

interface Props {
  beneficiaries: Array<Beneficiary>
  setBeneficiaries: (beneficiary: Array<Beneficiary>) => void
  beneficiaryAmount: number
  setBeneficiaryAmount: (beneficiaryAmount: number) => void
  holder: Holder
}

const BeneficiaryForm: React.FC<Props> = ({ beneficiaries, setBeneficiaries, beneficiaryAmount, setBeneficiaryAmount, holder }) => {

  const beneficiaryDefaultValue: Beneficiary = {
    document_number: "",
    document_type: "CC",
    gender: "Masculino",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    birthdate: "",
    phone_number: "",
    whatsapp_number: "",
    address: "",
    email: "",
    relationship: ""
  }

  const [newBeneficiary, setNewBeneficiary] = useState<Beneficiary>(beneficiaryDefaultValue);
  const [holderIsBeneficiary, setHolderIsBeneficiary] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewBeneficiary({
      ...newBeneficiary, [name]: value
    });
  };

  const addItem = () => {

    if (!allInputsAreComplete()) return

    if (!compareBeneficiaryDocument()) return

    setBeneficiaries([...beneficiaries, newBeneficiary]);
    setNewBeneficiary(beneficiaryDefaultValue);
    setBeneficiaryAmount(beneficiaryAmount + 1);

  };


  const removeBeneficiary = (index: number) => {

    Swal.fire({
      title: 'Borrar Beneficiario',
      text: "¿Esta seguro de borrar al beneficiario?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado',
          'Beneficiario eliminado',
          'error'
        )
        const updatedBeneficiaries = [...beneficiaries];
        updatedBeneficiaries.splice(index, 1);
        setBeneficiaries(updatedBeneficiaries);
        setBeneficiaryAmount(beneficiaryAmount - 1);
      }
    })
  };


  const compareBeneficiaryDocument = () => {

    const beneficiary = beneficiaries.filter(beneficiary => (beneficiary.document_number === newBeneficiary.document_number && beneficiary.document_type === newBeneficiary.document_type))

    if (beneficiary.length !== 0) {
      Swal.fire({
        icon: 'error',
        title: 'No puede agregar dos beneficiarios con el mismo <b>Documento de Identificación </b>',

      })
      return false
    }
    return true;
  }

  const loadHolderInformation = () => {
    Swal.fire(
      {
        icon: 'success',
        title: 'Información del titular Cargada',
      }
    )

    setNewBeneficiary({
      document_number: holder.document_number,
      document_type: holder.document_type,
      gender: holder.gender,
      first_name: holder.first_name,
      second_name: holder.second_name,
      last_name: holder.last_name,
      second_last_name: holder.second_last_name,
      birthdate: holder.birthdate,
      phone_number: holder.phone_number,
      whatsapp_number: holder.phone_number,
      address: holder.address,
      email: holder.email,
      relationship: "Ninguno"
    })
  }

  const allInputsAreComplete = () => {

    const noCompleteInputs = []

    if (!newBeneficiary.first_name.trim()) {
      noCompleteInputs.push("Primer Nombre")
    }

    if (!newBeneficiary.last_name.trim()) {
      noCompleteInputs.push("Primer Apellido")
    }

    if (!newBeneficiary.second_last_name.trim()) {
      noCompleteInputs.push("Segundo Apellido")
    }

    if (!newBeneficiary.document_type.trim()) {
      noCompleteInputs.push("Tipo de documento")
    }
    if (!newBeneficiary.birthdate.trim()) {
      noCompleteInputs.push("Fecha de nacimiento")
    }
    if (!newBeneficiary.gender.trim()) {
      noCompleteInputs.push("Genero")
    }

    if (!newBeneficiary.phone_number.trim()) {
      noCompleteInputs.push("Número de teléfono")
    }

    if (!newBeneficiary.whatsapp_number.trim()) {
      noCompleteInputs.push("Número de WhatsApp")
    }

    if (!newBeneficiary.email.trim()) {
      noCompleteInputs.push("Correo Electronico")

    }

    const regularPhrase = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regularPhrase.test(newBeneficiary.email)) {
      noCompleteInputs.push("Proporciona un correo valido")
    }

    if (!newBeneficiary.address.trim()) {
      noCompleteInputs.push("Dirección de residencia")
    }

    if (!newBeneficiary.relationship.trim()) {
      noCompleteInputs.push("Parentesco")
    }


    if (noCompleteInputs.length > 0) {
      completeAllInputsAlert("Beneficiario", noCompleteInputs.join(" - "))
      return false
    }
    return true
  }

  const completeAllInputsAlert = (title: string, information: string) => {
    title = "<b>" + title + "</b>"
    Swal.fire({
      icon: 'error',
      title: 'Completa todos campos del ' + title,
      text: information,
    })
  }

  return (
    <Card className={CARD_BODY + 'm-3 contract-backgroud'} >
      <CardHeader tag="h4" className={CARD_TITLE} >
        <b>Datos del beneficiario</b>
      </CardHeader>

      <CardBody className='text-white'>
        {(beneficiaries.length !== 2) ? <li className='btn btn-warning col-12' onClick={() => loadHolderInformation()}><b>Traer Información del Titular</b></li> : null}

        <CardTitle className='underline' tag="h5" lg={12}>
          Nombre completo
        </CardTitle>

        <Row>

          <Col md={6} lg={6} >
            <FormGroup>
              <Input name='first_name'
                value={newBeneficiary.first_name}
                onChange={handleInputChange}
              />
              <FormText>
                Primer Nombre (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='second_name'
                value={newBeneficiary.second_name}
                onChange={handleInputChange}
              />
              <FormText>
                Segundo Nombre
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='last_name'
                value={newBeneficiary.last_name}
                onChange={handleInputChange}
              />
              <FormText>
                Primer Apellido (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='second_last_name'
                value={newBeneficiary.second_last_name}
                onChange={handleInputChange}
              />
              <FormText>
                Segundo Apellido (*)
              </FormText>
            </FormGroup>
          </Col>
        </Row>

        <CardTitle className='underline' tag="h5" lg={12}>
          Información de Identidad
        </CardTitle>

        <Row>

          <Col md={6} lg={6}>

            <FormGroup className="form-outline mb-4">
              <Input type="select" name="document_type" value={newBeneficiary.document_type} onChange={handleInputChange}>

                <option value="CC">(C.C) Cédula de Ciudadania</option>
                <option value="CE">(C.E) Cédula de Extranjería</option>
                <option value="TI">(T.I) Tarjeta de Identidad</option>
                <option value="PA">(P.A) Pasaporte</option>

              </Input>
              <FormText>
                Tipo de documento de identidad (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='document_number'
                value={newBeneficiary.document_number}
                onChange={handleInputChange}
              />
              <FormText>
                Número de documento
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="date" name='birthdate'
                value={newBeneficiary.birthdate}
                onChange={handleInputChange}
              />
              <FormText>
                Fecha de nacimiento (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup className="form-outline mb-4">
              <Input type="select" name="gender" value={newBeneficiary.gender} onChange={handleInputChange}>

                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="No Binario">No Binario</option>
                <option value="Otro">Otro</option>

              </Input>
              <FormText>
                Genero (*)
              </FormText>
            </FormGroup>
          </Col>
        </Row>

        <CardTitle className='underline' tag="h5" lg={12}>
          Información personal
        </CardTitle>

        <Row>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='phone_number'
                value={newBeneficiary.phone_number}
                onChange={handleInputChange}
              />
              <FormText>
                Número de teléfono (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='whatsapp_number'
                value={newBeneficiary.whatsapp_number}
                onChange={handleInputChange}
              />
              <FormText>
                Número de WhatsApp (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="email" name='email'
                value={newBeneficiary.email}
                onChange={handleInputChange}

              />
              <FormText>
                Correo Electronico (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='address'
                value={newBeneficiary.address}
                onChange={handleInputChange}
              />
              <FormText>
                Dirección de residencia (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='relationship'
                value={newBeneficiary.relationship}
                onChange={handleInputChange}
              />
              <FormText>
                Parentesco (*)
              </FormText>
            </FormGroup>
          </Col>

        </Row>

        {(beneficiaries.length !== 2) ? <li className='btn btn-success col-12' onClick={() => addItem()}>{beneficiaries.length == 0 ? (<div><b>GUARDAR INFORMACIÓN DEL PRIMER BENEFICIARIO</b></div>) : (<div><b>GUARDAR INFORMACIÓN DEL SEGUNDO BENEFICIARIO</b></div>)}</li> : null}

        <hr />

        <Row>
          {beneficiaries.map((beneficiary, index) => (

            <Col md={12} key={index}>

              <Card className='m-3'>
                <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
                  <b>Datos del Beneficiario {index + 1}</b>
                </CardHeader>
                <CardBody>

                  <Table striped hover className='table'>
                    <tbody>
                      <tr>
                        <th className="table-heading">Tipo de Documento:</th>
                        <td>{beneficiary.document_type}</td>
                        <th className="table-heading">Número de Documento:</th>
                        <td>{beneficiary.document_number}</td>
                      </tr>
                      <tr>
                        <th className="table-heading">Nombres:</th>
                        <td>{`${beneficiary.first_name} ${beneficiary.second_name}`}</td>
                        <th className="table-heading">Apellidos:</th>
                        <td>{`${beneficiary.last_name} ${beneficiary.second_last_name}`}</td>
                      </tr>
                      <tr>
                        <th className="table-heading">Fecha de Nacimiento:</th>
                        <td>{beneficiary.birthdate}</td>
                        <th className="table-heading">Género:</th>
                        <td>{beneficiary.gender}</td>
                      </tr>
                      <tr>
                        <th className="table-heading">Teléfono:</th>
                        <td>{beneficiary.phone_number}</td>
                        <th className="table-heading">Número de WhatsApp:</th>
                        <td>{beneficiary.whatsapp_number}</td>
                      </tr>
                      <tr>
                        <th className="table-heading">Email:</th>
                        <td>{beneficiary.email}</td>
                        <th className="table-heading">Parentesco:</th>
                        <td>{beneficiary.relationship}</td>
                      </tr>
                      <tr>
                        <th className="table-heading">Dirección:</th>
                        <td>{beneficiary.address}</td>
                        <th className="table-heading"></th>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                  <li className='col-12 btn btn-danger' onClick={() => removeBeneficiary(index)}><b>Eliminar</b></li>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default BeneficiaryForm