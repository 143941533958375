import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Input, FormText, CardTitle, Button, ModalFooter, Alert, CardHeader } from 'reactstrap';
import { CARD_TITLE, CARD_BODY } from '../../../services/classService';
import { User } from '../../../interface/interface';
import { getUserDetailsByDocument } from '../../../controllers/UserController';
import UserCard from '../cards/UserCard';
import { genericErrorAlert } from '../../../services/alertService';


interface Props {
    isOpen: boolean;
    toggle: () => void;
    user: User;
}

const UserDetailsModal: React.FC<Props> = ({ isOpen, toggle, user }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [userDetails, setUserDetails] = useState<User | null>(null);

    useEffect(() => {
        loadUserDetails();
    }, []);

    const loadUserDetails = async () => {
        try {
            const userValues = await getUserDetailsByDocument(user.document_type, user.document_number);
            setUserDetails(userValues.data[0]);

        } catch (error: any) {
            genericErrorAlert("Error al cargar los detalles de Usuario",".");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "1000px" }}>
            <ModalHeader tag="h4" className={CARD_TITLE} toggle={toggle}>  <b> DETALLE EMPLEADO</b></ModalHeader>

            <ModalBody className={CARD_BODY + "contract-backgroud"} >

                {isLoading ? (< Alert color="warning" className="text-center m-3">
                    <b className="h2">Cargando detalles del usuario...</b>
                </Alert>) : (

                    <Col className='p-2'>
                        <UserCard user={userDetails as User}></UserCard>
                    </Col>
                )}

            </ModalBody>

            <ModalFooter className={CARD_TITLE + " text-white"}>
                <Button color='secondary' onClick={toggle}>
                    <b>Cerrar</b>
                </Button>
            </ModalFooter>

        </Modal>
    );
}

export default UserDetailsModal