import React, { useState, useEffect } from 'react'
import { Col, FormGroup, Input, FormText, Row, Button, CardTitle, Label } from 'reactstrap'
import { ProspectReport, User } from '../../../../interface/interface';
import { genericErrorAlert, genericSuccessAlert, internalServerErrorAlert } from '../../../../services/alertService';
import PBOInputOrigin from '../../pbo-form/pbo-form-inputs/PBOInputOrigin';
import { getFilterOptions, getProspectByOneWeakness, getProspectByOrigin, getProspectByProfession, getProspectByStatus, getProspectByUser, getProspectByWeakness } from '../../../../controllers/ProspectController';
import ManualCloseAlert from '../../../alerts/ManualCloseAlert';
import PBOInputStatus from '../../pbo-form/pbo-form-inputs/PBOInputStatus';

interface Props {
    setCurrentProspectList: (prospectList: Array<ProspectReport>) => void
    prospectList: Array<ProspectReport>
}

interface Profession {
    name: string
}

const PBOFilterManager: React.FC<Props> = ({ prospectList, setCurrentProspectList }) => {

    const [filter, setFilter] = useState({ type: "" });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [selectedFilterOption, setSelectedFilterOption] = useState({ origin: "", profession: "", status: "", user: "", writing: false, speaking: false, reading: false, listening: false });

    const [search, setSearch] = useState({ type: "" });

    const [isGenerating, setGenerating] = useState<boolean>(false);
    const [professionList, setProfessionList] = useState<Array<Profession>>([]);
    const [userList, setUserList] = useState<Array<User>>([]);


    const handleInputChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearch({
            ...search, [name]: value
        });
    };

    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    };

    const handleInputChangeMainFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFilter({
            ...filter, [name]: value
        });
    };

    const handleInputChangeSecundaryFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSelectedFilterOption({
            ...selectedFilterOption, [name]: value
        });
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const skillName = event.target.name;
        setSelectedFilterOption({
            ...selectedFilterOption,
            [skillName]: event.target.checked,
        });
    };

    const handleGenerateFilteredProspects = async () => {

        let filteredProspectList = []
        let response = null;

        switch (filter.type) {
            case "origin":
                response = await getProspectByOrigin(selectedFilterOption.origin);
                filteredProspectList = response.data.prospects;
                break;
            case "profession":
                response = await getProspectByProfession(selectedFilterOption.profession);
                filteredProspectList = response.data.prospects;
                break;
            case "weakness":

                if (search.type === "simple") {

                    response = await getProspectByOneWeakness(selectedOption as string);

                } else {
                    response = await getProspectByWeakness(selectedFilterOption.writing, selectedFilterOption.reading, selectedFilterOption.speaking, selectedFilterOption.listening);

                }

                filteredProspectList = response.data.prospects;
                break;
            case "user":
                const user = selectedFilterOption.user.split(",");
                response = await getProspectByUser(user[0], user[1]);
                filteredProspectList = response.data.prospects;
                break;
            case "status":
                response = await getProspectByStatus(selectedFilterOption.status);
                filteredProspectList = response.data.prospects;
                break;
        }

        setCurrentProspectList(filteredProspectList);

    }

    useEffect(() => {

        loadPBOFilterInformation();

    }, [])

    const loadPBOFilterInformation = async () => {

        try {

            const response = await getFilterOptions();

            setUserList(response.data.user_list);
            setProfessionList(response.data.profession_list);


        } catch (error) {

            internalServerErrorAlert("Error", error + "")
            console.log(error);

        } finally {
            setIsLoading(false);
        }

    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        try {
            setGenerating(true);

            await handleGenerateFilteredProspects();
            genericSuccessAlert("Filtro de prospectos","Se filtraron correctamente los prospectos.")

        } catch (error: any) {

            console.log(error)


        } finally {
            setGenerating(false);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row>

                <Col md={6} lg={6}>
                    <FormGroup className="form-outline mb-4">

                        <Input type="select" name="type" value={filter.type} onChange={handleInputChangeMainFilter} required>
                            <option value="">Seleccione un tipo de filtro</option>
                            <option value="origin">Origen</option>
                            <option value="profession">Profesión</option>
                            <option value="weakness">Habilidades - (Falencias)</option>
                            <option value="user">Asesor</option>
                            <option value="status">Estado</option>
                        </Input>
                        <FormText>
                            Tipo de filtro
                        </FormText>

                    </FormGroup>
                </Col>


                {
                    filter.type === "origin" ? (
                        <Col md={6}>
                            <FormGroup className="form-outline mb-4">

                                <PBOInputOrigin name='origin' onChange={handleInputChangeSecundaryFilter} value={selectedFilterOption.origin} required />

                                <FormText>
                                    Filtro por Origen
                                </FormText>
                            </FormGroup>
                        </Col>) : (null)
                }

                {
                    filter.type === "status" ? (
                        <Col md={6}>
                            <FormGroup className="form-outline mb-4">

                                <PBOInputStatus name='status' onChange={handleInputChangeSecundaryFilter} value={selectedFilterOption.status} required />

                                <FormText>
                                    Estado de prospecto
                                </FormText>

                            </FormGroup>
                        </Col>) : (null)
                }

                {
                    filter.type === "weakness" ? (
                        <Col md={6}>
                            <FormGroup className="form-outline mb-4">

                                <Input type="select" name="type" value={search.type} onChange={handleInputChangeSearch} required>
                                    <option value="">Seleccione un tipo de busqueda</option>
                                    <option value="simple">Simple</option>
                                    <option value="multiple">Multiple</option>
                                </Input>
                                <FormText>
                                    Tipo de busqueda
                                </FormText>

                            </FormGroup>
                        </Col>
                    ) : (null)
                }

                {
                    (filter.type === "weakness" && search.type !== "") ? (<div className='mb-4'>

                        {search.type !== "simple" ? (<Col md={12} >

                            <Row className='text-white text-center'>

                                <Col>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="writing"
                                            checked={selectedFilterOption.writing}
                                            onChange={handleCheckboxChange}
                                        />
                                        Escribir
                                    </Label></Col>

                                <Col>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="reading"
                                            checked={selectedFilterOption.reading}
                                            onChange={handleCheckboxChange}
                                        />
                                        Leer
                                    </Label></Col>

                                <Col>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="speaking"
                                            checked={selectedFilterOption.speaking}
                                            onChange={handleCheckboxChange}
                                        />
                                        Hablar
                                    </Label></Col>

                                <Col>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="listening"
                                            checked={selectedFilterOption.listening}
                                            onChange={handleCheckboxChange}
                                        />
                                        Escuchar
                                    </Label></Col>

                            </Row>
                        </Col>) : (<Col className='col-12'>

                            <div className='text-white'>
                                <Row className='text-center'>
                                    <Col>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    value="writing"
                                                    checked={selectedOption === 'writing'}
                                                    onChange={handleOptionChange}
                                                />
                                                Escribir
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    value="reading"
                                                    checked={selectedOption === 'reading'}
                                                    onChange={handleOptionChange}
                                                />
                                                Leer
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    value="speaking"
                                                    checked={selectedOption === 'speaking'}
                                                    onChange={handleOptionChange}
                                                />
                                                Hablar
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    value="listening"
                                                    checked={selectedOption === 'listening'}
                                                    onChange={handleOptionChange}
                                                />
                                                Escuchar
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Col>)}


                    </div>



                    ) : (null)
                }



                {
                    filter.type === "profession" ? (
                        <Col md={6}>
                            <FormGroup className="form-outline mb-4">
                                <Input type='select' name='profession' onChange={handleInputChangeSecundaryFilter} required>
                                    <option value="">Seleccione una profesión</option>
                                    {professionList.map((profession, index) =>
                                        <option key={index} value={profession.name}>{profession.name}</option>)}
                                </Input>

                                <FormText>
                                    Por Profesión
                                </FormText>
                            </FormGroup>
                        </Col>) : (null)
                }

                {
                    filter.type === "user" ? (
                        <Col md={6}>
                            <FormGroup className="form-outline mb-4">
                                <Input type='select' name='user' onChange={handleInputChangeSecundaryFilter} required>
                                    <option value="">Seleccione un asesor</option>
                                    {userList.map((user, index) =>
                                        <option key={index} value={user.document_type + "," + user.document_number}>{"(" + user.document_type + " - " + user.document_number + " ) - " + user.first_name + " " + user.second_name + " " + user.last_name + " " + user.second_last_name}</option>)}
                                </Input>

                                <FormText>
                                    Por asesor
                                </FormText>
                            </FormGroup>
                        </Col>) : (null)
                }

                <Col>
                    <Button type="submit" color="warning" className='col-12' disabled={isGenerating}><i className="bi bi-search" /> <b>{isGenerating ? "Filtrando..." : "Filtrar"}</b></Button>
                </Col>
            </Row>

        </form>
    )
}

export default PBOFilterManager
