import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Alert, Row, Col } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../../services/classService'
import { genericErrorAlert } from '../../../../../services/alertService'
import { Leads } from '../../../../../interface/interface'
import { getAllCreatorLeads } from '../../../../../controllers/LeadsController'
import NormalLeadsTableReport from './leads-normal-table/NormalLeadsTableReport'

const LeadsNormalReport = () => {

    const [isLoading, setLoading] = useState(true);
    const [leadsList, setLeadsList] = useState<Array<Leads>>([]);

    useEffect(() => {


        loadPersonalLeads();

    }, [])

    const loadPersonalLeads = async () => {

        try {

            const loadList = await getAllCreatorLeads();
            setLeadsList(loadList.data);

        } catch (error) {

            console.log(error);
            genericErrorAlert("Error al cargar los leads", "Recargue la página")

        } finally {
            setLoading(false);
        }

    }

    return (
        <Card className={CARD_BODY + 'm-2 contract-backgroud'}>

            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>MIS LEADS</b>
            </CardHeader>

            <CardBody>

                {isLoading ? (<Alert color="warning" className="text-center m-3">
                    <b className="h2">Cargando listado de leads...</b>
                </Alert>) : (<div>{leadsList.length === 0 ? (<Alert color="warning" className="text-center m-3">
                    <b className="h2">No se encontro registro de leads</b>
                </Alert>) :

                    <Card>

                        <Row>
                            <NormalLeadsTableReport leadsList={leadsList} />
                        </Row>

                    </Card>}

                </div>)}

            </CardBody>

        </Card>
    )
}

export default LeadsNormalReport
