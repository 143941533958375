import React, { useEffect, useState } from 'react'
import { Prospect, ProspectMeeting, User } from '../../../interface/interface';
import { Modal, ModalHeader, ModalBody, Alert, Col, ModalFooter, Button, Row, Collapse } from 'reactstrap';
import { CARD_TITLE, CARD_BODY } from '../../../services/classService';
import VirtualMeeting from '../cards/VirtualMeeting';
import PresencialMeeting from '../cards/PresencialMeeting';
import PBOFilterManager from '../pbo-report/pbo-report-filter/PBOFilterManager';
import PBOMeetingForm from '../pbo-form/pbo-form-meeting/PBOMeetingForm';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    user: User;
    prospectMeetingList: Array<ProspectMeeting>;
    prospectId: string;
}

const MeetingModalPBO: React.FC<Props> = ({ isOpen, toggle, user, prospectMeetingList, prospectId }) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [visibleCreateMeeting, setvisibleCreateMeeting] = useState<boolean>(false);
    const [meetingList, setMeetingList] = useState<Array<ProspectMeeting>>([]);

    const [virtualMeetingList, setVirtualMeetingList] = useState<Array<ProspectMeeting>>(prospectMeetingList.filter(meeting => meeting.modality === "Virtual"));
    const [presencialMeetingList, setPresencialMeetingList] = useState<Array<ProspectMeeting>>(prospectMeetingList.filter(meeting => meeting.modality === "Presencial"));

    const defaultProspectMeeting: ProspectMeeting = {
        modality: "",
        place: "",
        address: "",
        hour: "",
        date: ""
    }

    const [prospectMeeting, setProspectMeeting] = useState<ProspectMeeting>(defaultProspectMeeting);



    useEffect(() => {
        loadProspectMeetings();
    }, []);

    const loadProspectMeetings = async () => {

        setIsLoading(false);
    }

    const handleInputChangeProspectsMeeting = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "modality") {
            setProspectMeeting({
                ...prospectMeeting, place: "", address: "", modality: value as "Presencial" | "Virtual"
            })
            return
        }

        if (name === "place") {
            setProspectMeeting({
                ...prospectMeeting, address: "", place: value as "Empresa" | "Domicilio"
            })
            return
        }

        setProspectMeeting({
            ...prospectMeeting, [name]: value
        });

    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "1300px" }}>
            <ModalHeader tag="h4" className={CARD_TITLE} toggle={toggle}> <b>DETALLE DE REUNIONES</b></ModalHeader>

            <ModalBody className={CARD_BODY} >

                <div className='m-3'>
                    <Row className=''>
                        <Col>
                            <Button color='danger' className='bg col-12' onClick={() => setvisibleCreateMeeting(!visibleCreateMeeting)}>
                                <b> Agendar Reunión </b> {!visibleCreateMeeting ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>}
                            </Button>
                        </Col>
                    </Row>

                    <Collapse isOpen={visibleCreateMeeting}>

                        <PBOMeetingForm defaultProspectMeeting={defaultProspectMeeting} setProspectMeeting={setProspectMeeting} user={user} prospectId={prospectId} onChange={handleInputChangeProspectsMeeting} prospectMeeting={prospectMeeting} isAdmin ></PBOMeetingForm>

                    </Collapse>

                </div>

                {isLoading ? (< Alert color="warning" className="text-center m-3">
                    <b className="h2">Cargando historial de reuniones del prospecto...</b>
                </Alert>) : (

                    <Row>

                        <Col className='col-12'>
                            <VirtualMeeting prospectMeetingList={virtualMeetingList}></VirtualMeeting>
                        </Col>
                        <Col className='col-12'>
                            <PresencialMeeting prospectMeetingList={presencialMeetingList}></PresencialMeeting>
                        </Col>

                    </Row>
                )}

            </ModalBody>

            <ModalFooter className={CARD_TITLE + "text-white"}>
                <Button color='secondary' onClick={toggle}>
                    <b>Cerrar</b>
                </Button>
            </ModalFooter>

        </Modal>
    )
}

export default MeetingModalPBO
