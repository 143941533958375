import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Button, Col, Row, FormGroup, FormText, Input, ToastHeader, Spinner } from 'reactstrap'

import Swal from 'sweetalert2';
import { createBeneficiaryClass, getFreeTeacherListBySchedule } from '../../../../controllers/ClassController';
import { getAllClassrooms } from '../../../../controllers/ClassroomController';
import { getAllLessonGroupByLevelId } from '../../../../controllers/LessonController';
import { getAllScheduleTime } from '../../../../controllers/ScheduleTimeController';
import { Lesson, Classroom, ScheduleTime, User } from '../../../../interface/interface';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import { genericErrorAlert, beneficiaryClassSuccessAlert, beneficiaryClassErrorAlert, genericSuccessAlert, handleErrorResponseAlert } from '../../../../services/alertService';
import LoadingStatus from '../../../utils/LoadingStatus';

interface Props {
    beneficiarySelected: string
}

const ScheduleClassForm: React.FC<Props> = ({ beneficiarySelected }) => {

    const [selectedTeacher, setSelectedTeacher] = useState("");

    //Niveles
    const [conditioningLessons, setConditioningLessons] = useState<Array<Lesson>>([]);
    const [captationLessons, setCaptationLessons] = useState<Array<Lesson>>([]);
    const [appropiationLessons, setAppropiationLessons] = useState<Array<Lesson>>([]);
    const [empowermentLessons, setEmpowermentLessons] = useState<Array<Lesson>>([]);

    const [currentLessons, setCurrentLessons] = useState<Array<Lesson>>([]);
    const [levelSelected, setLevelSelected] = useState("Acondicionamiento");
    const [lessonSelected, setLessonSelected] = useState("");
    const [classRoomSelected, selectedClassRoom] = useState("");
    const [dateSelected, setDateSelected] = useState("");
    const [selectedScheduleTime, setSelectedScheduleTime] = useState("")
    const [modalitySelected, setModalitySelected] = useState<string>("Presencial")

    //Lista de salones
    const [classRoomList, setClassRoomList] = useState<Array<Classroom>>([]);

    //Horarios de atención
    const [scheduleTimeList, setScheduleTimeList] = useState<Array<ScheduleTime>>([]);

    //Lista de docentes diponibles
    const [freeTeacherList, setFreeTeacherList] = useState<Array<User>>([]);

    //Tiempo de espera para agendar clase.
    const [isLoadingSaving, setIsLoadingSaving] = useState(false);

    //Tiempo de espera para cargar disponibilidad de docente.
    const [loadingFreeTeacherList, setLoadingFreeTeacherList] = useState(false);

    const handleReset = () => {
        setCurrentLessons(conditioningLessons);
        setLevelSelected("Acondicionamiento");
        setLessonSelected("");
        selectedClassRoom("");
        setDateSelected("");
        setSelectedScheduleTime("");
        setSelectedTeacher("");
        setFreeTeacherList([]);
    }

    const loadAllClassByLevel = async () => {

        try {

            const allClassByLevel = await getAllLessonGroupByLevelId();
            setConditioningLessons(allClassByLevel.data.lessons[0])
            setCaptationLessons(allClassByLevel.data.lessons[1]);
            setAppropiationLessons(allClassByLevel.data.lessons[2]);
            setEmpowermentLessons(allClassByLevel.data.lessons[3]);
            setCurrentLessons(allClassByLevel.data.lessons[0]);

        } catch (error) {
            genericErrorAlert("Error al cargar los niveles", "Recargue la página");
            console.log(error)
        }


    }

    const loadScheduleTime = async () => {
        try {
            const scheduleTimeValues = await getAllScheduleTime();
            setScheduleTimeList(scheduleTimeValues.data.schedule_time)
        } catch (error) {
            genericErrorAlert("Error al cargar los horarios", "Recargue la página");
            console.log(error)
        }
    }

    const loadClassroomList = async () => {
        try {
            const classRoomListValues = await getAllClassrooms();
            setClassRoomList(classRoomListValues.data.classrooms);
        } catch (error) {
            genericErrorAlert("Error al cargar el listado de salones", "Recargue la página");
            console.log(error)
        }
    }

    useEffect(() => {


        loadAllClassByLevel();
        loadClassroomList();
        loadScheduleTime();


    }, [])



    const handleSelectLevel = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { value } = e.target;

        setLevelSelected(value);
        setLessonSelected("");

        switch (value) {
            case "Acondicionamiento":
                setCurrentLessons(conditioningLessons);
                break;

            case "Captación":
                setCurrentLessons(captationLessons);
                break;

            case "Apropiación":
                setCurrentLessons(appropiationLessons);
                break;

            case "Empoderamiento":
                setCurrentLessons(empowermentLessons)
                break;
        }
    };

    const handleSelectClassRoom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        selectedClassRoom(value);
        setSelectedTeacher("");
        setFreeTeacherList([]);
    };

    const handleSelectTeacher = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSelectedTeacher(value);
    };

    const handleSelectLesson = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setLessonSelected(value);
    };

    const handleScheduleTime = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSelectedScheduleTime(value);
        setSelectedTeacher("");
        setFreeTeacherList([]);
    }

    const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDateSelected(value);
        setSelectedTeacher("");
        setFreeTeacherList([]);
    }

    const handleSelectModality = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        console.log(value)
        setModalitySelected(value);
    };

    const handleLoadFreeTeacher = async () => {

        if (classRoomSelected.trim() === "") {
            genericErrorAlert("Completa todos los campos", "Selecciona un salon de clases");
            return

        }

        if (dateSelected.trim() === "") {
            genericErrorAlert("Completa todos los campos", "Falta seleccionar una fecha");
            return

        }

        if (selectedScheduleTime.trim() === "") {
            genericErrorAlert("Completa todos los campos", "Selecione un horario");
            return
        }

        try {

            setLoadingFreeTeacherList(true);

            const teacherList = await getFreeTeacherListBySchedule(dateSelected, Number(selectedScheduleTime), classRoomSelected);

            setFreeTeacherList(teacherList.data);

            if (teacherList.data.length === 0) {
                genericErrorAlert("No se encontro docentes disponibles", "Seleccione otro horario con disponibilidad docente")
            } else {
                genericSuccessAlert("Docentes disponibles obtenidos", "Seleccione un docente");
            }


        } catch (error:any) {

            handleErrorResponseAlert(error,"Error al cargar docentes diponibles","Intentelo de nuevo")
            console.log(error);

        } finally {
            setLoadingFreeTeacherList(false);
            setSelectedTeacher("")
        }

    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        if (selectedTeacher.trim() === "") {
            genericErrorAlert("Seleccione un docente", "Por favor cargue la disponibilida del docente y seleccionela.")
            return
        }

        Swal.fire({
            title: '¿Esta seguro de agendar clase?',
            html:
                'Una vez enviada, se le <b>notificará</b> al beneficiario sobre la clase, ',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    setIsLoadingSaving(true);
                    const teacher = selectedTeacher.split(",");
                    await createBeneficiaryClass(beneficiarySelected, levelSelected, lessonSelected, dateSelected, classRoomSelected, selectedScheduleTime, modalitySelected, teacher[0], teacher[1]);
                    beneficiaryClassSuccessAlert("Cita agendada", "La cita fue agenda satisfactoriamente.");
                    handleReset();

                } catch (error: any) {

                    handleErrorResponseAlert(error, "Error al Agendar clase", "Intentelo de nuevo")
                    console.log(error)

                } finally {
                    setIsLoadingSaving(false);
                }
            }
        })



    }
    return (
        <Card className={CARD_BODY + "m-1 contract-backgroud"}>


            <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
                <b>AGENDAR CLASE</b>
            </CardHeader>

            <CardBody>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-outline mb-4">
                                <Input type="select" name="modality" value={modalitySelected} onChange={handleSelectModality} required>
                                    <option value="">Seleccione un tipo de modalidad</option>
                                    <option value="Presencial">Presencial</option>
                                    <option value="Virtual">Virtual</option>
                                </Input>

                                <FormText>
                                    Modalidad (*)
                                </FormText>
                            </FormGroup>
                        </Col>

                        <Col md={6} lg={6}>

                            <FormGroup className="form-outline mb-4">
                                <Input type="select" name="levelSelected" value={levelSelected} onChange={handleSelectLevel} required>
                                    <option value="Acondicionamiento">Acondicionamiento</option>
                                    <option value="Captación">Captación</option>
                                    <option value="Apropiación">Apropiación</option>
                                    <option value="Empoderamiento">Empoderamiento</option>
                                </Input>

                                <FormText>
                                    Niveles (*)
                                </FormText>
                            </FormGroup>
                        </Col>

                        <Col md={6} lg={6}>
                            <FormGroup className="form-outline mb-4">
                                <Input type="select" name="lesson" value={lessonSelected} onChange={handleSelectLesson} required>
                                    <option value="">Seleccióna una lección</option>
                                    {currentLessons.map((lesson, index) => (
                                        <option key={index} value={lesson.id}>{lesson.name}</option>
                                    ))}
                                </Input>

                                <FormText>
                                    Lecciones(*)
                                </FormText>
                            </FormGroup>
                        </Col>

                        <Col md={6} lg={6}>
                            <FormGroup className=" mb-4">

                                <Input type="date" name="date" value={dateSelected} onChange={handleDate} required />
                                <FormText>
                                    Fecha(*)
                                </FormText>
                            </FormGroup>
                        </Col>

                        <Col md={6} lg={6}>
                            <FormGroup className="form-outline mb-4">
                                <Input type="select" name="schedule_time" value={selectedScheduleTime} onChange={handleScheduleTime} required >
                                    <option value="">Seleccióna un horario</option>
                                    {scheduleTimeList.map((scheduleTime, index) => (
                                        <option key={index} value={scheduleTime.id}>{scheduleTime.hour}</option>
                                    ))}
                                </Input>

                                <FormText>
                                    Horarios (*)
                                </FormText>
                            </FormGroup>
                        </Col>

                        <Col md={6} lg={6}>
                            <FormGroup className="form-outline mb-4">
                                <Input type="select" name="classroom" value={classRoomSelected} onChange={handleSelectClassRoom} required >
                                    <option value="">Seleccióna un salon</option>
                                    {classRoomList.map((classroom, index) => (
                                        <option key={index} value={classroom.id}>{classroom.name}</option>
                                    ))}
                                </Input>

                                <FormText>
                                    Salones(*)
                                </FormText>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>

                        <Col md={6} lg={6}>
                            <FormGroup className="form-outline mb-4">
                                <Input type="select" name="selectedTeacher" value={selectedTeacher} onChange={handleSelectTeacher} disabled={freeTeacherList.length === 0} required >
                                    {freeTeacherList.length === 0 ? (<option value="">Cargue la disponibilidad docente</option>) : (<option value="">Seleccióna un docente</option>)}
                                    {freeTeacherList.map((teacher, index) => (
                                        <option key={index} value={teacher.document_type + "," + teacher.document_number} >{teacher.first_name + " " + teacher.last_name + " - (" + teacher.document_number + " - " + teacher.document_type + ")"}</option>
                                    ))}
                                </Input>

                                <FormText>
                                    Docentes con disponibilidad (*)
                                </FormText>
                            </FormGroup>

                        </Col>

                        <Col md={6} lg={6}>
                            <Button className='btn col-12 mb-2'
                                color='warning'
                                type='button'
                                onClick={handleLoadFreeTeacher}
                                disabled={loadingFreeTeacherList}>
                                <LoadingStatus text='Cargar disponibilidad docente' loadingText='Cargando disponibilidad docente' status={loadingFreeTeacherList} />
                            </Button>
                        </Col>
                    </Row>

                    <Row >
                        <Col md={12} >
                            <Button className='btn col-12 text-center' color='success' type='submit' disabled={isLoadingSaving}>
                                <LoadingStatus loadingText='Agendado clase' text='Agendar clase' status={isLoadingSaving} />
                            </Button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    )
}

export default ScheduleClassForm