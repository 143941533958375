import { useState } from 'react'
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Form, FormGroup, FormText, Input, InputGroup, InputGroupText, Row } from 'reactstrap'
import { Holder } from '../../../interface/interface'
import NumberFormat, { NumberFormatValues, NumericFormat } from 'react-number-format';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';

interface Props {
  holder: Holder
  setHolder: (holder: Holder) => void
  enabled: boolean
}

const HolderForm: React.FC<Props> = ({ holder, setHolder, enabled }) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setHolder({
      ...holder, [name]: value
    });
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Eliminar puntos y comas y convertirlo en un número decimal
    const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
    setHolder({
      ...holder,
      [name]: numericValue
    });


  };

  return (
    <Card className={CARD_BODY + ' m-3 contract-backgroud '} style={{ pointerEvents: (enabled ? 'auto' : 'none') }} >
      <CardHeader tag="h4" className={CARD_TITLE}  >
        <b>Datos del Titular - {(enabled ? 'Nuevo' : 'Ya registrado')}</b>
      </CardHeader>
      <CardBody className='text-white'>

        <CardTitle className='underline ' tag="h5" lg={12}>
          Nombre completo
        </CardTitle>

        <Row>
          <Col md={6} lg={6}>
            <FormGroup>
              <Input name='first_name'
                value={holder.first_name}
                onChange={handleInputChange}
                required />
              <FormText >
                Primer Nombre (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='second_name'
                value={holder.second_name}
                onChange={handleInputChange}
              />
              <FormText>
                Segundo Nombre
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='last_name'
                value={holder.last_name}
                onChange={handleInputChange}
                required />
              <FormText>
                Primer Apellido (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='second_last_name'
                value={holder.second_last_name}
                onChange={handleInputChange}
                required />
              <FormText>
                Segundo Apellido (*)
              </FormText>
            </FormGroup>
          </Col>
        </Row>

        <CardTitle className='underline' tag="h5" lg={12}>
          Información de Identidad
        </CardTitle>

        <Row>

          <Col md={6} lg={6}>

            <FormGroup className="form-outline mb-4">
              <Input type="select" name="document_type" value={holder.document_type} onChange={handleInputChange}>

                <option value="CC">(C.C) Cédula de Ciudadania</option>
                <option value="CE">(C.E) Cédula de Extranjería</option>
                <option value="TI">(T.I) Tarjeta de Identidad</option>
                <option value="PA">(P.A) Pasaporte</option>

              </Input>
              <FormText>
                Tipo de documento de identidad (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='document_number'
                value={holder.document_number}
                onChange={handleInputChange}
              />
              <FormText>
                Número de documento (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="date" name='birthdate'
                value={holder.birthdate}
                onChange={handleInputChange}
                required />
              <FormText>
                Fecha de nacimiento (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup className="form-outline mb-4">
              <Input type="select" name="gender" value={holder.gender} onChange={handleInputChange}>

                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="No Binario">No Binario</option>
                <option value="Otro">Otro</option>

              </Input>
              <FormText>
                Genero (*)
              </FormText>
            </FormGroup>
          </Col>
        </Row>

        <CardTitle className='underline' tag="h5" lg={12}>
          Información personal
        </CardTitle>

        <Row>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='phone_number'
                value={holder.phone_number}
                onChange={handleInputChange}
                required
              />
              <FormText>
                Número de teléfono (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='whatsapp_number'
                value={holder.whatsapp_number}
                onChange={handleInputChange}
                required
              />
              <FormText>
                Número de WhatsApp (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="email" name='email'
                value={holder.email}
                onChange={handleInputChange}
                required
              />
              <FormText>
                Correo Electronico (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <Input type="text" name='address'
                value={holder.address}
                onChange={handleInputChange}
                required />
              <FormText>
                Dirección de residencia (*)
              </FormText>
            </FormGroup>
          </Col>

          <Col md={6} lg={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <NumericFormat className='form-control'
                  name='salary'
                  type='text'
                  thousandSeparator="."
                  decimalSeparator=','
                  decimalScale={2}
                  value={holder.salary + ",00"}
                  allowNegative={false}
                  onChange={handleNumberChange}
                  required />
              </InputGroup>
              <FormText>
                Promedio Salarial (*)
              </FormText>
            </FormGroup>
          </Col>

        </Row>
      </CardBody>
    </Card>
  )
}

export default HolderForm