import React from 'react'
import { Reference } from '../../../../interface/interface'
import { Col, Row } from 'reactstrap'
import ReferenceCard from './ReferenceCardForm'

interface Props {
    referenceOne: Reference
    setReferenceOne: (reference: Reference) => void
    referenceTwo: Reference
    setReferenceTwo: (reference: Reference) => void
}

const ReferenceForm: React.FC<Props> = ({ referenceOne, setReferenceOne, referenceTwo, setReferenceTwo }) => {
    return (
        <Row>
            <Col md={6} lg={6}>
                <ReferenceCard reference={referenceOne} setReference={setReferenceOne} />
            </Col>

            <Col md={6} lg={6}>
                <ReferenceCard reference={referenceTwo} setReference={setReferenceTwo} />
            </Col>
        </Row>
    )
}

export default ReferenceForm