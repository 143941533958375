import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Row, Col, Alert } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'
import { useNavigate } from 'react-router-dom';
import '../../../styles/scheduleStyles.css'

import { Calendar, momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/es';
import { getAllClass} from '../../../controllers/ClassController';
import ScheduleModal from '../schedule-modal/ScheduleModal';
import { CustomMessages, User } from '../../../interface/interface';


interface Props{
  user:User
}

const PersonalSchedule:React.FC<Props>  = ({user}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [classList, setClassList] = useState<Array<any>>([]);

  const [modalOpenState, setModalOpenState] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState({ classId: "" });

  const toggleModal = async () => {

    setModalOpenState(!modalOpenState);

  };


  const messages: CustomMessages = {
    allDay: 'Todo el día',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay enventos programados para esta fecha',
    showMore: total => `+${total} more`,
  };

  const navigate = useNavigate();

  const localizer = momentLocalizer(moment);
  useEffect(() => {

      loadClass(user)


  }, [])

  const loadClass = async (user:User) => {

    try {
      const classList = await getAllClass();
      classListFormat(classList.data.class)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const classListFormat = async (classList: Array<any>) => {

    const reformedClassList: React.SetStateAction<any[]> = []

    classList.forEach(currentClass => {
      const jsonDate = currentClass.date;
      const jsonHour = currentClass.hour;

      // Concatenar la fecha y la hora en un formato compatible con JavaScript
      const dateTimeString = `${jsonDate.split('T')[0]}T${jsonHour}`;

      const date = new Date(dateTimeString);
      date.setHours(date.getHours() + 1);
      reformedClassList.push({ start: convertToNewDate(dateTimeString), end: date, title: currentClass.lesson, class_id: currentClass.class_id });
    });

    setClassList(reformedClassList);

  }

  function convertToNewDate(dateTimeString: string) {
    // Obtener los valores de fecha y hora de la cadena
    const year = parseInt(dateTimeString.substring(0, 4));
    const month = parseInt(dateTimeString.substring(5, 7)) - 1;
    const day = parseInt(dateTimeString.substring(8, 10));
    const hour = parseInt(dateTimeString.substring(11, 13));
    const minute = parseInt(dateTimeString.substring(14, 16));

    // Crear el objeto Date
    const newDate = new Date(year, month, day, hour, minute);

    return newDate;
  }

  const min = new Date();
  min.setHours(6, 0, 0, 0);

  const max = new Date();
  max.setHours(22, 0, 0, 0);

  const handleEventClick = (event: any) => {
    setModalValues({ classId: event.class_id })
    toggleModal();
  };

  const CustomEvent = ({ event }: any) => {
    return (
      <div onClick={() => handleEventClick(event)}>
        <Row className='col-12'>
          <Col className='col-12 mt'>
            <b>Clase N°:</b> {event.class_id}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Card className={CARD_BODY + ' m-3 contract-backgroud'}>
      <CardHeader tag="h4" className={CARD_TITLE + ' text-center'}>
        <b>LISTADO DE CITAS</b>
      </CardHeader>
      <CardBody>
        <Row >
          {
            isLoading ? (
              <Alert color="warning" className="text-center">
                <b className="h2">Cargando clases...</b>
              </Alert>) :
              <Col >
                <Calendar
                  style={{ minHeight: 800, width: '100%' }}
                  defaultView="month"
                  className='p-3 rounded h'
                  localizer={localizer}
                  events={classList}
                  min={min}
                  max={max}
                  components={{
                    event: CustomEvent,
                  }}
                  messages={messages}
                />
              </Col>

          }


        </Row>
      </CardBody>

      {modalOpenState ? <ScheduleModal classId={modalValues.classId} isOpen={modalOpenState} toggle={toggleModal} /> : null}

    </Card>
  );
};
export default PersonalSchedule 