import React from 'react'
import { Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup, Input, FormText, Table } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import { Beneficiary } from '../../../../interface/interface'
import VerificationFormGroupText from '../verification-form-inputs/VerificationFormGroupText'
import { dateformatISO } from '../../../../utils/dateUtils'
import VerificactionFormGroupDocumentType from '../verification-form-inputs/VerificactionFormGroupDocumentType'
import VerificationFormGroupGender from '../verification-form-inputs/VerificationFormGroupGender'

interface Props {
    beneficiaries: Array<Beneficiary>
    setBeneficiaries: (beneficiary: Array<Beneficiary>) => void
}

const VerificationBeneficiarySection: React.FC<Props> = ({ beneficiaries, setBeneficiaries }) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        
        const { name, value, id } = e.target;

        const newArray = [...beneficiaries];
        newArray[index] = { ...newArray[index], [name]: value };
        setBeneficiaries(newArray);

    };

    return (
        <Row>
            {
                beneficiaries.map((beneficiary, index) =>

                    <Col key={index} lg={beneficiaries.length > 1 ? (6) : (12)} md={12}>
                        <Card className={CARD_BODY + 'm-3 contract-backgroud'} >
                            <CardHeader tag="h4" className={CARD_TITLE} >
                                <b>Datos del beneficiario - ({index + 1})</b>
                            </CardHeader>

                            <CardBody className='text-white p-4'>

                                <CardTitle className='underline' tag="h5" lg={12}>
                                    Nombre completo
                                </CardTitle>

                                <Row>

                                    <VerificationFormGroupText
                                        type='text'
                                        md={12} lg={12}
                                        name='first_name'
                                        value={beneficiary.first_name}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Primer Nombre'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='text'
                                        md={12} lg={12}
                                        name='second_name'
                                        value={beneficiary.second_name}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Segundo Nombre'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='text'
                                        md={12} lg={12}
                                        name='last_name'
                                        value={beneficiary.last_name}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Primer Apellido'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='text'
                                        md={12} lg={12}
                                        name='second_last_name'
                                        value={beneficiary.second_last_name}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Segundo Apellido'
                                        required
                                    />

                                </Row>

                                <CardTitle className='underline' tag="h5" lg={12}>
                                    Información de Identidad
                                </CardTitle>

                                <Row>

                                    <VerificactionFormGroupDocumentType
                                        type='select'
                                        md={12} lg={12}
                                        name="document_type"
                                        value={beneficiary.document_type}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Tipo de documento de identidad'
                                        required
                                    />


                                    <VerificationFormGroupText
                                        type='text'
                                        md={12} lg={12}
                                        name='document_number'
                                        value={beneficiary.document_number}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Número de documento'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='date'
                                        md={12} lg={12}
                                        name='birthdate'
                                        value={dateformatISO(beneficiary.birthdate)}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Fecha de nacimiento'
                                        required
                                    />

                                    <VerificationFormGroupGender
                                        type='select'
                                        md={12} lg={12}
                                        name="gender"
                                        value={beneficiary.gender}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Género'
                                        required />
                                </Row>

                                <CardTitle className='underline' tag="h5" lg={12}>
                                    Información personal
                                </CardTitle>

                                <Row>

                                    <VerificationFormGroupText
                                        type='text'
                                        md={12} lg={12}
                                        name='phone_number'
                                        value={beneficiary.phone_number}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Número de teléfono'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='text'
                                        md={12} lg={12}
                                        name='whatsapp_number'
                                        value={beneficiary.whatsapp_number}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Número de WhatsApp'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='email'
                                        md={12} lg={12}
                                        name='email'
                                        value={beneficiary.email}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Correo Electronico'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='email'
                                        md={12} lg={12}
                                        name='address'
                                        value={beneficiary.address}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Dirección de residencia'
                                        required
                                    />

                                    <VerificationFormGroupText
                                        type='email'
                                        md={12} lg={12}
                                        name='relationship'
                                        value={beneficiary.relationship}
                                        onChange={(e) => handleInputChange(e, index)}
                                        formText='Parentesco'
                                        required
                                    />

                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                )
            }


        </Row>
    )
}

export default VerificationBeneficiarySection
