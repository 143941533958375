import React, { useEffect, useState } from 'react';
import './App.css';
import RouterManager from './routes/RouterManager';
import { User } from './interface/interface';
import { getCookieValue } from './services/cookieService';

function App() {

  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {

    loadAuth();

  }, [])

  const loadAuth = () => {

    const cookie = getCookieValue("nbp-management-auth");

    if (cookie) {

      const { user, token } = cookie;

      setUser(user);
      setToken(token);
    }
  }

  return (

    <div className="principal-container">
      <RouterManager token={token} setToken={setToken} user={user as User} setUser={setUser}></RouterManager>
    </div>


  );
}

export default App;
