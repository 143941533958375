import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, Button, Table, Alert } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import { getActiveDefaultPlans, getAllDefaultPlans} from '../../../../controllers/DefaultPlansController';
import { genericErrorAlert } from '../../../../services/alertService';
import {PaymentModality } from '../../../../interface/interface';
import PaymentModalityRow from './PaymentModalityRow';

const PaymentModalityOptions = () => {

    const [defaultPlans, setDefaultPlans] = useState<Array<PaymentModality>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

        loadDefaultPlans();

    }, [])

    const loadDefaultPlans = async () => {

        try {
            const defaultPlansValues: any = await getAllDefaultPlans()
            setDefaultPlans(defaultPlansValues.data.plans)
        } catch (error: any) {
            const { title, message } = error.response.data;
            genericErrorAlert(title, message);
        } finally {
            setIsLoading(false);
        }
    }

    const updateDefaultPlanModality = (paymentModality: PaymentModality) => {
        setDefaultPlans((prevDefaultPlans) => {
            const updatedPlanIndex = prevDefaultPlans.findIndex((plan) => plan.id === paymentModality.id);

            if (updatedPlanIndex === -1) {
                return prevDefaultPlans;
            }

            const updatedPlan = [...prevDefaultPlans];
            updatedPlan.splice(updatedPlanIndex, 1, paymentModality);
            return updatedPlan;
        });
    };

    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>MODALIDAD DE PAGO</b>
            </CardHeader>
            <CardBody>

                {isLoading ? (
                    <Row>
                        <Col>
                            <Alert color="warning" className="text-center m-3">
                                <b className="h2">Cargando Modalidades...</b>
                            </Alert>
                        </Col>
                    </Row>) : (<Card className='py-3'>
                        <Row>
                            <Col>
                                <Table striped responsive className='text-center' hover>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>#</th>
                                            <th className='text-center'>Nombre</th>
                                            <th className='text-center'>Estado</th>
                                            <th className='text-center'>Total</th>
                                            <th className='text-center'>Valor Matricula</th>
                                            <th className='text-center'>Saldo</th>
                                            <th className='text-center'>N° de cuotas</th>
                                            <th className='text-center'>Valor de cuotas</th>
                                            <th className='text-center'>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {defaultPlans?.map((plan, index) =>
                                            (<PaymentModalityRow key={index} index={index} planModality={plan} updateDefaultPlanModality={updateDefaultPlanModality} />)
                                        )}

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>)}




            </CardBody>
        </Card>
    )
}

export default PaymentModalityOptions

