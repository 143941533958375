import { MDBNavbarNav, MDBNavbarLink } from 'mdb-react-ui-kit'
import React from 'react'
import navOptions, { navIcons } from '../narvbar-options/NavbarOptions'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { GiEarthAmerica } from 'react-icons/gi';
import { TbWorldPlus} from "react-icons/tb";
import {TbWorldSearch} from "react-icons/tb";

interface Props {
    handleLogout: () => void
}

const MarketingNavbar: React.FC<Props> = ({ handleLogout }) => {
    return (
        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 fw-bold'>

            <NavItem>
                <NavLink tag={Link} to="/home" style={{ textDecoration: 'none', color: 'black' }}>
                    <i className={navIcons.home}></i>{navOptions.home}
                </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret  style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <GiEarthAmerica /> {navOptions.leads}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/marketing/leads/manager" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                          <TbWorldPlus  fontSize="18px" className='pr-3'/> {navOptions.leadsManagement}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem>
                        <Link to="/marketing/leads/personal" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                          <TbWorldSearch  fontSize="18px"/> {navOptions.myLeads}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <MDBNavbarLink className='d-flex justify-content-between' onClick={handleLogout} style={{ cursor: 'pointer' }}><div><b>{navOptions.logout} <i className={navIcons.logout}></i> </b></div></MDBNavbarLink>

        </MDBNavbarNav>
    )
}

export default MarketingNavbar