import React from 'react'
import VerificationPill from '../../verification-custom-components/VerificationPill'
import { Table } from 'reactstrap'
import { VerificationAssignment } from '../../../../interface/interface'

interface Props{
  verifyAssigmentList:Array<VerificationAssignment>
}

const VerificationTableReport:React.FC<Props> = ({verifyAssigmentList}) => {
  return (
    <Table striped responsive className='text-center' hover>
      <thead>
        <tr>
          <th className='text-center'>#</th>
          <th className='text-center'>N° de contrato</th>
          <th className='text-center'>Tipo</th>
          <th className='text-center'>Identificación (Verificador)</th>
          <th className='text-center'>Empleado (Verificador)</th>
          <th className='text-center'>Activo</th>
          <th className='text-center'>Verificado</th>
          <th className='text-center'>Estado</th>
          <th className='text-center'>Observaciones</th>

        </tr>
      </thead>
      <tbody>

        {verifyAssigmentList.map((verifyAssigment, index) =>

        (<tr key={index}>
          <td>
            <p><b>{index + 1}</b></p>
          </td>
          <td>
            {verifyAssigment.contract_id}
          </td>
          <td>
            {verifyAssigment.contract_type}
          </td>
          <td>
            {verifyAssigment.checker_document_number} - ({verifyAssigment.checker_document_type})
          </td>
          <td>
            <p>{verifyAssigment.checker_first_name + " " + verifyAssigment.checker_second_name + " " + verifyAssigment.checker_last_name + " " + verifyAssigment.checker_second_last_name}</p>
          </td>
          <td>
            <VerificationPill text={verifyAssigment.is_active ? ("En curso") : ("Cerrada")} status={verifyAssigment.is_active} />
          </td>
          <td>
            <VerificationPill text={verifyAssigment.is_verify ? ("Verificado") : ("Sin verificar")} status={verifyAssigment.is_verify} />

          </td>
          <td>
            <VerificationPill text={verifyAssigment.is_verify === verifyAssigment.is_active === true ? ("Rechazado") : (verifyAssigment.is_verify ? (verifyAssigment.status ? ("Aprobado") : ("Rechazado")) : ("Pendiente"))} status={verifyAssigment.status} />

          </td>
          <td>
            {verifyAssigment.observation}
          </td>

        </tr>)
        )}
      </tbody>
    </Table>
  )
}

export default VerificationTableReport