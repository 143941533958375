import React from 'react'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import { Beneficiary } from '../../../interface/interface'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'
import {dateFormatDDMMYYY} from '../../../utils/dateUtils'

interface Props {
    beneficiary: Beneficiary
    index: number
}

const BeneficiaryCard: React.FC<Props> = ({ beneficiary, index }) => {

    return (
        <Card className={CARD_BODY + 'm-3'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>DATOS DEL BENEFICIARIO {index}</b>
            </CardHeader>
            <CardBody>
                <Table striped responsive hover className='table'>
                    <tbody>
                        <tr>
                            <th className="table-heading">Tipo de Documento:</th>
                            <td>{beneficiary.document_type}</td>
                            <th className="table-heading">Número de Documento:</th>
                            <td>{beneficiary.document_number}</td>
                        </tr>
                        <tr>
                            <th className="table-heading">Nombres:</th>
                            <td>{`${beneficiary.first_name} ${beneficiary.second_name}`}</td>
                            <th className="table-heading">Apellidos:</th>
                            <td>{`${beneficiary.last_name} ${beneficiary.second_last_name}`}</td>
                        </tr>
                        <tr>
                            <th className="table-heading">Fecha de Nacimiento:</th>
                            <td>{dateFormatDDMMYYY(beneficiary.birthdate)}</td>
                            <th className="table-heading">Género:</th>
                            <td>{beneficiary.gender}</td>
                        </tr>
                        <tr>
                            <th className="table-heading">Teléfono:</th>
                            <td>{beneficiary.phone_number}</td>
                            <th className="table-heading">Número de WhatsApp:</th>
                            <td>{beneficiary.whatsapp_number}</td>
                        </tr>
                        <tr>
                            <th className="table-heading">Email:</th>
                            <td>{beneficiary.email}</td>
                            <th className="table-heading">Parentesco:</th>
                            <td>{beneficiary.relationship}</td>
                        </tr>
                        <tr>
                            <th className="table-heading">Dirección:</th>
                            <td>{beneficiary.address}</td>
                            <th className="table-heading"></th>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </CardBody>
        </Card>

    )
}

export default BeneficiaryCard