import React, { useState, useEffect } from 'react';
import '../../styles/authStyles.css'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { User } from '../../interface/interface';
import { signin } from '../../controllers/AuthController';
import neuron from '../../images/nbp-management/nbp-neuron.png'
import { getCookieValue } from '../../services/cookieService';
import { setAuthorizationHeader } from '../../services/axiosInstance';
import { url } from 'inspector';
import { MDBBtn, MDBCheckbox, MDBInput } from 'mdb-react-ui-kit';

interface Props {
    setToken: (token: string | null) => void
    setUser: (user: User | null) => void
}

const Login: React.FC<Props> = ({ setToken, setUser }) => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        existToken()

    }, [])

    const existToken = () => {

        const cookie = getCookieValue("nbp-management-auth");

        if (cookie) {
            navigate("/home")
        }

    }

    const [loginValues, setLoginValues] = useState({ documentNumber: "", password: "", documentType: "" })

    const [error, setError] = useState<any>(null);

    const completeAllInputsAlert = (title: string, information: string) => {
        title = "<b>" + title + "</b>"
        Swal.fire({
            icon: 'error',
            title: 'Completa todos los campos para el ' + title,
            text: information,
        })
    }

    const verifyUserValues = () => {

        const noCompleteInputs = [];

        if (loginValues.documentNumber.trim() === "") {
            noCompleteInputs.push("Número de Identificación");
        }

        if (loginValues.documentType.trim() === "") {
            noCompleteInputs.push("Tipo de Documento");
        }

        if (loginValues.password.trim() === "") {
            noCompleteInputs.push("Contraseña");
        }

        if (noCompleteInputs.length > 0) {
            completeAllInputsAlert("Inicio de sesión", noCompleteInputs.join(" - "))
        }

        if (noCompleteInputs.length === 0) {
            return true
        }

        return false;
    }

    const loginSuccessAlert = (firstName: string, lastName: string) => {

        const customWelcome = "Bienvenido <b>" + firstName + " " + lastName + "<b/>"

        Swal.fire({
            title: 'Inicio de sesión exitoso',
            width: 400,
            padding: '1em',
            imageUrl: 'https://pep.nbpteam.com/wp-content/uploads/2023/10/nbp-management.png',
            imageWidth: 300,
            background: '#fff',
            html: customWelcome
        })
    }


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();


        if (!verifyUserValues()) {
            return
        }

        try {

            setIsLoading(true);
            const result = await signin(loginValues.documentType, loginValues.documentNumber, loginValues.password)
            setIsLoading(false);
            const { token, user } = result.data;

            //localStorage.setItem('nbp-management-auth', JSON.stringify({ token: token, user: user }));
            setCookie("nbp-management-auth", JSON.stringify({ token: token, user: user }), 60 * 24)

            setToken(token);
            setUser(user);
            setAuthorizationHeader(token);

            //Alerta de login satisfactorio

            loginSuccessAlert(user.first_name, user.last_name);
            //Redirección al home (Ruta protegida)
            navigate('home');

        } catch (error) {
            console.log(error)
            setError("Credenciales Invalidas...");
            setTimeout(() => {
                setError(null);
                setIsLoading(false);
            }, 4000)

        }


    };

    function setCookie(name: string, value: any, minutes: number) {
        var d = new Date();
        d.setTime(d.getTime() + (minutes * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + "; " + expires;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginValues({
            ...loginValues, [name]: value
        });
    };

    const [selectedCheckbox, setSelectedCheckbox] = useState<string | null>(null);

    const handleCheckboxChange = (option: string) => {
        setSelectedCheckbox(option === selectedCheckbox ? null : option);
        setLoginValues({ ...loginValues, documentType: option })
    };


    const getButtonStyle = (option: string) => {
        return {
            borderBottom: 'none', // Remover la línea negra
            backgroundColor: selectedCheckbox === option ? 'rgba(0, 123, 255, 0.5)' : 'transparent',
            color: 'black', // Cambiar el color del texto a negro siempre
            border: '1px solid gray', // Agregar borde de color gris
        };
    };

    return (
        <section className=" d-flex justify-content-center align-items-center container gradient-form  my-3 " >
            <Container>

                <Row className="d-flex justify-content-center align-items-center ">
                    <Col>
                        <Card className="rounded-3 text-black nbp-background-logo">
                            <Row className="g-0">
                                <Col lg={6}>

                                    <CardBody className="p-md-5 mx-md-4">
                                        <div className="text-center">
                                            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} style={{ width: '185px' }} alt="Logo" />
                                            <h4 className="mt-1 mb-5 pb-1">{process.env.REACT_APP_OWNER} Management</h4>
                                        </div>

                                        {error ? <Alert color="danger" className="text-center ">
                                            <b>{error}</b>
                                        </Alert> : null}

                                        <form onSubmit={handleSubmit}>

                                            <MDBInput
                                                label='Número de Identificación'
                                                className='mb-3'
                                                name="documentNumber"
                                                id="documentNumber"
                                                placeholder="Identificación"
                                                onChange={handleInputChange}
                                                value={loginValues.documentNumber}
                                                type='text'
                                                size='lg'
                                            />

                                            <div>
                                                <MDBBtn
                                                    type='button'
                                                    className='col-12 mb-1'
                                                    style={getButtonStyle('CC')}
                                                    onClick={() => handleCheckboxChange('CC')}
                                                >
                                                    (C.C) Cédula de Ciudadanía
                                                </MDBBtn>
                                                <MDBBtn
                                                    type='button'
                                                    className='col-12 mb-1'
                                                    style={getButtonStyle('CE')}
                                                    onClick={() => handleCheckboxChange('CE')}
                                                >
                                                    (C.E) Cédula de Extranjería
                                                </MDBBtn>
                                                <MDBBtn
                                                    type='button'
                                                    className='col-12 mb-1'
                                                    style={getButtonStyle('TI')}
                                                    onClick={() => handleCheckboxChange('TI')}
                                                >
                                                    (T.I) Tarjeta de Identidad
                                                </MDBBtn>
                                                <MDBBtn
                                                    type='button'
                                                    className='col-12 mb-1'
                                                    style={getButtonStyle('PA')}
                                                    onClick={() => handleCheckboxChange('PA')}
                                                >
                                                    (P.A) Pasaporte
                                                </MDBBtn>
                                            </div>

                                            {/*

                                            <FormGroup className="form-outline mb-4">
                                                <Input type="select" name="documentType" id="documentType" value={loginValues.documentType} onChange={handleInputChange}>

                                                    <option value="CC">(C.C) Cédula de Ciudadania</option>
                                                    <option value="CE">(C.E) Cédula de Extranjería</option>
                                                    <option value="TI">(T.I) Tarjeta de Identidad</option>
                                                    <option value="PA">(P.A) Pasaporte</option>

                                                </Input>
                                                <Label htmlFor="documentNumber" className="form-label">Tipo de Identificación</Label>
                                            </FormGroup>
                                           */}

                                            <MDBInput
                                                label='Contraseña'
                                                type="password"
                                                name="password"
                                                id="password"
                                                className="mt-3"
                                                placeholder="Contraseña"
                                                onChange={handleInputChange}
                                                value={loginValues.password}
                                                size='lg'
                                            />

                                            <div className="text-center pt-1 mb-5 pb-1">
                                                <Button type="submit" className=" mt-3 btn btn-primary btn-block fa-lg gradient-custom-2 mb-3 col-12" disabled={isLoading}><b>{!isLoading ? "Iniciar sesión" : "Espere un momento..."}</b></Button>
                                            </div>

                                        </form>
                                    </CardBody>
                                </Col>
                                <Col lg={6} className="d-flex justify-content-center align-items-center gradient-custom-2 col-12 text-center">

                                    <Row className='col-12'>

                                        <Col className='col-12'>
                                            <div className="text-center mt-3">
                                                <img src={neuron} style={{ width: '250px' }} alt="nbp-logo" />
                                            </div>
                                        </Col>
                                        <Col className='col-12'>
                                            <label className='auth-title col-12 mt-2'>SISTEMA DE GESTIÓN {process.env.REACT_APP_OWNER}</label>
                                        </Col>
                                        <Col className='col-12 mb-4 auth-slogan'>
                                            <h2>Si lo decimos lo hacemos</h2>
                                        </Col>
                                        <Col className='col-12'>
                                            <p className='col-12 auth-paragraph'>Comprometidos con el desarrollo integral de las personas</p>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Login;