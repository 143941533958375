import React, { useRef } from 'react';
import { Alert, Button } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';
import Swal from 'sweetalert2';

interface SignatureCanvasProps {
    signature: string
    setSignature: (signature: string) => void
}

const SignatureInput: React.FC<SignatureCanvasProps> = ({
    signature,
    setSignature }) => {


    const canvasRef = useRef<SignatureCanvas>(null);

    const handleSave = () => {

        if (canvasRef.current?.isEmpty()) {
            Swal.fire({
                icon: 'error',
                title: 'Ingrese su firma para poder guardarla',
            })
            return
        }

        if (canvasRef.current) {
            const dataURL = canvasRef.current.toDataURL();
            setSignature(dataURL)
        }
    };

    const handleClear = () => {
        if (canvasRef.current) {
            canvasRef.current.clear();
            setSignature("")
        }
    };

    return (

        <div >
            <div style={{ pointerEvents: (signature) ? "none" : "auto", }}>
                {(signature) ? <Alert className='text-center' color="success">
                    <b>Firma registrada</b>
                </Alert> : <Alert className='text-center' color="warning">
                    <b>Firma no registrada</b>
                </Alert>}
                <div style={{ backgroundColor: "white" }} className='mb-2 rounded'>
                    <SignatureCanvas

                        ref={canvasRef}
                        penColor="black"
                        canvasProps={{ className: "signatureCanvas" }}
                    />
                </div>
            </div>
            {!signature ? <Button onClick={handleSave} className="col-12 mb-1" color="success">Guardar</Button> : null}


            <Button onClick={handleClear} className="col-12" color="danger">Restablecer</Button>
        </div>

    );
};

export default SignatureInput;
