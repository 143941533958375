import React, { useState } from 'react'
import { Leads } from '../../../../../../interface/interface'
import { Col, Input, Label, Row } from 'reactstrap';

interface Props {
    index: number,
    leads: Leads,
    checkedList: Array<number>,
    setCheckedList: (checkedList: Array<number>) => void
}

const AssignmentLeadsRowTable: React.FC<Props> = ({ index, leads, checkedList, setCheckedList }) => {

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {


        if (!isChecked) {

            let checkedTemporalList: Array<number> = checkedList;
            checkedTemporalList.push(leads.id as number);
            setCheckedList(checkedTemporalList);

        } else {
            setCheckedList(checkedList.filter(checked => checked !== leads.id))
        }

        setIsChecked(!isChecked);
    };

    return (
        <tr key={index}>
            <td>
                <p><b>{index + 1}</b></p>
            </td>
            <td>
                <p>{leads.first_name + " " + leads.second_name + " " + leads.last_name + " " + leads.second_last_name}</p>
            </td>
            <td>
                <p>{leads.phone_number}</p>
            </td>
            <td>
                <p>{leads.email}</p>
            </td>
            <td>
                <p>{leads.origin}</p>
            </td>
            <td>
                <p>{leads.observation}</p>
            </td>

            <td >

                <Row>
                    <Col className='col-9'>
                        <Label className="custom-checkbox mb-0">
                            <Input type="checkbox" onChange={handleCheckboxChange} className="custom-input" checked={!!checkedList.find(id => id===leads.id)} />
                            <span className="checkmark"></span>.
                        </Label>
                    </Col>
                </Row>

            </td>
        </tr>
    )
}

export default AssignmentLeadsRowTable