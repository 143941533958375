import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import PBOPill from '../../pbo-custom-components/PBOPill'
import { Prospect, ProspectReport, User } from '../../../../interface/interface'
import Swal from 'sweetalert2'
import { genericErrorAlert, genericSuccessAlert } from '../../../../services/alertService'
import { updateProspectStatusAdmin, updateProspectStatusComercial } from '../../../../controllers/ProspectController'
import PBOInputStatus from '../../pbo-form/pbo-form-inputs/PBOInputStatus'

interface Props {

    index: number,
    prospect: ProspectReport,
    user: User,
    toggleModal(prospectId: number | string): void,
    activateCurrentObservationListModal?(prospectId: number|string):void
    updateVisualProspectInformation(prospect: Prospect): void
    isAdmin?: boolean

}

const NBPProspectRow: React.FC<Props> = ({ index, prospect, user, toggleModal, updateVisualProspectInformation, activateCurrentObservationListModal = () => { console.log("Teting")}, isAdmin = false }) => {

    const [editMode, setEditMode] = useState<boolean>(false);
    const [prospectEdit, setProspectEdit] = useState(prospect);
    const [isSaving, setIsSaving] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setProspectEdit({
            ...prospectEdit, [name]: value
        });
    };

    useEffect(() => {

        setProspectEdit(prospect)
        setEditMode(false)

    }, [prospect])

    const handleSubmit = async () => {

        Swal.fire({
            title: 'Confirmación de modificación',
            text: "¿Estas seguro de modificar el prospecto actual?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Modificar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {

            if (prospectEdit.status.trim() === "") {
                genericErrorAlert("Campo Incompleto", "Porfavor selecciona un tipo de estado.")
                return
            }


            if (result.isConfirmed) {
                try {
                    setIsSaving(true);

                    if (isAdmin) {
                        await updateProspectStatusAdmin(prospect, prospectEdit.status);

                    } else {
                        await updateProspectStatusComercial(prospect, prospectEdit.status, user.document_type, user.document_number);

                    }

                    genericSuccessAlert("Modificación exitosa", "Se han guardado los cambios")
                    updateVisualProspectInformation(prospectEdit);
                } catch (error: any) {
                    const { title, message } = error.response.data;
                    genericErrorAlert(title, message);
                } finally {
                    setEditMode(!editMode)
                    setIsSaving(false);
                }
            }
        })


    }

    const handleCancel = () => {


        Swal.fire({
            title: 'Confirmación de cancelación',
            text: "¿Estas seguro de cancelar la edición actual?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                setEditMode(!editMode);
                setProspectEdit(prospect);
                genericSuccessAlert("Edición cancelada", "La edición fue cancelada exitosamente");
            }
        })

    }

    return (
        <tr>
            <td className='text-center align-middle'><b>{(index + 1)}</b></td>
            <td className='text-center align-middle'>{prospect.origin}</td>
            <td style={{ width: '145px' }} className='text-center align-middle'>
                {prospect.first_name + " " + prospect.second_name + " " + prospect.last_name + " " + prospect.second_last_name}
            </td>
            <td style={{ width: '145px' }} className='text-center align-middle'>
                {prospect.teacher_first_name + " " + prospect.teacher_second_name + " " + prospect.teacher_last_name + " " + prospect.teacher_second_last_name}
            </td>
            <td className='text-center align-middle'>{prospect.phone_number}</td>
            <td className='text-center align-middle'>{prospect.email}</td>
            <td className='text-center align-middle'>{prospect.profession}</td>
            <td className='text-center align-middle'>{prospect.decision}</td>
            <td style={{ width: '100px' }} className='text-center align-middle'>
                {prospect.english_level}
            </td>

            <td style={{ width: '100px' }} className='text-center align-middle'>
                {prospect.civil_status}
            </td>
            <td className='text-center align-middle'>
                <div className='mx-auto' style={{ minWidth: '180px', maxWidth: '180px' }}>

                    {editMode ? (<PBOInputStatus name='status' onChange={handleInputChange} value={prospectEdit.status} disabled={isSaving} editMode></PBOInputStatus>) : (<Button className='mx-2 col-10' color={prospect.status === 'Agendado' ? ("primary") : ("dark")}  ><i className="bi bi-calendar-check-fill mx-1"></i><b>{prospect.status}</b></Button>)}
                </div>            </td>
            <td className='text-center align-middle'>

                <Button onClick={() => toggleModal(prospect.id as number)}><i className="bi bi-search"></i></Button>
            </td>
            <td className='text-center align-middle'>

                <Button color='primary' className='mx-1' title='Ver observaciones' onClick={() => activateCurrentObservationListModal(prospect.id + " ")} >
                    <i className="bi bi-three-dots"></i>
                </Button>

            </td>

            <td className='text-center align-middle'>
                <div className='mx-auto' style={{ minWidth: '180px', maxWidth: '180px' }}>
                    <PBOPill skill='Escribir' status={prospect.writing} />
                    <PBOPill skill='Leer' status={prospect.reading} />
                    <PBOPill skill='Hablar' status={prospect.speaking} />
                    <PBOPill skill='Escuchar' status={prospect.listening} />
                </div>
            </td>
            <td className='text-center align-middle'>
                <div className='mx-auto' style={{ minWidth: '100px', maxWidth: '100px' }}>
                    {
                        !editMode ? (<div>
                            <Button color='warning' className='mx-1' title='Editar prospecto' onClick={() => setEditMode(!editMode)}>
                                <i className="bi bi-pencil-fill"></i>
                            </Button>

                        </div>) : (<div>

                            <Button color='primary' className='mx-1' title='Guarar' onClick={() => handleSubmit()} disabled={isSaving}>
                                <i className="bi bi-archive-fill"></i>
                            </Button>
                            <Button color='danger' className='' title='Cancelar Edición' onClick={() => handleCancel()} disabled={isSaving}>
                                <i className="bi bi-x-circle"></i>
                            </Button>
                        </div>)
                    }
                </div>

            </td>
        </tr>
    )
}

export default NBPProspectRow