export function dateformatISO(inputDate: string) {

    const dateObject = new Date(inputDate);

    const year = dateObject.getUTCFullYear();
    const month = `0${dateObject.getUTCMonth() + 1}`.slice(-2);
    const day = `0${dateObject.getUTCDate()}`.slice(-2);

    return `${year}-${month}-${day}`;
}

export function dateFormatDDMMYYY(inputDate: string) {

    const dateObject = new Date(inputDate);

    const year = dateObject.getUTCFullYear();
    const month = `0${dateObject.getUTCMonth() + 1}`.slice(-2);
    const day = `0${dateObject.getUTCDate()}`.slice(-2);

    return `${month}-${day}-${year}`;
}

export function isNextDay(dateToCompare: Date): boolean {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate.toDateString() === dateToCompare.toDateString();
}

export function isToday(dateToCompare: Date): boolean {
    let currentDate = new Date();
    return currentDate.toDateString() === dateToCompare.toDateString();
}

export function dateformatISOFromDate(date: Date) {

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}