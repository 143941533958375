import React, { useState } from 'react'
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import VerificationFormChecked from './VerificationFormChecked';

interface Props {
  type: "email" | "text" | "number" | "textarea" | "date",
  value: string | number;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  readOnly?: boolean;
  formText: string;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const VerificationFormGroupText: React.FC<Props> = ({ type, name, value, onChange, required = false, readOnly = false, formText, md = 12, lg = 12 }) => {

  const [disabled, setDisabled] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setDisabled(!disabled);
  };

  return (
    <Col md={md} lg={lg}>
      <FormGroup className="mb-4">
        <Row>
          <Col xs={9} sm={10} md={11}>
            <Input
              type={type}
              name={name}
              value={value}
              onChange={onChange}  // Provide your onChange logic here
              required={required}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Col>
          <VerificationFormChecked disabled={disabled} handleCheckboxChange={handleCheckboxChange} />
        </Row>

        <FormText>
          {formText} {required ? (" (*)") : ("")}
        </FormText>
      </FormGroup>
    </Col>
  )
}

export default VerificationFormGroupText
