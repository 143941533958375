import React from 'react'
import { DailyAttendance, User } from '../../../interface/interface';
import { Card, CardHeader, CardBody, Row, Col, Alert, Table } from 'reactstrap';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import CheckAttendance from '../attendance-components/CheckAttendanceButton';
import LoadAttendanceCodeButton from '../attendance-components/LoadAttendanceCodeButton';

interface Props {
    title: string
    currentUser: User;
    classAttendace: Array<DailyAttendance>
    setClassAttendace: (classAttendac: Array<DailyAttendance>) => void
}

const AttendanceReport: React.FC<Props> = ({ title, currentUser, classAttendace, setClassAttendace }) => {

    return (
        <Card className={CARD_BODY + "my-2 nbp-background-logo "}>
            <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
                <b className='p-0 m-0'>{title}</b>
            </CardHeader>

            <Row>
                <Col>
                    {
                        classAttendace.length === 0 ? (

                            <Alert color="warning" className="text-center m-3">
                                <b className="h2">No se encontraron clases para el día seleccionado</b>
                            </Alert>
                        ) :

                            <CardBody className="m-3">

                                <Card className='py-3'>

                                    <Row>
                                        <Col>
                                            <Table striped responsive className='text-center' hover>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>#</th>
                                                        <th className='text-center'>Clase N°</th>
                                                        <th className='text-center'>Hora</th>
                                                        <th className='text-center'>Modalidad</th>
                                                        <th className='text-center'>Curso</th>
                                                        <th className='text-center'>Profesor</th>
                                                        <th className='text-center'>Estado</th>
                                                        <th className='text-center'>Opciones</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {classAttendace?.map((attendace, index) =>
                                                    (<tr key={index}>
                                                        <td className='center-text-table'>
                                                            <p><b>{index + 1}</b></p>
                                                        </td>
                                                        <td className='center-text-table'>

                                                            <p>{attendace.class_id}</p>
                                                        </td>
                                                        <td className='center-text-table'>
                                                            <p>{attendace.hour}</p>
                                                        </td>
                                                        <td className='center-text-table'>
                                                            <p>{attendace.modality}</p>
                                                        </td>
                                                        <td className='center-text-table'>
                                                            <p>{attendace.classroom_name}</p>
                                                        </td>
                                                        <td className='center-text-table'>
                                                            <p>{attendace.teacher_first_name + " " + attendace.teacher_last_name}</p>
                                                        </td>
                                                        <td className='center-text-table'>
                                                            <div className='mx-auto' style={{ minWidth: '180px', maxWidth: '180px' }}>
                                                                {attendace.attendance_check === null ? (<span className={`pill col-6 my-1 red`}>No registro</span>) : (<span className={`pill col-6 my-1 ${attendace.attendance_check ? 'green' : 'red'}`}>{attendace.attendance_check ? 'Realizada' : 'Sin realizar'}</span>)}
                                                            </div>
                                                        </td>
                                                        <td className='center-text-table'>
                                                            {/* Mostrar opciones dependiendo del rango de la persona (Que sea diferente a docente) */}
                                                            <div className='mx-auto' style={{ minWidth: '180px', maxWidth: '180px' }}>
                                                                {currentUser && currentUser.role_id !== 4 ? (
                                                                    <LoadAttendanceCodeButton classId={attendace.class_id} />
                                                                ) : (<div> {attendace.attendance_check === 1 ? ("Sin opciones") : ((<CheckAttendance classId={attendace.class_id} classAttendace={classAttendace} setClassAttendace={setClassAttendace} />))}</div>)}
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                    )}
                                                </tbody>

                                            </Table>
                                        </Col>
                                    </Row>
                                </Card>
                            </CardBody>
                    }
                </Col>
            </Row>

        </Card>
    )
}


export default AttendanceReport
