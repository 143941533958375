import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Row, Col, Alert, FormGroup, FormText, Input, Button } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../../services/classService'
import { Leads, User } from '../../../../../interface/interface'
import { genericErrorAlert } from '../../../../../services/alertService'
import { getCommercialUser } from '../../../../../controllers/LeadsController'
import { assignmentLeads } from '../../../../../controllers/LeadsAssignmentController'
import AssignmentLeadsTableReport from './leads-assignment-table/AssignmentLeadsTableReport'

interface Props {
    isLoading: boolean,
    leadsList: Array<Leads>,
    loadUnssignmentLeads: () => Promise<void>;
}

const LeadsAssignmentReport: React.FC<Props> = ({ isLoading, leadsList, loadUnssignmentLeads }) => {

    const [leads, setLeads] = useState({ selectedCommercial: "" });
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [commercialList, setCommercialList] = useState<Array<User>>([]);
    const [checkedList, setCheckedList] = useState<Array<number>>([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLeads({
            ...leads, [name]: value
        });
    };

    useEffect(() => {


        loadCommercial();

    }, [])

    const loadCommercial = async () => {

        try {

            const userList = await getCommercialUser();
            setCommercialList(userList.data)

        } catch (error) {
            genericErrorAlert("Error al cargar comerciales", "Recargue la pagina nuevamente")
        }

    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        if (checkedList.length === 0) genericErrorAlert("Error al asignar leads", "Seleccione un leads para asignar");

        try {

            setIsSaving(true);
            const user = leads.selectedCommercial.split(",");
            await assignmentLeads(checkedList, user[0], user[1]);
            await loadUnssignmentLeads();

        } catch (error) {

            console.log(error + "")
            genericErrorAlert("Error al asignar Leads", "Intentelo nuevamente");

        } finally {

            setIsSaving(false);
        }

    }

    return (
        <Card className={CARD_BODY + 'm-2 contract-backgroud'}>

            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>LEADS POR ASIGNAR</b>
            </CardHeader>

            <CardBody>

                {isLoading ? (<Alert color="warning" className="text-center m-3">
                    <b className="h2">Cargando listado de leads por asignar...</b>
                </Alert>) : (<div>{leadsList.length === 0 ? (<Alert color="warning" className="text-center m-3">
                    <b className="h2">No se encontro registro de ningun leads por asignar</b>
                </Alert>) :

                    (<form onSubmit={handleSubmit}>

                        <Row >
                            <Col>
                                <FormGroup className="form-outline">
                                    <Input type="select" name="selectedCommercial" value={leads.selectedCommercial} onChange={handleInputChange} required>

                                        <option value=''>{commercialList.length !== 0 ? ("Seleccione un comercial") : ("No hay comerciales registrados")}</option>

                                        {commercialList.map((user, index) =>
                                            <option key={index} value={user.document_type + "," + user.document_number}>{"(" + user.document_type + " - " + user.document_number + " ) - " + user.first_name + " " + user.second_name + " " + user.last_name + " " + user.second_last_name}
                                            </option>)}

                                    </Input>
                                    <FormText>
                                        Comercial(*)
                                    </FormText>
                                </FormGroup></Col>

                        </Row>

                        <Row>
                            <Col>
                                <Button type='submit' color='warning' className='col-12 text-center my-3' disabled={isSaving}><b>{!isSaving ? "Asignar comercial" : "Asignando Comercial..."}</b></Button>
                            </Col>
                        </Row>

                        <Card>

                            <Row>
                                <Col>
                                    <AssignmentLeadsTableReport leadsList={leadsList} checkedList={checkedList} setCheckedList={setCheckedList} />
                                </Col>
                            </Row>

                        </Card>

                    </form>)}
                </div>)}

            </CardBody>

        </Card>
    )
}

export default LeadsAssignmentReport