import React, { useState } from 'react'
import { Leads } from '../../../../../../interface/interface'
import { Col, Input, Label, Row } from 'reactstrap';

interface Props {
    index: number,
    leads: Leads,
}

const NormalLeadsRowTable: React.FC<Props> = ({ index, leads}) => {

    return (
        <tr key={index}>
            <td>
                <p><b>{index + 1}</b></p>
            </td>
            <td>
                <p>{leads.first_name + " " + leads.second_name + " " + leads.last_name + " " + leads.second_last_name}</p>
            </td>
            <td>
                <p>{leads.phone_number}</p>
            </td>
            <td>
                <p>{leads.email}</p>
            </td>
            <td>
                <p>{leads.origin}</p>
            </td>
            <td>
                <p>{leads.observation}</p>
            </td>

        </tr>
    )
}

export default NormalLeadsRowTable