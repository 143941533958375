
import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';
import { Beneficiary, Contract, Holder, HolderWork, NotePayable, PaymentModality, PaymentSupport, Reference, User, VerificationReport } from '../interface/interface';

export const verifyContract = async (

    user: User,
    holder: Holder,
    holderWork: HolderWork,
    contract: Contract,
    beneficiaryAmount: number,
    beneficiaries: Array<Beneficiary>,
    referenceOne: Reference,
    referenceTwo: Reference,
    paymentModality: PaymentModality,
    paymentSupport: PaymentSupport,
    notePayable: NotePayable,
    verificationReport: VerificationReport) => {

    console.log(verificationReport)

    return await axiosInstance.post(mainRoute + 'verifyAssigment/verifyContract', { user, holder, holderWork, contract, beneficiaryAmount, beneficiaries, referenceOne, referenceTwo, paymentModality, paymentSupport, notePayable, verificationReport })

}

export const getAllCheckerUser = async () => {
    return await axiosInstance.get(mainRoute + 'verifyAssigment/getCheckerUser');
}

export const verificationStatusInit = async (contract_id: string, checker_document_type: string, checker_document_number: string) => {
    return await axiosInstance.post(mainRoute + 'verifyAssigment/verificationStatusInit', { contract_id: contract_id, checker_document_type: checker_document_type, checker_document_number: checker_document_number });
}

export const getAllVerifyAssigments = async () => {
    return await axiosInstance.get(mainRoute + 'verifyAssigment/getAllVerifyAssigments');
}

export const getAllPersonalVerifyAssigments = async () => {
    return await axiosInstance.get(mainRoute + 'verifyAssigment/getAllPersonalVerifyAssigments');
}