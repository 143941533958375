import { MDBNavbarNav, MDBNavbarItem, MDBNavbarLink } from 'mdb-react-ui-kit'
import React from 'react'
import navOptions, { navIcons } from '../narvbar-options/NavbarOptions'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'


interface Props {
    handleLogout: () => void
}

const TeacherNavbar: React.FC<Props> = ({ handleLogout }) => {
    return (
        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 fw-bold'>

            <NavItem>
                <NavLink tag={Link} to="/home" style={{ textDecoration: 'none', color: 'black' }}>
                    <i className={navIcons.home}></i>{navOptions.home}
                </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.schedule}></i> {navOptions.schedule}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/schedule/mine" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.mySchedule}></i> {navOptions.mySchedule}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />


                    <DropdownItem >
                        <Link to="/schedule/dailyReport" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.scheduleReport}></i> {navOptions.scheduleReport}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.attendance}></i> {navOptions.attendance}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/attendance/manager" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.attendanceReport}></i> {navOptions.attendanceReport}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <MDBNavbarLink className='d-flex justify-content-between' onClick={handleLogout} style={{ cursor: 'pointer' }}><div><b>{navOptions.logout} <i className={navIcons.logout}></i> </b></div></MDBNavbarLink>

        </MDBNavbarNav>
    )
}

export default TeacherNavbar