import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap'
import { City, NotePayable, PaymentModality } from '../../../interface/interface';
import { getAllActiveCities } from '../../../controllers/CitiesController';
import SignatureInput from '../contract-inputs/SignatureInput';
import { NumericFormat } from 'react-number-format';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import { genericErrorAlert } from '../../../services/alertService';

interface Props {
    notePayable: NotePayable,
    setNotePayable: (notePayable: NotePayable) => void
    paymentModality: PaymentModality
}



const NotePayableForm: React.FC<Props> = ({ notePayable, setNotePayable, paymentModality }) => {

    const [currentDate, setCurrentDate] = useState('');
    const [cities, setCities] = useState<Array<City> | null>();
    const [selectedCity, setSelectedCity] = useState("0");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNotePayable({
            ...notePayable, [name]: value
        });
    };

    useEffect(() => {
        setCurrentDateValue();
        loadAllCities();
    }, []);


    const getCurrentCityValues = (cityId: string) => {

        const currentCity = cities?.filter(city => city.id?.toString() === cityId);
        return currentCity;
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;
        const values: any = getCurrentCityValues(value);
        setSelectedCity(values[0].id)
        setNotePayable({ ...notePayable, city_id: parseInt(values[0].id) })

    };

    const handleUserSignature = (signature: string) => {

        setNotePayable({
            ...notePayable, holder_signature: signature
        });

    }



    const loadAllCities = async () => {

        try {
            const cityList: any = await getAllActiveCities()
            setCities(cityList.data.cities)
            setSelectedCity(cityList.data.cities[0].id)
            setNotePayable({ ...notePayable, city_id: parseInt(cityList.data.cities[0].id) })
        } catch (error) {
            genericErrorAlert("Error al cargar las ciudades", "Intentelo nuevamente");
        }
    }

    const setCurrentDateValue = (): void => {
        const date = new Date();
        const stringDate = date.toISOString().split('T')[0];
        setCurrentDate(stringDate);
    };

    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>Pagaré</b>
            </CardHeader>
            <CardBody>

                <Row className='text-white'>
                    <p className='contract-details'>
                        EL (LOS) SUSCRIPTOR(ES) IDENTIFICADO(S) COMO APARECE(N) AL PIE DE LA(S) FIRMA(S) DE ESTE PAGARÉ, SE OBLIGA(N)
                        MEDIANTE EL TÍTULO VALOR A PAGAR A FAVOR DE <b>CEA S.A.S</b> EN LA CIUDAD DE

                        <Input className='note-payable-input p-0 ' type="select" name="planType" id="planType" value={selectedCity} onChange={handleSelectChange}>
                            {cities?.map((city, index) =>
                                <option key={index} value={city.id}>{city.name}</option>
                            )}
                        </Input>
                        EN

                        <input
                            className="note-payable-input"
                            type="text"
                            name="installments_number"
                            value={paymentModality.installments_number}
                            readOnly
                            required
                        />

                        CUOTAS MENSUALES Y SUCESIVAS DE


                        <NumericFormat className='note-payable-input'
                            name='monthly_amount'
                            type='text'
                            thousandSeparator="."
                            decimalSeparator=','
                            value={paymentModality.installments_value + ",00"}
                            allowNegative={false}
                            prefix='$ '
                            readOnly />

                        EXIGIBLES A PARTIR DEL DIA

                        <input
                            className="note-payable-input"
                            type="date"
                            name="current_date"
                            value={currentDate}
                            readOnly
                            required
                        />

                        LA CANTIDAD TOTAL DE

                        <NumericFormat className='note-payable-input'
                            name="totalAmount"
                            type='text'
                            thousandSeparator="."
                            decimalSeparator=','
                            decimalScale={2}
                            value={paymentModality.total + ",00"}
                            allowNegative={false}
                            prefix='$ '
                            readOnly
                            required />

                        . EXCUSADO EL PRETEXTO Y PRESENTACIÓN PARA EL PAGO. LA MORA EN LA CANCELACIÓN DE TRES CUOTAS HARÁ EXIGIBLE Y VENCIDO EL SALDO DE LA DEUDA, LA CUAL CAUSARÁ INTERESES LEGALES, COSTOS Y PAGOS DE COBRANZA.
                    </p>
                </Row>

                <Row className='d-flex d-flex justify-content-center align-items-center'>
                    <Col className='col-12 text-center'>
                        <Label tag="h5" className='text-white'><b>FIRMA DEL TITULAR</b></Label>
                    </Col>
                    <Col sm={12} md={6}>
                        <SignatureInput signature={notePayable.holder_signature} setSignature={handleUserSignature}></SignatureInput>
                    </Col>
                </Row>
            </CardBody>
        </Card >
    )
}

export default NotePayableForm