import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap'
import { PaymentSupport } from '../../../interface/interface';
import { getTranscriptionNumbers } from '../../../utils/numberUtils';
import SignatureInput from '../contract-inputs/SignatureInput';
import { NumericFormat } from 'react-number-format';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';

interface Props {
    paymentSupport: PaymentSupport
    setPaymentSupport: (paymentSupport: PaymentSupport) => void
    userFullName: string
    amount: number
}


const PaymentSupportForm: React.FC<Props> = ({ paymentSupport, setPaymentSupport, userFullName, amount }) => {

    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        setCurrentDateValue();
    }, []);

    const setCurrentDateValue = (): void => {
        const date = new Date();
        const stringDate = date.toISOString().split('T')[0];
        setCurrentDate(stringDate);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPaymentSupport({
            ...paymentSupport, [name]: value
        });
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentDate(event.target.value);
    };

    const handleUserSignature = (signature: string) => {

        setPaymentSupport({
            ...paymentSupport, responsible_user_signature: signature
        });

    }

    const handleInputSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (value === "Efectivo" || value === "Transferencia") {
            setPaymentSupport({
                ...paymentSupport, [name]: value, card_type: ""
            });
        } else {

            setPaymentSupport({
                ...paymentSupport, [name]: value, card_type: "Visa"
            });
        }
    };

    const generatePaymentMethodIput = () => {

        if (paymentSupport.payment_method !== "Efectivo" && paymentSupport.payment_method !== "Transferencia") {

            return <FormGroup>
                <Input type="select" name="card_type" id="card_type" value={paymentSupport.card_type} onChange={handleInputChange}>
                    <option value="Visa">Visa</option>
                    <option value="Mastercard">Mastercard</option>
                    <option value="American Express">American Express</option>
                </Input>
                <FormText>
                    Tipo de tarjeta
                </FormText>
            </FormGroup>
        }
    }

    return (
        <Card className= {CARD_BODY+'m-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>SOPORTE DE PAGO</b>
            </CardHeader>
            <CardBody>

                <Row>

                    <Col sm={6} md={6} lg={6}>
                        <FormGroup>

                            <Input type="text" name="user_full_name"
                                value={userFullName}
                                readOnly>
                            </Input>

                            <FormText>
                                Pagado a
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col sm={6} md={6} lg={6}>
                        <FormGroup>

                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='amount'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={amount + ",00"}
                                    allowNegative={false}
                                    readOnly />
                            </InputGroup>

                            <FormText>
                                Monto
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col sm={12}>
                        <FormGroup>

                            <Input type="text" name="text_amount"
                                value={getTranscriptionNumbers(amount)}
                                readOnly>
                            </Input>

                            <FormText>
                                Monto en texto
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>

                            <Input type="text" name="description"
                                value={paymentSupport.description}
                                onChange={handleInputChange}
                                required
                            >
                            </Input>

                            <FormText>
                                Por concepto de
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                        <FormGroup>

                            <Input type="date" name="current_date"
                                value={currentDate}
                                readOnly>
                            </Input>

                            <FormText>
                                Fecha Actual
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                        <FormGroup>
                            <Input type="select" name="payment_method" id="payment_method" value={paymentSupport.payment_method} onChange={handleInputSelect}>
                                <option value="Efectivo">Efectivo</option>
                                <option value="Transferencia">Transferencia</option>
                                <option value="Tarjeta de credito">Tarjeta de credito</option>
                                <option value="Tarjeta de debito">Tarjeta de debito</option>
                            </Input>
                            <FormText>
                                Tipo de pago
                            </FormText>
                        </FormGroup>
                    </Col>


                    <Col md={12} lg={6}>
                        {generatePaymentMethodIput()}
                    </Col>


                </Row>
                <Row className='d-flex d-flex justify-content-center align-items-center'>
                    <Label tag="h5" className='text-center text-white'><b>FIRMA DEL EJECUTIVO CEA S.A.S</b></Label>
                    <Col sm={12} md={6}>
                        <SignatureInput signature={paymentSupport.responsible_user_signature} setSignature={handleUserSignature}></SignatureInput>
                    </Col>
                </Row>

            </CardBody>
        </Card>
    )
}

export default PaymentSupportForm