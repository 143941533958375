import Swal from "sweetalert2";

export const sessionEndedAlert = (test: string) => {
    Swal.fire({
        icon: 'info',
        title: 'Sesión no valida...' + test,
        text: 'Iniciar sesión para continuar...',
    })
}

export const holderNotFoundAlert = () => {
    Swal.fire({
        icon: 'error',
        title: 'Titular no encontrado',
        text: 'La identificación suministrada no se encuentra en el sistema.',
    })
}

export const holderLoadedSuccessAlert = () => {
    Swal.fire({
        icon: 'success',
        title: 'Titular encontrado',
        text: 'La información del titular fue cargada con exito.',
    })
}

export const completeAllInputsAlert = () => {
    Swal.fire({
        icon: 'error',
        title: 'Información Incompleta',
        text: 'Completa todos los campos para realizar la consulta.',
    })
}


export const beneficiaryClassErrorAlert = (title: string, message: string) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: message,
    })
}

export const beneficiaryClassSuccessAlert = (title: string, message: string) => {
    Swal.fire({
        icon: 'success',
        title: title,
        text: message,
    })
}

export const beneficiaryNotFountAlert = (title: string, message: string) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: message,
    })
}

export const internalServerErrorAlert = (title: string, message: string) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: message,
    })
}

export const genericErrorAlert = (title: string, message: string) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: message,
    })
}

export const genericSuccessAlert = (title: string, message: string) => {
    Swal.fire({
        icon: 'success',
        title: title,
        text: message,
    })
}


/*

  Gestiona los errores personalidados dados por el backend

*/

export const handleErrorResponseAlert = (error: any, defaultTitle: string, defaultMessage: string) => {

    let titleAlert = defaultTitle;
    let messageAlert = defaultMessage;

    if (error.response && error.response.data) {
        const { title, message } = error.response.data;
        if (title && message) {
            titleAlert = title;
            messageAlert = message;
        }
    }

    genericErrorAlert(titleAlert, messageAlert);

}