import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Row, Col, Alert, Label } from 'reactstrap'
import { NotePayable, PaymentModality } from '../../../interface/interface'
import { cityNameById } from '../../../controllers/CitiesController'
import { NumericFormat } from 'react-number-format'
import { CARD_BODY } from '../../../services/classService'
import { dateFormatDDMMYYY } from '../../../utils/dateUtils'
import { genericErrorAlert } from '../../../services/alertService'

interface Props {
    notePayable: NotePayable
    paymentModality: PaymentModality
}

const NotePayableCard: React.FC<Props> = ({ notePayable, paymentModality }) => {

    const [cityName, setCityName] = useState<string>("");

    useEffect(() => {

        loadCity();

    }, [])

    const loadCity = async () => {

        try {
            const cityValues = await cityNameById(notePayable.city_id as number);
            setCityName(cityValues.data.name[0].name)
        } catch (error: any) {

            const { title, message } = error.response.data;

            genericErrorAlert(title, message)

        }
    }

    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            
            <CardHeader tag="h4" className='bg-nbp-orange' >
                <b>PAGARÉ</b>
            </CardHeader>

            <CardBody>

                <Row>
                    <p className='contract-details text-white'>
                        EL (LOS) SUSCRIPTOR(ES) IDENTIFICADO(S) COMO APARECE(N) AL PIE DE LA(S) FIRMA(S) DE ESTE PAGARÉ, SE OBLIGA(N)
                        MEDIANTE EL TÍTULO VALOR A PAGAR A FAVOR DE <b>CEA S.A.S</b> EN LA CIUDAD DE

                        <input
                            className="note-payable-input"
                            type="text"
                            name="city"
                            value={cityName}
                            readOnly
                        />

                        EN

                        <input
                            className="note-payable-input"
                            type="text"
                            name="installments_number"
                            value={paymentModality.installments_number}
                            readOnly
                        />


                        CUOTAS MENSUALES Y SUCESIVAS DE

                        <NumericFormat className='note-payable-input'
                            name='monthly_amount'
                            type='text'
                            thousandSeparator="."
                            decimalSeparator=','
                            decimalScale={2}
                            value={paymentModality.installments_value + ",00"}
                            allowNegative={false}
                            prefix='$ '
                            readOnly />

                        EXIGIBLES A PARTIR DEL DIA

                        <input
                            className="note-payable-input"
                            type="text"
                            name="current_date"
                            value={dateFormatDDMMYYY(notePayable.created_at as string)}
                            readOnly
                        />

                        LA CANTIDAD TOTAL DE

                        <NumericFormat className='note-payable-input'
                            name="totalAmount"
                            type='text'
                            thousandSeparator="."
                            decimalSeparator=','
                            decimalScale={2}
                            value={paymentModality.total + ",00"}
                            allowNegative={false}
                            prefix='$ '
                            readOnly
                            required />
                        . EXCUSADO EL PRETEXTO Y PRESENTACIÓN PARA EL PAGO. LA MORA EN LA CANCELACIÓN DE TRES CUOTAS HARÁ EXIGIBLE Y VENCIDO EL SALDO DE LA DEUDA, LA CUAL CAUSARÁ INTERESES LEGALES, COSTOS Y PAGOS DE COBRANZA.
                    </p>

                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col className='col-12 text-center'>
                            <Label tag="h5" className='text-white'><b>FIRMA DEL TITULAR</b></Label>
                        </Col>

                        <Col className='col-12 d-flex justify-content-center align-items-center'>
                            <Alert color='warning'><img src={notePayable.holder_signature} className='img-fluid' alt='Firma del usuario responsable' /></Alert>
                        </Col>
                    </Row>
                </Row>
            </CardBody>

        </Card>
    )
}

export default NotePayableCard

