import { useEffect, useState } from 'react'
import { Card, CardHeader, Alert, CardBody, Row, Col, Table } from 'reactstrap';
import { getAllPersonalVerifyAssigments } from '../../../../controllers/VerifyAssigmentController';
import { VerificationAssignment } from '../../../../interface/interface';
import { genericErrorAlert } from '../../../../services/alertService';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import VerificationTableReport from '../verification-report-table/VerificationTableRepor';

const PersonalVerificationReport = () => {
  const [verifyAssigmentList, setVerifyAssigmentList] = useState<Array<VerificationAssignment>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    loadVerifyAssigments();

  }, [])

  const loadVerifyAssigments = async () => {

    try {

      setIsLoading(true);
      const verifyAssigments = await getAllPersonalVerifyAssigments();
      setVerifyAssigmentList(verifyAssigments.data[0]);

    } catch (error) {

      console.log(error);
      genericErrorAlert("Error al cargar información", "No fue posible cargar el historial de verificaciones")

    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Card className={CARD_BODY + "my-2 nbp-background-logo m-2"}>
      <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
        <b className='p-0 m-0'>HISTORIAL DE VERIFICACIONES</b>
      </CardHeader>
      {
        isLoading ? (
          <Alert color="warning" className="text-center m-3">
            <b className="h2">Cargando historial de verificaciones...</b>
          </Alert>) : (<Row>

            {verifyAssigmentList.length === 0 ? (<Col>
              <Alert color="warning" className="text-center m-3">
                <b className="h2">No tiene historial de verificaciones</b>
              </Alert>
            </Col>) :
             (<Col>
              <CardBody className="m-3">
                <Card className='py-3'>
                  <Row>
                    <Col>
                      <VerificationTableReport verifyAssigmentList={verifyAssigmentList} />
                    </Col>
                  </Row>
                </Card>
              </CardBody>
            </Col>)}
          </Row>)
      }
    </Card>
  )
}

export default PersonalVerificationReport