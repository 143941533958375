import React from 'react'
import { Input } from 'reactstrap';

interface Props {
    value: string;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    disabled?: boolean;
}

const PBOInputOrigin: React.FC<Props> = ({ name, value, onChange, required = false, disabled = false }) => {

    return (
        <Input type='select' name={name} value={value} onChange={onChange} required={required} disabled={disabled}>
            <option value="">Seleccione un origen</option>
            <option value="Referido">Referido</option>
            <option value="Redes">Redes</option>
            <option value="Mercado Natural">Mercado Natural</option>
            <option value="Counter">Counter</option>
            <option value="Radio">Radio</option>
            <option value="TV">TV</option>
            <option value="Convenio">Convenio</option>
        </Input>
    )
}

export default PBOInputOrigin
