import axios from "axios";
import { getCookieValue } from "./cookieService";

const isProduction = process.env.NODE_ENV === 'production';

console.log('isProduction', isProduction);
console.log('process.env', process.env);

export const BASE_URL = isProduction 
    ? process.env.REACT_APP_BASE_URL 
    : 'http://localhost:5001/';
 
const DEPLOY_BASE_URL = 'https://tools.nbpteam.com/';

const axiosInstance = axios.create({
    baseURL: "http://localhost:3000/",
});

export const setAuthorizationHeader = (token: string | null) => {
    if (token) {
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common["Authorization"];
    }
};

const cookie = getCookieValue("nbp-management-auth");

if (cookie) {
    const token = cookie.token;
    setAuthorizationHeader(token);
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axiosInstance.interceptors.request.use(
    (config) => {

        const cookie = getCookieValue("nbp-management-auth");

        if (cookie) {
            const token = cookie.token;
            setAuthorizationHeader(token);
            axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
