import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';

export const isActiveBeneficiary = async (document_type: string, document_number: string) => {
    const exist = await axiosInstance.post(mainRoute + 'beneficiary/isActiveBeneficiary', { document_type: document_type, document_number: document_number })
    return exist;
}
export const getBeneficiaryByContractNumber = async (contract_id: string) => {
    const exist = await axiosInstance.post(mainRoute + 'beneficiary/getBeneficiaryByContractNumber', { contract_id: contract_id })
    return exist;
}

export const getBeneficiaryByContractNumberSchedule = async (contract_id: string) => {
    const exist = await axiosInstance.post(mainRoute + 'beneficiary/getBeneficiaryByContractNumberSchedule', { contract_id: contract_id })
    return exist;
}

export const findBeneficiaryByName = async (first_name: string, second_name: string, last_name: string, second_last_name: string) => {
    const exist = await axiosInstance.post(mainRoute + 'beneficiary/findBeneficiaryByName', {first_name: first_name, second_name: second_name, last_name: last_name, second_last_name: last_name })
    return exist;
}

export const getBeneficiaryContactInformation = async (beneficiary_id:string) => {
    const exist = await axiosInstance.get(mainRoute + 'beneficiary/getBeneficiaryContactInformation/'+beneficiary_id)
    return exist;
}

