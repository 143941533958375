import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Row, Col, Alert, Button, Table, Container } from 'reactstrap';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import ContractStatusInfo from '../../../contract/contract-status/ContractStatusInfo';
import { dateformatISO } from '../../../../utils/dateUtils';
import { getUnverifiedContractsAssigned } from '../../../../controllers/ContractController';
import { genericErrorAlert } from '../../../../services/alertService';

interface Props {
  setSelectedContract: (contractId: string) => void;
  setResponsibleName: (userResponsibleName: string) => void
}

const AdminVerificationReports: React.FC<Props> = ({ setSelectedContract, setResponsibleName }) => {

  const [contractList, setContractList] = useState<Array<any>>([]);
  const [loadding, setLoadding] = useState<boolean>(true);

  useEffect(() => {

    loadContractList();

  }, []);

  const handleLoadContractUnverified = (contractId: string, responsibleUser: string) => {

    setSelectedContract(contractId);
    setResponsibleName(responsibleUser);

  }

  const loadContractList = async () => {
    try {

      const contractListData = await getUnverifiedContractsAssigned();
      const contracts = contractListData.data;
      setContractList(contracts);
      setLoadding(false);

    } catch (error) {
      genericErrorAlert("Error al cargar contratos al verificar", "Recarga la pagina");
    }
  };

  return (
    <Card className={CARD_BODY + "my-2 nbp-background-logo m-2"}>

      <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
        <b className='p-0 m-0'>LISTADO POR VERIFICAR</b>
      </CardHeader>
      {
        loadding ? (<Row>
          <Col>
            <Alert color="warning" className="text-center m-3">
              <b className="h2">Cargando contratos...</b>
            </Alert>
          </Col>
        </Row>
        ) : (<Row>
          {contractList.length === 0 ? (<Col>
            <Alert color="warning" className="text-center m-3">
              <b className="h2">No hay contratos por verificar </b>
            </Alert>
          </Col>) : (<CardBody className="m-3">

            <Container>
              <Card className='py-3'>
                <Row>
                  <Col>

                    <Table striped responsive className='text-center' hover>
                      <thead>
                        <tr>
                          <th className='text-center'>#</th>
                          <th className='text-center'>N° de contrato</th>
                          <th className='text-center'>Estado</th>
                          <th className='text-center'>Fecha</th>
                          <th className='text-center'>Identificación (Empleado)</th>
                          <th className='text-center'>Empleado</th>
                          <th className='text-center'>Identificación (Titular)</th>
                          <th className='text-center'>Titular</th>
                          <th className='text-center'>Opciones</th>
                        </tr>
                      </thead>
                      <tbody>

                        {contractList.map((contract, index) =>
                        (<tr key={index}>
                          <td>
                            <p><b>{index + 1}</b></p>
                          </td>
                          <td>
                            <p>{contract.serial}</p>
                          </td>
                          <td>
                            {<ContractStatusInfo contractStatus={contract.status} contractId={contract.id} contractSerial={contract.serial} />}
                          </td>
                          <td>
                            {dateformatISO(contract.created_at)}
                          </td>
                          <td>
                            <p>{contract.user_document_number + "- (" + contract.user_document_type + ")"}</p>
                          </td>
                          <td>
                            <p>
                              {contract.user_first_name + " " + contract.user_second_name + " " + contract.user_last_name + " " + contract.user_second_last_name}
                            </p>
                          </td>
                          <td>
                            <p>{contract.holder_document_number}</p>
                          </td>
                          <td>
                            <p>{contract.holder_first_name + " " + contract.holder_second_name + " " + contract.holder_last_name + " " + contract.holder_second_last_name}</p>
                          </td>
                          <td>
                            <Button color="primary" id={contract.id} onClick={() => handleLoadContractUnverified(contract.id, contract.user_first_name + " " + contract.user_second_name + " " + contract.user_last_name + " " + contract.user_second_last_name)}>
                              <i className="bi bi-clipboard-fill"></i>
                            </Button>
                          </td>

                        </tr>)
                        )}
                      </tbody>
                    </Table>

                  </Col>
                </Row>
              </Card>
            </Container>
          </CardBody>)
          }


        </Row>)
      }

    </Card>
  )
}

export default AdminVerificationReports
