import React from 'react'
import { CardTitle, Col, FormGroup, FormText, Input, Row } from 'reactstrap'
import { User } from '../../../interface/interface';
import { dateformatISO } from '../../../utils/dateUtils';

interface Props {
    user: User;
}

const UserCard: React.FC<Props> = ({ user }) => {

    return (
        <Row>

            <Col lg={12}> {user.is_active ? (<span className={'bg-lime p-2 col-12 custom-pill my-2'}>Activo</span>) : (<span className={'bg-danger p-2 col-12 custom-pill '}>Desactivado</span>)}</Col>

            <Col lg={12}>
                <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                    Nombre Completo
                </CardTitle>
            </Col>

            <Col md={6} lg={6}>

                <FormGroup>
                    <Input name='first_name'
                        value={user.first_name}
                        readOnly />
                    <FormText>
                        Primer Nombre
                    </FormText>
                </FormGroup>
            </Col>

            <Col md={6} lg={6}>
                <FormGroup>
                    <Input type="text" name='second_name'
                        value={user.second_name}
                        readOnly
                    />
                    <FormText>
                        Segundo Nombre
                    </FormText>
                </FormGroup>
            </Col>

            <Col md={6} lg={6}>
                <FormGroup>
                    <Input type="text" name='last_name'
                        value={user.last_name}
                        readOnly
                    />
                    <FormText>
                        Primer Apellido
                    </FormText>
                </FormGroup>
            </Col>

            <Col md={6} lg={6}>
                <FormGroup>
                    <Input type="text" name='second_last_name'
                        value={user.second_last_name}
                        readOnly />
                    <FormText>
                        Segundo Apellido
                    </FormText>
                </FormGroup>
            </Col>

            <Col lg={12}> <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                Información de Identidad
            </CardTitle> </Col>

            <Col md={6} lg={6}>

                <FormGroup className="mb-4">

                    <Input type="text" name='second_last_name'
                        value={user.document_type}
                        readOnly
                    />

                    <FormText>
                        Tipo de documento de identidad
                    </FormText>
                </FormGroup>
            </Col>


            <Col md={6} lg={6}>
                <FormGroup>
                    <Input type="text" name='document_number'
                        value={user.document_number}
                        readOnly
                    />
                    <FormText>
                        Número de documento
                    </FormText>
                </FormGroup>
            </Col>


            <Col md={6} lg={6}>
                <FormGroup>
                    <Input type="date" name='birthdate'
                        value={dateformatISO(user.birthdate as string)}
                        readOnly />
                    <FormText>
                        Fecha de nacimiento
                    </FormText>
                </FormGroup>
            </Col>

            <Col md={6} lg={6}>
                <FormGroup className="mb-4">
                    <Input type="text" name='gender'
                        value={user.gender}
                        readOnly />
                    <FormText>
                        Genero
                    </FormText>
                </FormGroup>
            </Col>

            <Col lg={12}> <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                Información personal
            </CardTitle> </Col>


            <Col md={6} lg={6}>

                <FormGroup>
                    <Input type="text" name='phone_number'
                        value={user.phone_number}
                        readOnly
                    />
                    <FormText>
                        Número de teléfono
                    </FormText>
                </FormGroup>
            </Col>

            <Col md={6} lg={6}>
                <FormGroup>
                    <Input type="email" name='email'
                        value={user.email}
                        readOnly
                    />
                    <FormText>
                        Correo Electronico
                    </FormText>
                </FormGroup>
            </Col>

            <Col md={6} lg={6}>
                <FormGroup>
                    <Input type="text" name='address'
                        value={user.address}
                        readOnly />
                    <FormText>
                        Dirección de residencia
                    </FormText>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default UserCard