import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col, Button, Collapse, Alert, ModalFooter } from 'reactstrap';
import { CARD_TITLE, CARD_BODY } from '../../../services/classService';
import ObservationPBO from '../pbo-report/pbo-observation/ObservationPBO';
import { IObservationPBO } from '../../../interface/interface';
import { genericErrorAlert } from '../../../services/alertService';
import { getProspectObservationById } from '../../../controllers/ProspectObservationController';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    prospectId: string
}

const ObservationModalPBO: React.FC<Props> = ({ isOpen, toggle, prospectId }) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [prospectObservations, setProspectObservations] = useState<Array<IObservationPBO>>([]);

    useEffect(() => {

        loadProspectObservation();

    }, [])

    const loadProspectObservation = async () => {

        try {

            setIsLoading(true);
            const observations = await getProspectObservationById(prospectId as string);
            setProspectObservations(observations.data);

        } catch (error) {

            console.log(error);
            genericErrorAlert("Error al cargar las observaciones", "Intentelo nuevamente")
            toggle()

        } finally {
            setIsLoading(false)
        }
    }

    return (

        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "1300px" }}>
            <ModalHeader tag="h4" className={CARD_TITLE} toggle={toggle}> <b>HISTORIAL DE OBSERVACIONES</b></ModalHeader>

            <ModalBody className={CARD_BODY} >


                {isLoading ? (< Alert color="warning" className="text-center m-3">
                    <b className="h2">Cargando observaciones del prospecto...</b>
                </Alert>) : (

                    <Row>
                        <Col>
                            <ObservationPBO observationList={prospectObservations} prospectId={prospectId} loadProspectObservation={loadProspectObservation} />
                        </Col>
                    </Row>

                )}

            </ModalBody>

            <ModalFooter className={CARD_TITLE + "text-white"}>
                <Button color='secondary' onClick={toggle}>
                    <b>Cerrar</b>
                </Button>
            </ModalFooter>

        </Modal>
    )
}

export default ObservationModalPBO
