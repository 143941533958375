import React, { useState } from 'react'
import 'mdb-ui-kit/css/mdb.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import ContractModalChangeStatus from '../contract-modal/ContractModalChangeStatus';
import { contractStatusDictionary } from '../../../dictionary/Dictionary';

interface Props {
    contractStatus: string;
    contractId: string;
    contractSerial?: string;
    canEdit?: boolean;
    checkerList?: Array<any>;
    contractList?: Array<any>;
    setContractList?: (contractList: Array<any>) => void;
}
//'Validar-Pago','Crear-Usuario','Pendiente-Verificación','Activo','Información-Incompleta', 'Terminado','Anulado' 

const ContractStatusInfo: React.FC<Props> = ({ contractStatus, contractId, contractSerial = "none", canEdit = false, checkerList = [] ,contractList = [], setContractList = () => { }}) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const checkContractStatus = (status: string) => {

        switch (status) {
            case 'Validar-Pago':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Validar Pago</span>
            case 'Crear-Usuario':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Crear Usuario</span>
            case 'Pendiente-Verificación':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Pendiente Verificación</span>
            case 'Información-Incompleta':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Información Incompleta </span>
            case 'Activo':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Activo</span>
            case 'Terminado':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Terminado</span>
            case 'Anulado':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Anulado</span>
            case 'Desactivado':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Desactivado</span>
            case 'Verificación-Aprobada':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Verificación-Aprobada</span>
            case 'Verificación-Rechazada':
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Verificación-Rechazada</span>
            default:
                return <span onClick={toggleModal} className={contractStatusDictionary[status] + ' p-2 col-12 custom-pill '}>Error </span>
        }
    }

    return (
        <div>
            {checkContractStatus(contractStatus)}
            {canEdit ? <ContractModalChangeStatus isOpen={isModalOpen} toggle={toggleModal} contractId={contractId} contractSerial={contractSerial} contractStatus={contractStatus} checkerList={checkerList} contractList={contractList} setContractList={setContractList} /> : null}
        </div>
    )
}

export default ContractStatusInfo