import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, FormGroup, Input, FormText, ModalFooter, Button, Alert, Row, Col, Card, CardBody, CardHeader, CardTitle, InputGroup, InputGroupText, Table } from 'reactstrap';
import { contractStatusDictionary } from '../../../dictionary/Dictionary';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import { cancelBeneficiaryClass, getBeneficiaryByClassId, getClassInformationDetails, getClassScheduleById, getTeacherByClassId } from '../../../controllers/ClassController';
import { NumericFormat } from 'react-number-format';
import { Beneficiary, User } from '../../../interface/interface';
import { genericErrorAlert, genericSuccessAlert, internalServerErrorAlert } from '../../../services/alertService';
import Swal from 'sweetalert2';
import { getCookieValue } from '../../../services/cookieService';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    classId: string;
    isAdmin?: boolean;
    loadClass?: () => void;
}

const ScheduleModal: React.FC<Props> = ({ isOpen, toggle, classId, isAdmin = false, loadClass = () => { } }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [currentTeacher, setCurrentTeacher] = useState<any>({});
    const [beneficiaryList, setBeneficiaryList] = useState<Array<any>>([]);
    const [classSchedule, setClassSchedule] = useState<any>({});
    const [cancelProcess, setCancelProcess] = useState<boolean>(false);

  const [userValues,setUserValues] = useState<User|null>(null)

    useEffect(() => {

        const cookie = getCookieValue("nbp-management-auth");

        const { user } = cookie;
      
        setUserValues(user);

        loadClassInformation();

    }, [])

    const loadClassInformation = async () => {
        try {
            const classInformationDetails = await getClassInformationDetails(classId);

            setClassSchedule(classInformationDetails.data.classSchedule[0]);
            setBeneficiaryList(classInformationDetails.data.beneficiaryList);
            setCurrentTeacher(classInformationDetails.data.teacher[0]);

        } catch (error: any) {

            const { title, message } = error.response.data;
            genericErrorAlert(title, message)
            toggle();

        } finally {
            setIsLoading(false);
        }
    }

    const handleCancel = async (beneficiaryId: string) => {

        Swal.fire({
            title: '¿Esta seguro de cancelar esta clase?',
            html: 'Una vez confirmada, se le <b>notificará</b> al beneficiario sobre dicha cancelación.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Rechazar',

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    setCancelProcess(true);
                    await cancelBeneficiaryClass(beneficiaryId, classId);

                    if (beneficiaryList.length !== 1) {
                        setBeneficiaryList(beneficiaryList.filter(beneficiary => beneficiary.id !== beneficiaryId));
                    } else {
                        toggle();
                    }

                    genericSuccessAlert("Clase cancelada", "Clase N° - " + classId + " cancelada");

                } catch (error: any) {

                    const { title, message } = error.response.data;
                    genericErrorAlert(title, message)

                } finally {

                    setCancelProcess(false)
                }
            }
        })
    }

    function formatISODate(inputDate: string) {

        const dateObject = new Date(inputDate);

        const year = dateObject.getUTCFullYear();
        const month = `0${dateObject.getUTCMonth() + 1}`.slice(-2);
        const day = `0${dateObject.getUTCDate()}`.slice(-2);

        return `${year}-${month}-${day}`;
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "1200px" }}>
            <ModalHeader className={CARD_TITLE + " text-white"} toggle={toggle}>Detalle de clase - N° {classId}</ModalHeader>

            <ModalBody className={CARD_BODY} >
                {isLoading ? (<ModalBody><Alert color="warning" className="text-center m-3">
                    <b className="h2">Cargando detalle de clase... N° {classId}</b>
                </Alert></ModalBody>) : (<Row>

                    <Col className='col-12'>
                        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
                            <CardHeader tag="h4" className={CARD_TITLE} >
                                <b>PROGRAMACIÓN</b>
                            </CardHeader>
                            <CardBody className='text-white'>


                                <Row>
                                    <Col md={6} lg={6}>
                                        <FormGroup>
                                            <Input type="date" name='first_name'
                                                value={formatISODate(classSchedule.date)}
                                                readOnly />
                                            <FormText>
                                                Fecha
                                            </FormText>
                                        </FormGroup>

                                    </Col>

                                    <Col md={6} lg={6}>
                                        <FormGroup>
                                            <Input type="text" name='document_number'
                                                value={classSchedule.hour + ""}
                                                readOnly
                                            />
                                            <FormText>
                                                Hora
                                            </FormText>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} lg={6}>
                                        <FormGroup>
                                            <Input type="text" name='document_number'
                                                value={classSchedule.classroom_name + ""}
                                                readOnly
                                            />
                                            <FormText>
                                                Salon
                                            </FormText>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} lg={6}>
                                        <FormGroup>
                                            <Input type="text" name='modality'
                                                value={classSchedule.modality}
                                                readOnly />
                                            <FormText>
                                                Modalidad
                                            </FormText>
                                        </FormGroup>

                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col className='col-12'>
                        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
                            <CardHeader tag="h4" className={CARD_TITLE} >
                                <b>DOCENTE ASIGNADO</b>
                            </CardHeader>
                            <CardBody className='text-white'>


                                <Row>
                                    <Col md={6} lg={6}>
                                        <FormGroup>
                                            <Input name='first_name'
                                                value={currentTeacher?.first_name + ' ' + currentTeacher.second_name + ' ' + currentTeacher.last_name + ' ' + currentTeacher.second_last_name}
                                                readOnly />
                                            <FormText>
                                                Nombre Completo
                                            </FormText>
                                        </FormGroup>

                                    </Col>
                                    <Col md={6} lg={6}>

                                        <FormGroup className="mb-4">

                                            <Input type="text" name='second_last_name'
                                                value={currentTeacher.document_type + ""}
                                                readOnly
                                            />

                                            <FormText>
                                                Tipo de documento de identidad
                                            </FormText>
                                        </FormGroup>
                                    </Col>


                                    <Col md={6} lg={6}>
                                        <FormGroup>
                                            <Input type="text" name='document_number'
                                                value={currentTeacher.document_number + ""}
                                                readOnly
                                            />
                                            <FormText>
                                                Número de documento
                                            </FormText>
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col className='col-12'>
                        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
                            <CardHeader tag="h4" className={CARD_TITLE} >
                                <b>BENEFICIARIOS ASIGNADOS</b>
                            </CardHeader>
                            <CardBody className='text-white'>
                                <Row>
                                    <Table striped responsive className='text-center'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th className='text-center'>Identificación</th>
                                                <th className='text-center'>Nombre</th>
                                                <th className='text-center'>Lección</th>
                                                {(isAdmin && userValues && userValues.role_id !== 6) ? (<th className='text-center'>Opciones</th>) : null}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {beneficiaryList.map((user, index) =>
                                            (<tr key={index}>
                                                <td>
                                                    <p><b>{index + 1}</b></p>
                                                </td>

                                                <td>
                                                    <p>{user.document_number + " - (" + user.document_type + ")"}</p>
                                                </td>

                                                <td>
                                                    {user.first_name + " " + user.second_name + " " + user.last_name + " " + user.second_last_name}
                                                </td>

                                                <td>
                                                    <p>{user.lesson_name}</p>
                                                </td>

                                                {
                                                    (isAdmin && userValues && userValues.role_id !== 6) ? (<td>
                                                        <p> <Button color="danger" title='Detalle de Usuario' onClick={() => handleCancel(user.id)} disabled={cancelProcess}>
                                                            <b> Cancelar</b>
                                                        </Button></p>
                                                    </td>) : (null)
                                                }

                                            </tr>)
                                            )}
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card></Col>

                </Row>)}
            </ModalBody>

            <ModalFooter className={CARD_TITLE + " text-white"}>

                <Button color='secondary' className='col-12' onClick={toggle}>
                    <b>Cerrar</b>
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ScheduleModal