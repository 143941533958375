import React from 'react'
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, Row } from 'reactstrap'
import { Reference } from '../../../../interface/interface'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'

interface Props {
    reference: Reference
    setReference: (reference: Reference) => void

}

const ReferenceCardForm: React.FC<Props> = ({ reference, setReference }) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setReference({
            ...reference, [name]: value
        });
    };
    return (
        <Card className= {CARD_BODY+'m-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>Referencia</b>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input name='first_name'
                                value={reference.first_name}
                                onChange={handleInputChange}
                                required />
                            <FormText>
                                Primer nombre (*)
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='second_name'
                                value={reference.second_name}
                                onChange={handleInputChange}
                            />
                            <FormText>
                                Segundo nombre
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='last_name'
                                value={reference.last_name}
                                onChange={handleInputChange}
                                required
                            />
                            <FormText>
                                Primer Apellido (*)
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='second_last_name'
                                value={reference.second_last_name}
                                onChange={handleInputChange}
                            />
                            <FormText>
                                Segundo Apellido
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='profession'
                                value={reference.profession}
                                onChange={handleInputChange}
                                required />
                            <FormText>
                                Profesión (*)
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='phone_number'
                                value={reference.phone_number}
                                onChange={handleInputChange}
                                required />
                            <FormText>
                                 Número de teléfono (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col lg={12}>
                        <FormGroup className="form-outline mb-4">
                            <Input type="select" name="gender" value={reference.gender} onChange={handleInputChange}>
                                <option value="Masculino">Masculino</option>
                                <option value="Femenino">Femenino</option>
                                <option value="No Binario">No Binario</option>
                                <option value="Otro">Otro</option>
                            </Input>
                            <FormText>
                                Genero (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ReferenceCardForm