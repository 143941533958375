import React, { useEffect, useState } from 'react'
import '../../../styles/contractStyles.css'
import HolderForm from '../contract-sections/HolderForm'
import {Beneficiary, Contract, Holder, HolderWork,Reference, User } from '../../../interface/interface'
import HolderWorkForm from '../contract-sections/HolderWorkForm'
import BeneficiaryForm from '../contract-sections/BeneficiaryForm'
import ReferenceForm from '../contract-sections/reference-form/ReferenceForm'
import { Button, Col, Container, Row } from 'reactstrap'
import {createCourtesyContract, createCourtesyContractWithRegisteredHolder } from '../../../controllers/ContractController'
import Swal from 'sweetalert2'
import { existHolderByDocumentNumber } from '../../../controllers/HolderController'
import { isActiveBeneficiary } from '../../../controllers/BeneficiaryController'
import { useNavigate } from 'react-router-dom'
import { getCookieValue } from '../../../services/cookieService'
import { validateToken } from '../../../services/authService'
import HolderTypeForm from '../contract-sections/HolderTypeForm'
import {internalServerErrorAlert } from '../../../services/alertService'
import ProgramInformationCourtesy from '../contract-sections/ProgramInformationCourtesy'

interface Props {
    user: User | null;
}

const CourtesyContractForm: React.FC<Props> = ({ user }) => {

    const navigate = useNavigate();
    const [fullName, setFullName] = useState("");

    //  const fullName = user?.first_name as string + " " + user?.second_name + " " + user?.last_name + " " + user?.second_last_name;

    useEffect(() => {

        const cookie = getCookieValue("nbp-management-auth");

        if (!cookie) {
            navigate("/");;
        }

        validateToken("Contract Form");

        const { user } = cookie;
        setFullName(user?.first_name + " " + user?.second_name + " " + user?.last_name + " " + user?.second_last_name);

    }, [])

    const [createContractLoading, setCreateContractLoading] = useState<boolean>(false);

    const [holderType, setHolderType] = useState<string>("new");

    const [holder, setHolder] = useState<Holder>({
        document_number: "",
        document_type: "CC",
        gender: "Masculino",
        first_name: "",
        second_name: "",
        last_name: "",
        second_last_name: "",
        birthdate: "",
        phone_number: "",
        whatsapp_number: "",
        address: "",
        email: "",
        salary: 0
    })

    const [holderWork, setHolderWork] = useState<HolderWork>({
        name: "",
        position: "",
        address: "",
        phone_number: ""
    });

    const [contract, setContract] = useState<Contract>({
        holder_signature: "",
        user_signature: ""
    });

    const [beneficiaryAmount, setBeneficiaryAumount] = useState<number>(0);
    const [beneficiaries, setBeneficiaries] = useState<Array<Beneficiary>>([]);

    const [referenceOne, setReferenceOne] = useState<Reference>({
        gender: 'Masculino',
        first_name: '',
        second_name: '',
        last_name: '',
        second_last_name: '',
        phone_number: '',
        profession: '',
    })

    const [referenceTwo, setReferenceTwo] = useState<Reference>({
        gender: 'Masculino',
        first_name: '',
        second_name: '',
        last_name: '',
        second_last_name: '',
        phone_number: '',
        profession: '',
    })








    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (beneficiaryAmount === 0) {
            needBeneficiaryAlert();
            return
        }

        if (!verifyAllSignature()) {
            return
        }

        Swal.fire({
            title: '¿Esta seguro de enviarlo?',
            icon: 'warning',
            html:
                'Esta es una matricula de tipo <b>cortesía</b>, una vez enviada, se le <b>notificará</b> al titular sobre la creación de este documento, ' +
                'al siguiente correo: ' +
                `<b>${holder.email}</b>`,
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setCreateContractLoading(true)
                    if (holderType === "new") {
                        const isRegisterHolder = await existHolderByDocumentNumber(holder.document_type, holder.document_number);

                        if (isRegisterHolder.data.exist) {
                            holderRegisteredAlert();
                            return
                        }
                    }

                    if (!await verifyBeneficiary()) {
                        return
                    }

                    if (holderType === "new") {
                        await createCourtesyContract(user as User, holder, holderWork, contract, beneficiaryAmount, beneficiaries, referenceOne, referenceTwo)
                    } else {

                        await createCourtesyContractWithRegisteredHolder(user as User, holder, holderWork, contract, beneficiaryAmount, beneficiaries, referenceOne, referenceTwo)
                    }

                    contractAddedSuccessAlert();

                } catch (error: any) {
                    const { title, message } = error.response.data;

                    internalServerErrorAlert(title, message)
                    console.log(error);

                } finally {
                    setCreateContractLoading(false)
                }
            }
        })
    }

    const verifyBeneficiary = async () => {

        const activeBeneficiary = [];

        for (let index = 0; index < beneficiaries.length; index++) {
            const beneficiary = beneficiaries[index];
            const isActive = await isActiveBeneficiary(beneficiary.document_type, beneficiary.document_number)
            if (isActive.data.exist) {

                beneficiaryActivatedAlert(index + 1);
                return false;
            }
        }

        return true;

    }

    const contractAddedSuccessAlert = () => {

        Swal.fire({
            icon: 'success',
            title: 'Contrato guardo con exito',
            showConfirmButton: true,
        })

    }

    const needBeneficiaryAlert = () => {

        Swal.fire({
            icon: 'error',
            title: 'Falta de beneficiarios',
            text: 'Se necesita agregar almenos un beneficiarios para enviar la solicitud.',
        })

    }

    const holderRegisteredAlert = () => {

        Swal.fire({
            icon: 'error',
            title: 'Titular ya registrado',
            text: 'El tipo de documento especificado para el titular ya se encuentra registrado.',
        })

    }

    const beneficiaryActivatedAlert = (beneficiaryIndex: number) => {

        Swal.fire({
            icon: 'error',
            title: 'No se puede agregar al beneficiario (' + beneficiaryIndex + ')',
            text: 'El beneficiario cuenta con una proceso de matricula abierto.',
        })

    }

    const verifyAllSignature = () => {

        const noCompleteSignature = []

        if (contract.holder_signature.trim() === "") {
            noCompleteSignature.push("Firma principal del Titular")
        }

        if (contract.user_signature.trim() === "") {
            noCompleteSignature.push("Firma principal del empleado")
        }


        if (noCompleteSignature.length > 0) {
            completeAllSignature(noCompleteSignature.join(" - "))
            return false
        }
        return true

    }

    const completeAllSignature = (information: string) => {

        Swal.fire({
            icon: 'error',
            title: 'Completa todas las <b>firmas</b> antes de enviar el formulario',
            text: information,
        })
    }

    return (

        <Container fluid className='p-0 m-0'>

            <HolderTypeForm holder={holder} setHolder={setHolder} holderType={holderType} setHolderType={setHolderType} setHolderWork={setHolderWork} />

            <form onSubmit={handleSubmit}>

                <HolderForm holder={holder} setHolder={setHolder} enabled={(holderType === "new")} />
                <HolderWorkForm holderWork={holderWork} setHolderWork={setHolderWork} enabled={(holderType === "new")} />

                <BeneficiaryForm beneficiaries={beneficiaries} setBeneficiaries={setBeneficiaries} beneficiaryAmount={beneficiaryAmount} setBeneficiaryAmount={setBeneficiaryAumount} holder={holder} />

                <ReferenceForm referenceOne={referenceOne} setReferenceOne={setReferenceOne} referenceTwo={referenceTwo} setReferenceTwo={setReferenceTwo} />
              
                <ProgramInformationCourtesy contract={contract} setContract={setContract}></ProgramInformationCourtesy>
              
                <Row>
                    <Col className='d-flex d-flex justify-content-center align-items-center px-4'>
                        <Button type='submit' color='warning' className=' col-12 text-center mb-4' disabled={createContractLoading}><b>{!createContractLoading ? "Enviar Inscripción" : "Enviando información..."}</b></Button>
                    </Col>
                </Row>

            </form>

        </Container>
    )
}

export default CourtesyContractForm