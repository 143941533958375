import { useState } from 'react'
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Form, FormGroup, FormText, Input, InputGroup, InputGroupText, Row } from 'reactstrap'
import { Holder } from '../../../../interface/interface'
import NumberFormat, { NumberFormatValues, NumericFormat } from 'react-number-format';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import { dateFormatDDMMYYY, dateformatISO } from '../../../../utils/dateUtils';
import VerificactionFormGroupDocumentType from '../verification-form-inputs/VerificactionFormGroupDocumentType';
import VerificationFormGroupGender from '../verification-form-inputs/VerificationFormGroupGender';
import VerificationFormGroupMonetary from '../verification-form-inputs/VerificationFormGroupMonetary';
import VerificationFormGroupText from '../verification-form-inputs/VerificationFormGroupText';

interface Props {
  holder: Holder
  setHolder: (holder: Holder) => void

}

const VerificationHolderSection: React.FC<Props> = ({ holder, setHolder }) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setHolder({
      ...holder, [name]: value
    });
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Eliminar puntos y comas y convertirlo en un número decimal
    const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
    setHolder({
      ...holder,
      [name]: numericValue
    });


  };

  return (
    <Card className={CARD_BODY + ' m-3 contract-backgroud '} >
      <CardHeader tag="h4" className={CARD_TITLE}  >
        <b>Datos del Titular </b>
      </CardHeader>
      <CardBody className='text-white'>

        <CardTitle className='underline ' tag="h5" lg={12}>
          Nombre completo
        </CardTitle>

        <Row>

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='first_name'
            value={holder.first_name}
            onChange={handleInputChange}
            formText='Primer Nombre'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='second_name'
            value={holder.second_name}
            onChange={handleInputChange}
            formText='Segundo Nombre'

          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='last_name'
            value={holder.last_name}
            onChange={handleInputChange}
            formText='Primer Apellido'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='second_last_name'
            value={holder.second_last_name}
            onChange={handleInputChange}
            formText='Segundo Apellido'
            required
          />

        </Row>

        <CardTitle className='underline' tag="h5" lg={12}>
          Información de Identidad
        </CardTitle>

        <Row>
          <VerificactionFormGroupDocumentType
            type="select"
            name="document_type"
            value={holder.document_type}
            onChange={handleInputChange}
            formText="Tipo de documento de identidad"
            required />



          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='document_number'
            value={holder.document_number}
            onChange={handleInputChange}
            formText='Número de documento'
            required
          />

          <VerificationFormGroupText
            type='date'
            md={12} lg={12}
            name='birthdate'
            value={dateformatISO(holder.birthdate)}
            onChange={handleInputChange}
            formText='Fecha de nacimiento'
            required
          />

          <VerificationFormGroupGender
           md={12}
            lg={12} 
            type="select" 
            name="gender" 
            value={holder.gender} 
            onChange={handleInputChange} 
            formText='Género'
            required/>

        </Row>

        <CardTitle className='underline' tag="h5" lg={12}>
          Información personal
        </CardTitle>

        <Row>

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='phone_number'
            value={holder.phone_number}
            onChange={handleInputChange}
            formText=' Número de teléfono'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='whatsapp_number'
            value={holder.whatsapp_number}
            onChange={handleInputChange}
            formText=' Número de WhatsApp'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='email'
            value={holder.email}
            onChange={handleInputChange}
            formText='Correo Electronico'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='address'
            value={holder.address}
            onChange={handleInputChange}
            formText='Dirección de residencia'
            required
          />

          <VerificationFormGroupMonetary
            type='text'
            md={12} lg={12}
            name='salary'
            onChange={handleNumberChange}
            value={holder.salary + ",00"}
            formText='Promedio Salarial'
            required
          />

        </Row>
      </CardBody>
    </Card>
  )
}

export default VerificationHolderSection