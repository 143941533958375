
import { PaymentModality } from '../interface/interface';
import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';

export const getPaymentModalityByContractNumber = async (contractId: string) => {
    const exist = await axiosInstance.post(mainRoute + 'paymentModality/getPaymentModalityByContractNumber', { contract_id: contractId })
    return exist;
}


export const updatePaymentModalityById = async (paymentModality: PaymentModality) => {
    return await axiosInstance.put(mainRoute + 'paymentModality/updatePaymentModalityById', { payment_modality: paymentModality })
}

