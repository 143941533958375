import { Leads } from '../interface/interface';
import axiosInstance from '../services/axiosInstance';

import { BASE_URL as mainRoute } from '../services/axiosInstance';

export const createLeads = async (leads: Leads) => {
    return await axiosInstance.post(mainRoute + 'leads/createLeads/', { leads: leads });
}

export const getAllLeads = async () => {
    return await axiosInstance.get(mainRoute + 'leads/getAllLeads/');
}

export const getCommercialUser = async () => {
    return await axiosInstance.get(mainRoute + 'leads/getCommercialUser/');
}

export const getAllUnssignmentLeads = async () => {
    return await axiosInstance.get(mainRoute + 'leads/getAllUnssignmentLeads/');
}

export const getAllCreatorLeads = async () => {
    return await axiosInstance.get(mainRoute + 'leads/getAllCreatorLeads/');
}


