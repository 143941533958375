import React, { useState } from 'react'
import VerificationForm from '../verification-form/verification-form-main/VerificationForm'
import NormalVerificationReport from '../verification-report/verified-report-manager/AdminVerificationReports'
import { Container } from 'reactstrap';

const VerificationManager = () => {

  const [selectedContract, setSelectedContract] = useState("");
  const [userResponsibleName, setResponsibleName] = useState("");

  return (

    <Container fluid>

      <NormalVerificationReport setSelectedContract={setSelectedContract} setResponsibleName={setResponsibleName}></NormalVerificationReport>
      <VerificationForm selectedContract={selectedContract} userResponsibleName={userResponsibleName}></VerificationForm>

    </Container>

  )
}

export default VerificationManager
