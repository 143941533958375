import { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Alert, Row } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../../services/classService'
import { Leads } from '../../../../../interface/interface'
import { genericErrorAlert } from '../../../../../services/alertService'
import { getAllPersonalUnassignmentLeads } from '../../../../../controllers/LeadsAssignmentController'
import ComercialLeadsTableReport from './leads-comercial-assignment-table/ComercialLeadsTableReport'
import PBOForm from '../../../../pbo/pbo-form/pbo-form-main/PBOForm'

const LeadsComercialAssignmentReport = () => {

    const [isLoading, setLoading] = useState(true);
    const [leadsList, setLeadsList] = useState<Array<Leads>>([]);
    const [selectedLead, setSelectedLead] = useState<number | null>(null);

    useEffect(() => {


        loadPersonalLeads();

    }, [])

    const loadPersonalLeads = async () => {

        try {
            setLoading(true);
            const loadList = await getAllPersonalUnassignmentLeads();
            setLeadsList(loadList.data);

        } catch (error) {

            console.log(error);
            genericErrorAlert("Error al cargar las asignaciones de leads", "Recargue la página")

        } finally {
            setLoading(false);
        }

    }

    return (
        <Card className={CARD_BODY + 'm-2'}>

            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>MIS ASIGNACIONES</b>
            </CardHeader>

            <CardBody className='mx-2'>

                {isLoading ? (<Alert color="warning" className="text-center m-3">
                    <b className="h2">Cargando listado de leads...</b>
                </Alert>) : (<div>{leadsList.length === 0 ? (<Alert color="warning" className="text-center m-3">
                    <b className="h2">No se encontro registro de leads</b>
                </Alert>) :

                    <Card>

                        <Row>
                            <ComercialLeadsTableReport leadsList={leadsList} setSelectedLead={setSelectedLead} />
                        </Row>

                    </Card>}

                </div>)}

            </CardBody>

            {selectedLead ? (<PBOForm leadsId={selectedLead} loadPersonalLeads={loadPersonalLeads} setSelectedLead={setSelectedLead} />) : (null)}

        </Card>
    )
}

export default LeadsComercialAssignmentReport
