const navOptions = {
    home: "Inicio",
    contract: "Contratos",
    newContract: "Nuevo contrato",
    myContracts: "Mis contratos",
    allContracts: "Todos los contratos",
    contractOptions: "Opciones de contrato",
    user: "Usuarios",
    registerUser: "Registrar Usuario",
    logout: "Cerrar Sesión",
    userList: "Lista de Usuarios",
    schedule: "Cronograma",
    newSchedule: "Agregar cita",
    allSchedule: "Todas las citas",
    mySchedule: "Mi agenda",
    scheduleReport: "Reporte de clases",
    mainPVO: "PVO",
    registerPVO: "Agregar Prospecto",
    pboManager: "Administración",
    personalPBOReport: "Mis prospectos",
    allPVO: "Todos los Prospectos",
    verification: "Verificación",
    pendingVerification: "Mis Pendientes",
    reportVerification: "Reporte",
    personalReportVerification: "Mis verificaciones",
    leads: "Leads",
    leadsManagement: "Gestión",
    myLeads: "Mis Leads",
    myLeadsAssignment: "Mis asignaciones",
    attendance: "Asistencia",
    attendanceReport:"Reporte",
    email: "Correos",
    emailManagement:"Gestión"
}  


export const navIcons = {
    home: "bi bi-house-door-fill",
    contract: "bi bi-person-badge-fill",
    users: "bi bi-person-lines-fill",
    newContract: "bi bi-journal-plus",
    myContracts: "bi bi-journal",
    allContracts: "bi bi-journal-arrow-up",
    contractOptions: "bi bi-gear-fill",
    registerUser: "bi bi-person-add",
    logout: "bi bi-door-closed-fill",
    userList: "bi bi-people-fill",
    schedule: "bi bi-calendar-event-fill",
    newSchedule: "bi bi-calendar-plus",
    allSchedule: "bi bi-calendar-week-fill",
    mySchedule: "bi bi-calendar2-fill",
    scheduleReport: "bi bi-calendar-check",
    mainPVO: "bi bi-person-bounding-box",
    registerPVO: "bi bi-plus-circle-fill",
    pboManager: "Administración",
    personalPBOReport: "bi bi-person-lines-fill",
    allPVO: "bi bi-list-check",
    verification: "bi bi-clipboard-check-fill",
    pendingVerification: "bi bi-clipboard",
    reportVerification: "bi bi-clipboard-pulse",
    personalReportVerification: "bi bi-clipboard2-check-fill",
    attendance:"bi bi-calendar2-check-fill",
    attendanceReport:"bi bi-card-checklist",
    email: "bi bi-envelope-fill",
    emailManagement:"bi bi-envelope-paper"
}

export default navOptions;