import React, { useState } from 'react'
import { Card, Col, CardHeader, CardBody, Row, Input, FormText, FormGroup, Button, Alert, Label, Collapse, Table } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import { Beneficiary } from '../../../../interface/interface';
import { findBeneficiaryByName, getBeneficiaryByContractNumberSchedule } from '../../../../controllers/BeneficiaryController';
import { beneficiaryClassErrorAlert, beneficiaryNotFountAlert, genericErrorAlert, genericSuccessAlert, internalServerErrorAlert } from '../../../../services/alertService';
import ContractStatusInfo from '../../../contract/contract-status/ContractStatusInfo';
import ManualCloseAlert from '../../../alerts/ManualCloseAlert';
import ScheduleClassForm from '../schedule-form-sections/ScheduleClassForm';
import ScheduleActivityForm from '../schedule-form-sections/ScheduleActivityForm';
import ScheduleInduction from '../schedule-form-sections/ScheduleInductionForm';
import ScheduleClassHistory from '../../schedule-modal/ScheduleClassHistory';
import ScheduleBeneficiaryDetailsModal from '../../schedule-modal/ScheduleBeneficiaryDetailsModal';
import ScheduleCanceledClassHistory from '../../schedule-modal/ScheduleCanceledClassHistory';

const ScheduleForm = () => {

    const [isLoadingSearchBeneficiary, setisLoadingSearchBeneficiary] = useState(false);
    const [isLoadingBeneficiary, setisLoadingBeneficiary] = useState(false);

    //Beneficiario seleccionado para cargar su información
    const [beneficiaryIdHistory, setBeneficiaryIdHistory] = useState<string>("");
    const [beneficiaryIdDetails, setBeneficiaryIdDetails] = useState<string>("");
    const [beneficiaryIdCanceled, setBeneficiaryIdCanceled] = useState<string>("");

    //Controla el ver historial de lecciones
    const [modalOpenStateHistory, setModalOpenStateHistory] = useState<boolean>(false);

    //Controla ver el historial de cancelaciones
    const [modalOpenStateCanceledHistory, setModalOpenCanceledHistory] = useState<boolean>(false);

    //Controla ver los detalles de cotnacto del beneficiario
    const [modalDetailsOpenState, setModalDetailsOpenState] = useState<boolean>(false);

    //Inversor de visibilidad del modal de historial de clases
    const toggleModalClassHistory = async () => {
        setModalOpenStateHistory(!modalOpenStateHistory);
    };

    //Inversor de visibilidad del modal de historial de clases
    const toggleModalCanceledHistory = async () => {
        setModalOpenCanceledHistory(!modalOpenStateCanceledHistory);
    };

    //Inversor de visibilidad del modal de detalles del beneficiario
    const toggleModalDetails = async () => {
        setModalDetailsOpenState(!modalDetailsOpenState);
    };

    //Controllador de estado del modal de historial de clases
    const handleClassHistory = (beneficiaryId: string) => {
        setBeneficiaryIdHistory(beneficiaryId)
        toggleModalClassHistory();
    }

    //Controlador de estado del modal historial de cancelaciones
    const handleCanceledHistory = (beneficiaryId: string) => {
        setBeneficiaryIdCanceled(beneficiaryId)
        toggleModalCanceledHistory();
    }

    //Controlador de estado del modal de detalles del beneficiario
    const handleBeneficiaryDetails = (beneficiaryId: string) => {
        setBeneficiaryIdDetails(beneficiaryId)
        toggleModalDetails();
    }

    //Tipo de cita
    const [scheduleTypeSelected, setScheduleTypeSelected] = useState("");

    //Beneficiarios cargados
    const [beneficiaryList, setBeneficiaryList] = useState<Array<Beneficiary>>([]);

    //Información de la agenda
    const [beneficiarySelected, setBeneficiarySelected] = useState("")

    const [fullName, setFullName] = useState({ first_name: "", second_name: "", last_name: "", second_last_name: "" });

    const [beneficiarySearchList, setBeneficiarySearchList] = useState<Array<Beneficiary>>([]);

    const [schedule, setSchedule] = useState({ contract_id: "", time: "" });

    const [visibleBeneficiarySearch, setVisibleBeneficiarySearch] = useState(false);

    //Inversor de estado del buscado de beneficiario
    const toggleVisibleBeneficiarySearch = () => {
        setVisibleBeneficiarySearch(!visibleBeneficiarySearch);
    };

    const handleReset = () => {
        setBeneficiarySelected("")
    }

    const handleFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFullName({ ...fullName, [name]: value });
    };

    const handleBenficiarySelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setBeneficiarySelected(value)
    };

    const handleSelectScheduleType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setScheduleTypeSelected(value);
    };

    const handleLoadBeneficiary = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        if (schedule.contract_id.trim() === "") {
            genericErrorAlert("Error en el N° de contrato", "Escriba un N° de contrato ")
            return;
        }

        if (isNaN(Number(schedule.contract_id))) {
            genericErrorAlert("Error al escribir el N° de contrato.", "Escriba un N° de contrato valido.")
            return;
        }

        handleReset();

        try {
            setisLoadingBeneficiary(true);
            const beneficiaryList = await getBeneficiaryByContractNumberSchedule(schedule.contract_id);
            setBeneficiaryList(beneficiaryList.data.beneficiary);
            genericSuccessAlert("Información encontrada", "Cargando información de beneficiario/s");

        } catch (error: any) {

            const { title, message } = error.response.data;

            if (error.response.status === 400) {
                beneficiaryNotFountAlert(title, message);
                setBeneficiaryList([]);
            } else {
                internalServerErrorAlert(title, message)
            }

        } finally {
            setisLoadingBeneficiary(false);
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSchedule({ ...schedule, [name]: value })

    };

    const handleSearchBeneficiary = async () => {

        try {

            let secondName = fullName.second_name;

            if (fullName.second_name.trim() == "") {
                secondName = "*****";
            }

            setisLoadingSearchBeneficiary(true);
            const beneficiaryList = await findBeneficiaryByName(fullName.first_name, secondName, fullName.last_name, fullName.second_last_name);

            setBeneficiarySearchList(beneficiaryList.data.beneficiary);

        } catch (error: any) {

            const { title, message } = error.response.data;
            beneficiaryClassErrorAlert(title, message);
            setBeneficiarySearchList([]);
            console.log(error)

        } finally {
            setisLoadingSearchBeneficiary(false);
        }

    }

    return (
        <Card className={CARD_BODY + 'm-3'}>
            <CardHeader tag="h4" className={CARD_TITLE + "text-center"} >
                <b>AGENDAR CITA</b>
            </CardHeader>
            <CardBody>

                <Row className='m-2'>
                    <ManualCloseAlert color='warning' text='Tener en cuenta que se implementó el envío de correos a los beneficiarios. Si se presenta un problema, se debe llamar al beneficiario para informarle.'></ManualCloseAlert>
                </Row>
                <Row className='m-2'>
                    <ManualCloseAlert color='warning' text='Para agendar citas se requieren 24 horas de antelación.'></ManualCloseAlert>
                </Row>

                <form>
                    <Row className='m-2'>
                        <Col>
                            <Button color='danger' className='bg col-12' onClick={toggleVisibleBeneficiarySearch}>
                                <b> Desplegar busqueda de beneficiario</b> {!visibleBeneficiarySearch ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>}
                            </Button>
                        </Col>
                    </Row>
                    <Collapse isOpen={visibleBeneficiarySearch}>
                        <div>


                            <Row className='m-2' >
                                <Col xs={6} md={3} lg={3} >
                                    <FormGroup>

                                        <Input name='first_name'
                                            value={fullName.first_name}
                                            onChange={handleFullName}
                                        />

                                        <FormText>
                                            Primer nombre
                                        </FormText>

                                    </FormGroup>
                                </Col>

                                <Col xs={6} md={3} lg={3} >
                                    <FormGroup>

                                        <Input name='second_name'
                                            value={fullName.second_name}
                                            onChange={handleFullName}
                                        />

                                        <FormText>
                                            Segundo Nombre
                                        </FormText>

                                    </FormGroup>
                                </Col>

                                <Col xs={6} md={3} lg={3} >
                                    <FormGroup>


                                        <Input name='last_name'
                                            value={fullName.last_name}
                                            onChange={handleFullName}
                                        />

                                        <FormText>
                                            Apellido
                                        </FormText>

                                    </FormGroup>
                                </Col>

                                <Col xs={6} md={3} lg={3} >
                                    <FormGroup>

                                        <Input name='second_last_name'
                                            value={fullName.second_last_name}
                                            onChange={handleFullName}
                                        />

                                        <FormText>
                                            Segundo apellido
                                        </FormText>

                                    </FormGroup>
                                </Col>


                                <Col>
                                    {beneficiarySearchList.length != 0 ? (<Table striped responsive className='text-center'>

                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th className='text-center'>N° Contrato</th>
                                                <th className='text-center'>Estado</th>
                                                <th className='text-center'>Identificación</th>
                                                <th className='text-center'>Beneficiario</th>
                                                <th className='text-center'>Detalles</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {beneficiarySearchList?.map((beneficiary, index) =>
                                            (<tr key={index}>
                                                <td>
                                                    <p><b>{index + 1}</b></p>
                                                </td>

                                                <td>
                                                    <p>{beneficiary.contract_id}</p>
                                                </td>
                                                <td>
                                                    {<ContractStatusInfo contractId={beneficiary.contract_id as string} contractStatus={beneficiary.contract_status as string} />}
                                                </td>
                                                <td>
                                                    <p>{beneficiary.document_number + " - (" + beneficiary.document_type + ")"}</p>
                                                </td>

                                                <td>
                                                    {beneficiary.first_name + " " + beneficiary.second_name + " " + beneficiary.last_name + " " + beneficiary.second_last_name}
                                                </td>
                                                <td>
                                                    <Button color="primary" title='Historial de clases' onClick={() => handleBeneficiaryDetails(beneficiary?.id as string)} >
                                                        <i className="bi bi-zoom-in"></i>
                                                    </Button>
                                                </td>
                                            </tr>)
                                            )}
                                        </tbody>
                                    </Table>) : (null)}


                                </Col>

                                <Col className='col-12'>
                                    <Button type='submit' color='primary' className='col-12' onClick={() => handleSearchBeneficiary()} disabled={isLoadingSearchBeneficiary}>
                                        <i className="bi bi-search"><b>{isLoadingSearchBeneficiary ? (" Realizando busqueda") : (null)}</b></i>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </form>

                <hr className="border-top" />

                <Row className='m-2'>

                    <form onSubmit={handleLoadBeneficiary}>

                        <Row>
                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input name='contract_id'
                                        value={schedule.contract_id}
                                        onChange={handleInputChange}
                                    />
                                    <FormText>
                                        Codigo del contrato
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={6} lg={6}>
                                <Button type='submit' className='btn col-12 mb-3' color='warning' disabled={isLoadingBeneficiary}><b>{isLoadingBeneficiary ? "Cargando Información" : "Cargar Beneficiarios"}</b></Button>
                            </Col>
                        </Row>
                    </form>

                    <Col>
                        <Card className={CARD_BODY + "contract-backgroud"}>
                            <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
                                <b>BENEFICIARIOS</b>
                            </CardHeader>
                            <CardBody>

                                <Col md={12} >
                                    {beneficiaryList.length > 0 ? (

                                        <div>
                                            <Card className='p-2 mb-2'>
                                                <Table striped responsive className='text-center'>

                                                <thead>
                                                        <tr > 

                                                            <th className='text-center'>#</th>
                                                            <th className='text-center'>Identificación</th>
                                                            <th className='text-center'>Beneficiario</th>
                                                            <th className='text-center'>N° de Clases esta semana</th>
                                                            <th className='text-center'>Historial de clases</th>
                                                            <th className='text-center'>Historial de cancelaciones</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {beneficiaryList.map((beneficiary, index) =>
                                                        (<tr key={index}>

                                                            <td>
                                                                <p><b>{index + 1}</b></p>
                                                            </td>

                                                            <td>
                                                                <p>{beneficiary.document_number + " - (" + beneficiary.document_type + ")"}</p>
                                                            </td>

                                                            <td>
                                                                {beneficiary.first_name + " " + beneficiary.second_name + " " + beneficiary.last_name + " " + beneficiary.second_last_name}
                                                            </td>
                                                            <td>
                                                                {beneficiary.class_amount}
                                                            </td>
                                                            <td>

                                                                <Button color="primary" title='Historial de clases' onClick={() => handleClassHistory(beneficiary?.id as string)} >
                                                                    <i className="bi bi-zoom-in"></i>
                                                                </Button>

                                                            </td>
                                                            <td>

                                                                <Button color="danger" title='Historial de cancelaciones' onClick={() => handleCanceledHistory(beneficiary?.id as string)} >
                                                                    <i className="bi bi-zoom-in"></i>
                                                                </Button>

                                                            </td>

                                                        </tr>)
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Card>

                                            <Row >

                                                <Col md={12}>
                                                    <FormGroup className="form-outline mb-4">
                                                        <Input type="select" name="beneficiary" value={beneficiarySelected} onChange={handleBenficiarySelected} disabled={beneficiaryList.length == 0} required>
                                                            <option value="">Seleccionar beneficiario</option>
                                                            {beneficiaryList.map((beneficiary, index) => (
                                                                <option key={index} value={beneficiary.id}>{beneficiary.id + " - " + beneficiary.document_number + " (" + beneficiary.document_type + ")" + " - " + beneficiary.first_name + " " + beneficiary.second_name + " " + beneficiary.last_name + " " + beneficiary.second_last_name + " "}</option>
                                                            ))}
                                                        </Input>

                                                        <FormText>
                                                            Beneficiario
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={12} >
                                                    <FormGroup className="form-outline mb-4">

                                                        <Input type="select" name="beneficiary" value={scheduleTypeSelected} onChange={handleSelectScheduleType}>
                                                            <option value="">Tipo de cita</option>
                                                            <option value="Clase">Clase</option>
                                                            {/**<option value="Inducción" disabled>Inducción (Desactivado)</option>
                                                        <option value="Actividad" disabled>Actividad (Desactivado)</option> */}
                                                        </Input>

                                                        <FormText className='m-0'>
                                                            Elegir tipo de cita
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </div>) :
                                        <Alert color="warning" className="text-center ">
                                            <b className="h2">Beneficiarios no cargados</b>
                                        </Alert>
                                    }
                                </Col>


                            </CardBody>

                        </Card>

                    </Col>
                </Row>

                {(beneficiaryList.length > 0 && beneficiarySelected !== "") ?

                    (<div>
                        <hr className="border-top" />

                        {scheduleTypeSelected === "Inducción" ? (<Row className='m-2'><Col><ScheduleInduction beneficiarySelected={beneficiarySelected} /></Col></Row>) : (null)}

                        {scheduleTypeSelected === "Actividad" ? (<Row className='m-2'><Col><ScheduleActivityForm /></Col></Row>) : (null)}

                        {scheduleTypeSelected === "Clase" ? (<Row className='m-2'><Col><ScheduleClassForm beneficiarySelected={beneficiarySelected} /></Col></Row>) : (null)}

                    </div>) : (null)}

                {beneficiaryList.length > 0 && beneficiarySelected === "" ? (<div>
                    {<Alert color="warning" className="text-center m-4 ">
                        <b className="h2">Selecione un beneficiario</b>
                    </Alert>}
                </div>) : (null)}

            </CardBody>

            {modalOpenStateHistory ? <ScheduleClassHistory beneficiaryId={beneficiaryIdHistory} isOpen={modalOpenStateHistory} toggle={toggleModalClassHistory} /> : null}
            {modalOpenStateCanceledHistory ? <ScheduleCanceledClassHistory beneficiaryId={beneficiaryIdCanceled} isOpen={modalOpenStateCanceledHistory} toggle={toggleModalCanceledHistory} /> : null}
            {modalDetailsOpenState ? <ScheduleBeneficiaryDetailsModal beneficiaryId={beneficiaryIdDetails} isOpen={modalDetailsOpenState} toggle={toggleModalDetails} /> : null}

        </Card>
    )
}

export default ScheduleForm