import { useState, useEffect } from "react";
import { Card, CardHeader, Alert, CardBody, Row, Col } from "reactstrap";
import { getAllVerifyAssigments } from "../../../../controllers/VerifyAssigmentController";
import { VerificationAssignment } from "../../../../interface/interface";
import { genericErrorAlert } from "../../../../services/alertService";
import { CARD_BODY, CARD_TITLE } from "../../../../services/classService";
import VerificationTableReport from "../verification-report-table/VerificationTableRepor";

const AdminVerificationReport = () => {

    const [verifyAssigmentList, setVerifyAssigmentList] = useState<Array<VerificationAssignment>>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        loadVerifyAssigments();

    }, [])

    const loadVerifyAssigments = async () => {

        try {

            setIsLoading(true);
            const verifyAssigments = await getAllVerifyAssigments();
            setVerifyAssigmentList(verifyAssigments.data[0]);

        } catch (error) {

            console.log(error);
            genericErrorAlert("Error al cargar información", "No se pudo cargar el reporte de asignaciones")

        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Card className={CARD_BODY + "my-2 nbp-background-logo m-2"}>
            <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
                <b className='p-0 m-0'>REPORTE DE VERIFICACIONES</b>
            </CardHeader>

            {
                isLoading ? (
                    <Alert color="warning" className="text-center m-3">
                        <b className="h2">Cargando reporte de verificaciones</b>

                    </Alert>) : (<Row>

                        {verifyAssigmentList.length === 0 ? (<Col>
                            <Alert color="warning" className="text-center m-3">
                                <b className="h2">No existe historial de verificaciones</b>
                            </Alert>
                        </Col>) :
                            (<Col>
                                <CardBody className="m-3">
                                    <Card className='py-3'>
                                        <Row>
                                            <Col>
                                                <VerificationTableReport verifyAssigmentList={verifyAssigmentList} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </CardBody>
                            </Col>)}
                    </Row>)
            }

        </Card>
    )
}

export default AdminVerificationReport