import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';

export const signin = async (document_type: string, document_number: string, password: string) => {

    const response = await axiosInstance.post(mainRoute + 'auth/signin', { document_type, document_number, password });
    return response;

}

export const resetPassword = async (document_type: string, document_number: string, password: string) => {

    const response = await axiosInstance.post(mainRoute + 'auth/resetPassword', {document_type: document_type, document_number:document_number, password:password });
    return response; 

}


export const registerUser = async (user: any) => {

    const registeredSuccess = await axiosInstance.post(mainRoute + 'auth/signup', { user })

    return registeredSuccess;

}

export const logoutUser = async () => {

}

export const verifyToken = async () => {
    return await axiosInstance.get(mainRoute + 'auth/verifyToken')
}

export const getCurrentUser = async (token: string) => {

    const currentUser = await axiosInstance.post(mainRoute + 'currentUser', {}, {
        headers: {
            Authorization: token
        }
    })

    return currentUser;
}