import React, { useState } from 'react'
import { Alert, Col, Row } from 'reactstrap'

interface Props {
    color: string,
    text: string
}

const ManualCloseAlert: React.FC<Props> = ({ color, text }) => {

    const [active, setActive] = useState(true)

    return (
        <div>
            {active ?
                <Alert color={color} className='pt-0'>
                    <Row>
                        <Col lg={12} className='d-flex align-items-center justify-content-end px-0'>
                            <i onClick={() => setActive(!active)} className={"bi bi-x-circle-fill "}  style={{ cursor: 'pointer' }}></i>
                        </Col>
                        <Col lg={12} className='text-center fs-5'>
                            <b>{text}</b>
                        </Col>
                    </Row>
                </Alert>
                : null}
        </div>

    )
}

export default ManualCloseAlert