export function getCookieValue(cookieName: string) {

  var cookie = document.cookie.replace(/(?:(?:^|.*;\s*)nbp-management-auth\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  if (cookie) {
    return JSON.parse(cookie);

  }

  return null;
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(';');

  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      var jsonValue = cookie.substring(name.length, cookie.length);
      return JSON.parse(jsonValue);
    }
  }

}