import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Row } from 'reactstrap'
import { Holder } from '../../../interface/interface'
import { NumericFormat } from 'react-number-format'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'
import {dateformatISO } from '../../../utils/dateUtils'

interface Props {
    holder: Holder
}

const HolderCard: React.FC<Props> = ({ holder }) => {

    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>DATOS DEL TITULAR</b>
            </CardHeader>
            <CardBody className='text-white'>

                <CardTitle className='underline' tag="h5" lg={12}>
                    Nombre completo
                </CardTitle>

                <Row>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input name='first_name'
                                value={holder.first_name}
                                readOnly />
                            <FormText>
                                Primer Nombre 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='second_name'
                                value={holder.second_name}
                                readOnly
                            />
                            <FormText>
                                Segundo Nombre
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='last_name'
                                value={holder.last_name}
                                readOnly
                            />
                            <FormText>
                                Primer Apellido 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='second_last_name'
                                value={holder.second_last_name}
                                readOnly />
                            <FormText>
                                Segundo Apellido 
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>

                <CardTitle className='underline' tag="h5" lg={12}>
                    Información de Identidad
                </CardTitle>

                <Row>

                    <Col md={6} lg={6}>

                        <FormGroup className="mb-4">

                            <Input type="text" name='second_last_name'
                                value={holder.document_type}
                                readOnly
                            />

                            <FormText>
                                Tipo de documento de identidad 
                            </FormText>
                        </FormGroup>
                    </Col>


                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='document_number'
                                value={holder.document_number}
                                readOnly
                            />
                            <FormText>
                                Número de documento 
                            </FormText>
                        </FormGroup>
                    </Col>


                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="date" name='birthdate'
                                value={dateformatISO(holder.birthdate)}
                                readOnly />
                            <FormText>
                                Fecha de nacimiento 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup className="mb-4">
                            <Input type="text" name='gender'
                                value={holder.gender}
                                readOnly />
                            <FormText>
                                Genero 
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>

                <CardTitle className='underline' tag="h5" lg={12}>
                    Información personal
                </CardTitle>

                <Row>

                    <Col md={6} lg={6}>

                        <FormGroup>
                            <Input type="text" name='phone_number'
                                value={holder.phone_number}
                                readOnly
                            />
                            <FormText>
                                Número de teléfono 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='whatsapp_number'
                                value={holder.whatsapp_number}
                                readOnly
                            />
                            <FormText>
                                Número de WhatsApp 
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="email" name='email'
                                value={holder.email}
                                readOnly
                            />
                            <FormText>
                                Correo Electronico 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='address'
                                value={holder.address}
                                readOnly />
                            <FormText>
                                Dirección de residencia 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='salary'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={holder.salary + ",00"}
                                    allowNegative={false}
                                    readOnly
                                    required />
                            </InputGroup>
                            <FormText>
                                Promedio Salarial 
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    )
}

export default HolderCard