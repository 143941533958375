import axiosInstance from '../services/axiosInstance';

import { BASE_URL as mainRoute } from '../services/axiosInstance';

export const getAllActiveCities = async () => {
    const cityList = await axiosInstance.get(mainRoute + 'cities/getAllCities')
    return cityList;
}

export const cityNameById = async (city_id: number) => {
    const cityName = await axiosInstance.get(mainRoute + 'cities/cityNameById/'+ city_id )
    return cityName;
} 
