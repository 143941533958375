import { MDBNavbarNav, MDBNavbarLink } from 'mdb-react-ui-kit'
import React from 'react'
import navOptions, { navIcons } from '../narvbar-options/NavbarOptions'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavItem, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import { AiFillHome } from "react-icons/ai";
import { GiEarthAmerica } from 'react-icons/gi'
import { TbWorldPlus, TbWorldSearch } from 'react-icons/tb'
interface Props {
    handleLogout: () => void
}

const AdminNavbar: React.FC<Props> = ({ handleLogout }) => {
    return (
        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 fw-bold'>

            <NavItem>
                <NavLink tag={Link} to="/home" style={{ textDecoration: 'none' }}>
                    <i><AiFillHome className='mb-1' /></i> {navOptions.home}
                </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <GiEarthAmerica /> Leads
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/marketing/leads/manager" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                            <TbWorldPlus fontSize="18px" className='pr-3' /> Gestión
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem>
                        <Link to="/marketing/leads/personal" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                            <TbWorldSearch fontSize="18px" />  Mis Leads
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.mainPVO}></i> {navOptions.mainPVO}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem >
                        <Link to="/pbo/manager" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.registerPVO}></i> {navOptions.registerPVO}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem >
                        <Link to="/pbo/report/personal" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.personalPBOReport}></i> {navOptions.personalPBOReport}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem >
                        <Link to="/pbo/report/admin" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.allPVO}></i> {navOptions.allPVO}
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.contract}></i> {navOptions.contract}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem >
                        <Link to="/contract/manager" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.newContract}></i> {navOptions.newContract}
                        </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem >
                        <Link to="/contract/mine" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.myContracts}></i>  {navOptions.myContracts}
                        </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                        <Link to="/contract/list" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.allContracts}></i>   {navOptions.allContracts}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem>
                        <Link to="/contract/options" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.contractOptions}></i>   {navOptions.contractOptions}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.verification}></i> {navOptions.verification}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/verification/pending" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.pendingVerification}></i> {navOptions.pendingVerification}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem>
                        <Link to="/verification/report/admin" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.reportVerification}></i> {navOptions.reportVerification}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem>
                        <Link to="/verification/report/personal" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.personalReportVerification}></i> {navOptions.personalReportVerification}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>

            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.schedule}></i> {navOptions.schedule}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem >
                        <Link to="/schedule/create" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.newSchedule}></i> {navOptions.newSchedule}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem>
                        <Link to="/schedule/list" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.allSchedule}></i>   {navOptions.allSchedule}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem >
                        <Link to="/schedule/dailyReport" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.scheduleReport}></i> {navOptions.scheduleReport}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.attendance}></i> {navOptions.attendance}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/attendance/manager" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.attendanceReport}></i> {navOptions.attendanceReport}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.email}></i> {navOptions.email}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/email/manager" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.emailManagement}></i> {navOptions.emailManagement}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>

                <DropdownToggle nav caret>
                    <i className={navIcons.users}></i> {navOptions.user}
                </DropdownToggle>

                <DropdownMenu end>
                    <DropdownItem>
                        <Link to="/user/register" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.registerUser}></i>  {navOptions.registerUser}
                        </Link>
                    </DropdownItem>
                    <DropdownItem divider />

                    <DropdownItem >
                        <Link to="/user/list" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.userList}></i>  {navOptions.userList}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>

            </UncontrolledDropdown>

            <MDBNavbarLink className='d-flex justify-content-between' onClick={handleLogout} style={{ cursor: 'pointer' }}><div><b>{navOptions.logout} <i className={navIcons.logout}></i> </b></div></MDBNavbarLink>

        </MDBNavbarNav>
    )
}

export default AdminNavbar