import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import RegularContractForm from '../contract-form/RegularContractForm';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import { getCookieValue } from '../../../services/cookieService';
import { useNavigate } from 'react-router-dom';
import { validateToken } from '../../../services/authService';
import { User } from '../../../interface/interface';
import Swal from 'sweetalert2';
import CourtesyContractForm from '../contract-form/CourtesyContractForm';

const ContractManager = () => {

    const [contractType, setContractType] = useState("regular");
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);

    const handleContractType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        Swal.fire({
            title: '¿Esta seguro de realizar el cambio de tipo de contrato?',
            text: "Una vez realizado el cambio, se perdera la información actual.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                setContractType(value);
            }
        })

    }

    useEffect(() => {

        const cookie = getCookieValue("nbp-management-auth");

        if (!cookie) {
            navigate("/");;
        }

        const { user } = cookie;
        setUser(user);

    }, [])

    return (
        <Container fluid className='px-3'>

            <Card className={CARD_BODY + 'm-3 contract-backgroud'}>

                <CardHeader tag="h4" className={CARD_TITLE} >
                    <b>Información del Empleado</b>
                </CardHeader>

                <CardBody className='text-white'>

                    <Row>
                        <Col>
                            <p><b>Nombre Completo:</b> {user?.first_name} {user?.second_name} {user?.last_name} {user?.second_last_name}</p>
                            <p><b>Identificación: </b> {user?.document_number}</p>
                            <p><b>Tipo de documento:</b> {user?.document_type}</p>
                            <hr />
                            <FormGroup>
                                <Input type='select' value={contractType} onChange={handleContractType}>
                                    <option value="regular">Normal</option>
                                    {(user?.role_id === 1 || user?.role_id === 6) ? (<option value="courtesy">Cortesía</option>) : (null)}
                                </Input>
                                <FormText>
                                    Tipo de Contrato (*)
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>

                </CardBody>
            </Card>

            {contractType === "regular" ? (<RegularContractForm user={user} />) : (null)}
            {contractType === "courtesy" ? (<CourtesyContractForm user={user} />) : (null)}

        </Container>
    )
}

export default ContractManager