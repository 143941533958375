import { MDBNavbarNav, MDBNavbarItem, MDBNavbarLink } from 'mdb-react-ui-kit'
import React from 'react'
import navOptions, { navIcons } from '../narvbar-options/NavbarOptions'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { GiEarthAmerica } from 'react-icons/gi'
import { TbWorldPlus } from 'react-icons/tb'


interface Props {
    handleLogout: () => void
}


const ComercialAdminNavbar: React.FC<Props> = ({ handleLogout }) => {
    return (
        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 fw-bold'>
            <NavItem>
                <NavLink tag={Link} to="/home" style={{ textDecoration: 'none', color: 'black' }}>
                    <i className={navIcons.home}></i>{navOptions.home}
                </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <GiEarthAmerica /> {navOptions.leads}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/marketing/leads/myAssignments" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                            <TbWorldPlus fontSize="18px" className='pr-3' /> {navOptions.myLeadsAssignment}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.mainPVO}></i> {navOptions.mainPVO}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem >
                        <Link to="/pbo/manager" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.registerPVO}></i> {navOptions.registerPVO}
                        </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem >
                        <Link to="/pbo/report/personal" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.personalPBOReport}></i> {navOptions.personalPBOReport}
                        </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem >
                        <Link to="/pbo/report/admin" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.allPVO}></i> {navOptions.allPVO}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.contract}></i> {navOptions.contract}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem >
                        <Link to="/contract/manager" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.newContract}></i> {navOptions.newContract}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem >
                        <Link to="/contract/mine" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.myContracts}></i>  {navOptions.myContracts}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem>
                        <Link to="/contract/list" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.allContracts}></i>   {navOptions.allContracts}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.verification}></i> {navOptions.verification}
                </DropdownToggle>
                <DropdownMenu end>

                    <DropdownItem>
                        <Link to="/verification/report/admin" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.reportVerification}></i> {navOptions.reportVerification}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className={navIcons.schedule}></i> {navOptions.schedule}
                </DropdownToggle>
                <DropdownMenu end>

                <DropdownItem>
                        <Link to="/schedule/list" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.allSchedule}></i>   {navOptions.allSchedule}
                        </Link>
                    </DropdownItem>

                    <DropdownItem divider />


                    <DropdownItem >
                        <Link to="/schedule/dailyReport" style={{ textDecoration: 'none', color: 'black' }}>
                            <i className={navIcons.scheduleReport}></i> {navOptions.scheduleReport}
                        </Link>
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>

            <MDBNavbarLink className='d-flex justify-content-between' onClick={handleLogout} style={{ cursor: 'pointer' }}><div><b>{navOptions.logout} <i className={navIcons.logout}></i> </b></div></MDBNavbarLink>

        </MDBNavbarNav>
    )
}

export default ComercialAdminNavbar