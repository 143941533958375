import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, Alert, Row, Col, Card, CardBody, Table, Button, ModalFooter } from 'reactstrap';
import { CARD_TITLE, CARD_BODY } from '../../../services/classService';
import { getBeneficiaryCanceledClassHistory } from '../../../controllers/ClassController';
import { genericErrorAlert } from '../../../services/alertService';
import { ClassBeneficiaryHistory } from '../../../interface/interface';
import { dateformatISO } from '../../../utils/dateUtils';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    beneficiaryId: string | number
}

const ScheduleCanceledClassHistory: React.FC<Props> = ({ isOpen, toggle, beneficiaryId }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [beneficiaryClassHistory, setBeneficiaryClassHistory] = useState<Array<ClassBeneficiaryHistory>>([]);


    useEffect(() => {
        loadBeneficiaryCanceledHistory();
    }, [])

    const loadBeneficiaryCanceledHistory = async () => {

        try {
            setIsLoading(true);

            const beneficiaryClassHistory = await getBeneficiaryCanceledClassHistory(beneficiaryId);
            setBeneficiaryClassHistory(beneficiaryClassHistory.data)
            console.log(beneficiaryClassHistory.data)

        } catch (error) {

            genericErrorAlert("Error al cargar historial de cancelaciones", "Intentalo nuevamente")
            toggle();

        } finally {
            setIsLoading(false);
        }
    }


    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "1200px" }}>
            <ModalHeader className={CARD_TITLE + " contract-backgroud "} toggle={toggle}>
                <Row className='d-flex'>
                    <Col>
                        <b>HISTORIAL DE CANCELACIONES</b>
                    </Col>
                </Row>
            </ModalHeader>

            <ModalBody className={CARD_BODY} >
                {isLoading ? (<ModalBody><Alert color="warning" className={" text-center m-3"}>
                    <b className="h2">Cargando historial de clases...</b>
                </Alert></ModalBody>) : (<div> {beneficiaryClassHistory.length === 0 ? (<Alert color="warning" className="text-center m-3">
                    <b className="h2">No existe historial de cancelaciones para este beneficiario</b>
                </Alert>) : (<Row>

                    <Col className='col-12'>

                        <CardBody className='text-white'>

                            <Card>
                                <Row>
                                    <Col>
                                        <Table striped responsive className='text-center m-1' hover>

                                            <thead>
                                                <tr>

                                                    <th className='text-center'>#</th>
                                                    <th className='text-center'>Responsable</th>
                                                    <th className='text-center'>Fecha</th>
                                                    <th className='text-center'>Hora</th>
                                                    <th className='text-center'>Modalidad</th>
                                                    <th className='text-center'>Beneficiario</th>
                                                    <th className='text-center'>Profesor</th>
                                                    <th className='text-center'>Salón</th>
                                                    <th className='text-center'>Lección</th>
                                                    <th className='text-center'>Vivencial</th>
                                                </tr>
                                            </thead>

                                            <tbody>

                                                {beneficiaryClassHistory.map((history, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <p><b>{index + 1}</b></p>
                                                        </td>
                                                        <td>
                                                            <p>{history.responsible_first_name + " " + history.responsible_last_name}</p>
                                                        </td>
                                                        <td>
                                                            <p>{dateformatISO(history.class_date)}</p>
                                                        </td>
                                                        <td>
                                                            <p>{history.class_time}</p>
                                                        </td>
                                                        <td>
                                                            <p>{history.modality}</p>
                                                        </td>
                                                        <td>
                                                            <p>{history.beneficiary_first_name + " " + history.beneficiary_second_name + " " + history.beneficiary_last_name + " " + history.beneficiary_second_last_name}</p>
                                                        </td>

                                                        <td>
                                                            <p>{history.teacher_first_name + " " + history.teacher_last_name}</p>
                                                        </td>

                                                        <td>
                                                            <p>{history.classroom_name}</p>
                                                        </td>
                                                        <td>
                                                            <p>{history.lesson_name}</p>
                                                        </td>

                                                        <td>
                                                            <p>{history.repetition ? (history.repetition) : ("Sin Registros")}</p>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card>
                        </CardBody>
                    </Col>
                </Row>)}</div>)}
            </ModalBody>

            <ModalFooter className={CARD_TITLE + " text-white"}>

                <Button color='secondary' className='col-12' onClick={toggle}>
                    <b>Cerrar</b>
                </Button>
            </ModalFooter>

        </Modal>
    )
}

export default ScheduleCanceledClassHistory
