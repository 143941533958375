import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { getAttendanceCodeClass } from '../../../controllers/ClassController';
import { handleErrorResponseAlert } from '../../../services/alertService';
import { Button } from 'reactstrap';
import LoadingStatus from '../../utils/LoadingStatus';

interface Props {
    classId: number
}

const LoadAttendanceCodeButton: React.FC<Props> = ({ classId }) => {

    const [currentCode, setCurrentConde] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const loadClassAttendanceCode = async (classId: number) => {

        try {

            setLoading(true);

            const code = await getAttendanceCodeClass(classId);
            setCurrentConde(code.data);

            Swal.fire({
                title: `Código de clase obtenido`,
                html:
                    `El código de clase es <b>${code.data}</b> , recuerda que debe ser suministrado al docente para que pueda marcar asistencia.`,
                icon: 'warning',
                confirmButtonText: 'Cerrar',
            })

            setCurrentConde("");

        } catch (error) {

            handleErrorResponseAlert(error, "Error al el codigo de asistencia", "Intentelo nuevamente");

        } finally {
            setLoading(false)
        }

    } 

    return (

        <Button color='primary' onClick={() => loadClassAttendanceCode(classId)}>
            <LoadingStatus status={loading} text='Cargar código' loadingText='Cargando código'></LoadingStatus>
        </Button>
    )
}

export default LoadAttendanceCodeButton