import { throws } from "assert";
import { verifyToken } from "../controllers/AuthController"
import { genericErrorAlert, sessionEndedAlert } from "./alertService";

export const validateToken = async (test="None") => {

    try {
        const response = await verifyToken()

        return true
        
    } catch (error:any) {

        document.cookie = "nbp-management-auth=; max-age=0";
        sessionEndedAlert(test);
    
        setTimeout(() => {
            window.location.href = '/';
        }, 2000)

        return false
    }

}