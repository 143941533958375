import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, FormGroup, Input, FormText, ModalFooter, Button, Alert, Row, Col, Card, CardBody, CardHeader, CardTitle, InputGroup, InputGroupText, Table, Label } from 'reactstrap';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import { IRegisterValues, User } from '../../../interface/interface';
import { genericErrorAlert, genericSuccessAlert, handleErrorResponseAlert } from '../../../services/alertService';
import { resetPassword } from '../../../controllers/AuthController';
import Swal from 'sweetalert2';
import PasswordInputs from '../../auth/cutom-inputs/PasswordInputs';
import { dateFormatDDMMYYY, dateformatISO } from '../../../utils/dateUtils';
import { getUserDetailsByDocument, updateUserInformation } from '../../../controllers/UserController';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  user: User;
  userList: Array<User>;
  setUserList: (userList: Array<User>) => void;
}

const EditUserModal: React.FC<Props> = ({ isOpen, toggle, user, userList, setUserList }) => {

  const [selectedUser, setSelectedUser] = useState<User>(user);
  const [updating, setUpdating] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {

    loadUser();

  }, []);

  const updatedUserVisualValues = () => {

    const updatedProspectIndex = userList.findIndex((updatedProspect) => updatedProspect.document_number === user.document_number && updatedProspect.document_type === user.document_type);

    if (updatedProspectIndex === -1) {
      return userList;
    }

    const updatedProspect = [...userList];
    updatedProspect.splice(updatedProspectIndex, 1, selectedUser);

    setUserList(updatedProspect);

  }

  const loadUser = async () => {

    setIsLoading(true);

    try {

      const userValues = await getUserDetailsByDocument(user.document_type, user.document_number);
      setSelectedUser(userValues.data[0]);

    } catch (error) {

      console.log("Error al cargar información del usuario", error);
      genericErrorAlert("Error al cargar información de usuario", "Intentelo nuevamente");
      toggle();

    } finally {
      setIsLoading(false);
    }

  }

  const handleInputChangeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedUser({
      ...selectedUser, [name]: value
    });
  };

  const handleInputChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setSelectedUser({ ...selectedUser, [name]: !selectedUser.is_active });
  };

  const defaultRegisterValues: IRegisterValues = {
    document_number: "",
    document_type: "CC",
    document_number_confirm: "",
    document_type_confirm: "CC",
    role: "",
    gender: "Masculino",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    birthdate: "",
    phone_number: "",
    address: "",
    email: "",
    password: "",
    confirmPassword: ""

  }

  const [registerValues, setRegisterValues] = useState<IRegisterValues>(defaultRegisterValues);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRegisterValues({
      ...registerValues, [name]: value
    });
  };

  function isSecurePassword(password: string) {

    // Verificar si la contraseña tiene al menos 8 caracteres de longitud
    if (password.length < 8) {
      return false;
    }

    // Verificar si la contraseña contiene al menos una letra mayúscula
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // Verificar si la contraseña contiene al menos una letra minúscula
    if (!/[a-z]/.test(password)) {
      return false;
    }

    // Verificar si la contraseña contiene al menos un número
    if (!/\d/.test(password)) {
      return false;
    }

    // Verificar si la contraseña contiene al menos un carácter especial
    if (!/[\!@#\$%\^&\*\(\)_\+~`\|{\[\}:;<>?,./\-="]/.test(password)) {
      return false;
    }

    // La contraseña cumple con todos los criterios de seguridad
    return true;
  }

  const verifyUserValues = () => {

    const noCompleteInputs = [];

    if (selectedUser.document_number.trim() === "") {
      noCompleteInputs.push("Número de Identificación");
    }

    if (selectedUser.document_type.trim() === "") {
      noCompleteInputs.push("Tipo de Identificación");
    }

    if (selectedUser.first_name && selectedUser.first_name.trim() === "") {
      noCompleteInputs.push("Primer nombre");
    }

    if (selectedUser.second_name && selectedUser.second_name.trim() === "") {
      noCompleteInputs.push("Primer nombre");
    }

    if (selectedUser.last_name && selectedUser.last_name.trim() === "") {
      noCompleteInputs.push("Primer apellido");
    }

    if (selectedUser.second_last_name && selectedUser.second_last_name.trim() === "") {
      noCompleteInputs.push("Segundo apellido");
    }

    if (selectedUser.phone_number && selectedUser.phone_number.trim() === "") {
      noCompleteInputs.push("Número de telefono");
    }

    if (selectedUser.email && selectedUser.email.trim() === "") {
      noCompleteInputs.push("Dirección de residenciao");
    }

    if (selectedUser.address && selectedUser.address.trim() === "") {
      noCompleteInputs.push("Dirección de residencia");
    }

    if (noCompleteInputs.length > 0) {
      completeAllInputsAlert("La actualización de datos", noCompleteInputs.join(" - "))
    }

    if (noCompleteInputs.length === 0) {
      return true
    }

    return false;
  }

  const completeAllInputsAlert = (title: string, information: string) => {
    title = "<b>" + title + "</b>"
    Swal.fire({
      icon: 'error',
      title: 'Completa todos los campos para ' + title,
      text: information,
    })
  }


  const handleResetPassword = async () => {

    if (registerValues.password.trim() !== registerValues.confirmPassword.trim()) {
      genericErrorAlert("Las contraseñas son diferentes", "Escriba dos contraseñas iguales")
      return;
    }


    if (!isSecurePassword(registerValues.password) || !isSecurePassword(registerValues.confirmPassword)) {

      Swal.fire({
        title: '<strong> Contraseña no segura </strong>',
        icon: 'error',
        html:
          `<p>Por favor, asegúrate de que tu contraseña cumpla con estos criterios:</p>
                <ul>
                  <li>Al menos 8 caracteres de longitud.</li>
                  <li>Una letra mayúscula.</li>
                  <li>Una letra minúscula.</li>
                  <li>Un número.</li>
                  <li>Un carácter especial (!@#$%^&*).</li>
                </ul>`,
        showCloseButton: true,

        focusConfirm: false,

        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })
      return;
    }

    setUpdatingPassword(true);


    Swal.fire({
      title: '¿Esta seguro de cambiar al contraseña?',
      text: "La contraseña del usuario sera modicada.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      try {

        if (result.isConfirmed) {

          const resetPasswordResponse = await resetPassword(user.document_type, user.document_number, registerValues.password)
          const { title, message } = resetPasswordResponse.data;

          Swal.fire(
            title,
            message,
            'success'
          )
          toggle();
        }
      } catch (error: any) {
        
        handleErrorResponseAlert(error, "Error al actualizar contraseña", "Intentelo nuevamente");
        console.log(error + "")

      } finally {
        setUpdatingPassword(false);
      }

    })
  }

  const handleDenyCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
  };

  const handleUpdateUserValues = async (event: React.FormEvent) => {

    event.preventDefault();

    if (!verifyUserValues()) {
      return
    }

    setUpdating(true);

    try {

      await updateUserInformation(selectedUser);
      updatedUserVisualValues();

    } catch (error: any) {

      handleErrorResponseAlert(error, "Error al actualizar información del usuario", "Intentelo nuevamente");

    } finally {

      setUpdating(false);

    }

  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "1000px" }}>

      <ModalHeader tag="h4" className={CARD_TITLE} toggle={toggle}><b>EDITAR USUARIO</b></ModalHeader>

      <ModalBody className={CARD_BODY + "contract-backgroud"} >

        {isLoading ? (< Alert color="warning" className="text-center m-3">
          <b className="h2">Cargando detalles del usuario...</b>
        </Alert>) : (<Row>
          <Col>

            <Row>

              <Col className='col-12'>

                <form onSubmit={handleUpdateUserValues}>

                  <Row>
                    <Col className='p-2'>
                      {selectedUser.is_active ? (<span className={'bg-lime p-2 col-12 custom-pill'}>Activo</span>) : (<span className={'bg-danger p-2 col-12 custom-pill '}>Desactivado</span>)}
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Input name="is_active" type='select' value={selectedUser.is_active ? "true" : "false"} onChange={handleInputChangeStatus}>
                          <option value="true">Activo</option>
                          <option value="false">Desactivado</option>
                        </Input>
                        <FormText>
                          Estado de usuario (*)
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Input type="text" name='document_number'
                          value={user.document_number}
                          readOnly
                        />
                        <FormText>
                          Número de Documento
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Input type="text" name='document_type'
                          value={user.document_type}
                          readOnly
                        />
                        <FormText>
                          Tipo de Documento
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}> <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                      Información Personal
                    </CardTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Input name='first_name'
                          value={selectedUser.first_name}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                          required />
                        <FormText>
                          Primer Nombre (*)
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Input type="text" name='second_name'
                          value={selectedUser.second_name}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                        />
                        <FormText>
                          Segundo Nombre
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Input type="text" name='last_name'
                          value={selectedUser.last_name}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                          required />
                        <FormText>
                          Primer Apellido (*)
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Input type="text" name='second_last_name'
                          value={selectedUser.second_last_name}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                          required />
                        <FormText>
                          Segundo Apellido (*)
                        </FormText>
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row>

                    <Col md={12} lg={6}>
                      <FormGroup>
                        <Input type="date" name='birthdate'
                          value={dateformatISO(selectedUser.birthdate as string)}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                          required />
                        <FormText>
                          Fecha de nacimiento (*)
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                      <FormGroup>
                        <Input type="select" name="gender" value={selectedUser.gender} onChange={handleInputChangeUser}>
                          <option value="Masculino">Masculino</option>
                          <option value="Femenino">Femenino</option>
                          <option value="No Binario">No Binario</option>
                          <option value="Otro">Otro</option>
                        </Input>
                        <FormText>
                          Género (*)
                        </FormText>
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row>
                    <Col lg={12}> <CardTitle className='underline text-white mb-2' tag="h5" lg={12}>
                      Información de contacto
                    </CardTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={6}>
                      <FormGroup>
                        <Input type="text" name='phone_number'
                          value={selectedUser.phone_number}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                          required />
                        <FormText>
                          Número de teléfono (*)
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                      <FormGroup>
                        <Input type="email" name='email'
                          value={selectedUser.email}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                          required />
                        <FormText>
                          Correo Electronico (*)
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col md={12} lg={6}>
                      <FormGroup>
                        <Input type="text" name='address'
                          value={selectedUser.address}
                          onChange={handleInputChangeUser}
                          autoComplete='false'
                          required />
                        <FormText>
                          Dirección de residencia (*)
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button type="submit" color='success' className='col-12' disabled={updating}><b>{!updating ? ("Actualizar Información") : ("Actualizando Información")}</b></Button>
                    </Col>
                  </Row>

                </form>

                <hr />

                <Row style={{ minWidth: '100px' }}>

                  <PasswordInputs handleDenyCopyPaste={handleDenyCopyPaste} registerValues={registerValues} handleInputChange={handleInputChange} />

                  <Col>
                    <Button type="button" color='warning' className='col-12' onClick={() => handleResetPassword()} disabled={updatingPassword}><b>{!updatingPassword ? ("Cambiar Contraseña") : ("Cambiando contraseña")}</b></Button>
                  </Col>

                </Row>
              </Col>

            </Row>
          </Col>
        </Row>
        )}
      </ModalBody>

      <ModalFooter className={CARD_TITLE + " text-white"}>
        <Button color='secondary' onClick={toggle}>
          <b>Cerrar</b>
        </Button>
      </ModalFooter>

    </Modal>
  );
}

export default EditUserModal