import { Prospect, ProspectMeeting } from '../interface/interface';
import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';

export const createProspect = async (lead_id: number | null, prospect: Prospect, prospect_meeting: ProspectMeeting, has_meeting: boolean, observation: string) => {
    return await axiosInstance.post(mainRoute + 'prospect/createProspect', { lead_id: lead_id, prospect: prospect, prospect_meeting: prospect_meeting, has_meeting: has_meeting, observation: observation })
}

export const getPersonalPBOList = async () => {
    return await axiosInstance.get(mainRoute + 'prospect/getPersonalPBOList')
}

export const getAllPBOList = async () => {
    return await axiosInstance.get(mainRoute + 'prospect/getAllPBOList');
}

export const updateProspectStatusComercial = async (prospect: Prospect, new_status: string, comercial_document_type: string, comercial_document_number: string) => {
    return await axiosInstance.put(mainRoute + 'prospect/updateProspectStatusComercial', { prospect: prospect, new_status: new_status, comercial_document_type: comercial_document_type, comercial_document_number: comercial_document_number })
}

export const updateProspectStatusAdmin = async (prospect: Prospect, new_status: string) => {
    return await axiosInstance.put(mainRoute + 'prospect/updateProspectStatusAdmin', { prospect: prospect, new_status: new_status })
}

export const getFilterOptions = async () => {
    return await axiosInstance.get(mainRoute + 'prospect/getFilterOptions');
}

export const getProspectByStatus = async (status: string) => {
    return await axiosInstance.get(mainRoute + 'prospect/getProspectByStatus/' + status);
}
export const getProspectByOrigin = async (origin: string) => {
    return await axiosInstance.get(mainRoute + 'prospect/getProspectByOrigin/' + origin);
}
export const getProspectByProfession = async (profession: string) => {
    return await axiosInstance.get(mainRoute + 'prospect/getProspectByProfession/' + profession);
}

export const getProspectByWeakness = async (writing: boolean, reading: boolean, speaking: boolean, listening: boolean) => {
    return await axiosInstance.get(mainRoute + 'prospect/getProspectByWeakness/' + writing + '/' + reading + '/' + speaking + '/' + listening);
}

export const getProspectByOneWeakness = async (weakness: string) => {
    return await axiosInstance.get(mainRoute + 'prospect/getProspectByOneWeakness/' + weakness);
}

export const getProspectByUser = async (document_type: string, document_number: string) => {
    return await axiosInstance.get(mainRoute + 'prospect/getProspectByUser/' + document_type + '/' + document_number);
}



