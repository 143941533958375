import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, Row } from 'reactstrap'
import { HolderWork } from '../../../../interface/interface'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import VerificationFormGroupText from '../verification-form-inputs/VerificationFormGroupText'

interface Props {
  holderWork: HolderWork
  setHolderWork: (holderWork: HolderWork) => void

}

const VerificationHolderWorkSection: React.FC<Props> = ({ holderWork, setHolderWork }) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setHolderWork({
      ...holderWork, [name]: value
    });
  };

  return (
    <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
      <CardHeader tag="h4" className={CARD_TITLE} >
        <b>Información Laboral del Titular</b>
      </CardHeader>
      <CardBody>
        <Row>

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='name'
            value={holderWork.name}
            onChange={handleInputChange}
            formText='Nombre de la empresa'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='position'
            value={holderWork.position}
            onChange={handleInputChange}
            formText=' Cargo'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='phone_number'
            value={holderWork.phone_number}
            onChange={handleInputChange}
            formText='Número Teléfonico de la Empresa'
            required
          />

          <VerificationFormGroupText
            type='text'
            md={12} lg={12}
            name='address'
            value={holderWork.address}
            onChange={handleInputChange}
            formText='Dirección de la empresa'
            required
          />
          
        </Row>
      </CardBody>
    </Card>
  )
}

export default VerificationHolderWorkSection
