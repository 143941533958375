import React, { useState, useEffect } from 'react'
import { genericErrorAlert } from '../../../services/alertService';
import { Modal, ModalHeader, Row, Col, ModalBody, Alert, CardBody, Card, Table, ModalFooter, Button, FormGroup, FormText, Input } from 'reactstrap';
import { CARD_TITLE, CARD_BODY } from '../../../services/classService';
import { dateformatISO } from '../../../utils/dateUtils';
import { Beneficiary } from '../../../interface/interface';
import { getBeneficiaryContactInformation } from '../../../controllers/BeneficiaryController';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    beneficiaryId: string
}

const ScheduleBeneficiaryDetailsModal: React.FC<Props> = ({ isOpen, toggle, beneficiaryId }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [beneficiary, setBeneficiary] = useState<Beneficiary | null>(null)

    useEffect(() => {
        loadBeneficiaryDetails();
    }, [])

    const loadBeneficiaryDetails = async () => {

        try {

            const details = await getBeneficiaryContactInformation(beneficiaryId);
            setBeneficiary(details.data[0])

        } catch (error: any) {

            genericErrorAlert("Error al cargar los datalles del beneficiario", "Intentalo nuevamente")
            toggle();

        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "1200px" }}>

            <ModalHeader className={CARD_TITLE + " contract-backgroud "} toggle={toggle}>
                <Row className='d-flex'>
                    <Col>
                        <b>DETALLES DE CONTACTO DEL BENEFICIARIO</b>
                    </Col>
                </Row>
            </ModalHeader>

            <ModalBody className={CARD_BODY} >
                {isLoading ? (<ModalBody><Alert color="warning" className={" text-center m-3"}>
                    <b className="h2">Cargando detalles del beneficiario... </b>
                </Alert></ModalBody>) : (<Row>

                    <Col className='col-12'>

                        <Row>
                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input type="text" name="phone_number"
                                        value={beneficiary?.phone_number}
                                        readOnly />
                                    <FormText>
                                        Número de WhatsApp
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='whatsapp_number'
                                        value={beneficiary?.whatsapp_number}
                                        readOnly />
                                    <FormText>
                                        Número de WhatsApp
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <Input type="email" name="email"
                                        value={beneficiary?.email}
                                        readOnly />
                                    <FormText>
                                        Correo Electrónico
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Col>
                </Row>)}
            </ModalBody>

            <ModalFooter className={CARD_TITLE + " text-white"}>

                <Button color='secondary' className='col-12' onClick={toggle}>

                    <b>Cerrar</b>

                </Button>

            </ModalFooter>
        </Modal>
    )
}

export default ScheduleBeneficiaryDetailsModal
