
import { User } from '../interface/interface';
import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';

export const existUser = async (document_number: string, document_type: string) => {

    const existUser = await axiosInstance.post(mainRoute + 'existUser', { document_number: document_number, document_type: document_type })

    return existUser;
}

export const getAllUsers = async () => {

    const userList = await axiosInstance.get(mainRoute + 'user/getAllUsers');
    return userList;

}

export const getUserDetailsByDocument = async (document_type: string, document_number: string) => {

    const userDetails = await axiosInstance.get(mainRoute + 'user/getUserDetailsByDocument/' + document_type + '/' + document_number);
    return userDetails;

}

export const updateUserInformation = async (user: User) => {
    return await axiosInstance.put(mainRoute + 'user/updateUserInformation', {user});
}


