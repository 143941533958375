import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, Row, Col, Alert, Button, Collapse, FormGroup, FormText, Input } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'
import { useNavigate } from 'react-router-dom';
import { getCookieValue } from '../../../services/cookieService';
import { validateToken } from '../../../services/authService';
import '../../..//styles/scheduleStyles.css'

import { Calendar, DateLocalizer, momentLocalizer, Messages } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/es';
import { getAllClass } from '../../../controllers/ClassController';
import ScheduleModal from '../schedule-modal/ScheduleModal';
import { CustomMessages } from '../../../interface/interface';
import ManualCloseAlert from '../../alerts/ManualCloseAlert';
import { isNextDay, isToday } from '../../../utils/dateUtils';
import { genericErrorAlert } from '../../../services/alertService';

const AdminSchedule = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [classList, setClassList] = useState<Array<any>>([]);

  const [modalOpenState, setModalOpenState] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState({ classId: "" });
  const [viewScheduleDetails, setViewScheduleDetails] = useState<boolean>(false);
  const [nextDayClassAmount, setNextDayClassAmount] = useState(0);
  const [todayClassAmount, setTodayClassAmount] = useState(0);


  const toggleModal = async () => {

    setModalOpenState(!modalOpenState);

  };

  const messages: CustomMessages = {
    allDay: 'Todo el día',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay enventos programados para esta fecha',
    showMore: total => `+${total} more`,
  };

  const navigate = useNavigate();

  const localizer = momentLocalizer(moment);
  useEffect(() => {

    loadClass();
    
  }, [])

  const loadClass = async () => {

    try {

      const classList = await getAllClass();
      setClassList(classListFormat(classList.data.class))

    } catch (error: any) {

      const { title, message } = error.response.data;

      genericErrorAlert(title, message);

    } finally {
      setIsLoading(false)
    }
  }

  const classListFormat = (classList: Array<any>) => {

    const reformedClassList: React.SetStateAction<any[]> = []

    const nextClass = []
    const todayClass = []

    classList.forEach(currentClass => {
      const jsonDate = currentClass.date;
      const jsonHour = currentClass.hour;

      // Concatenar la fecha y la hora en un formato compatible con JavaScript
      const dateTimeString = `${jsonDate.split('T')[0]}T${jsonHour}`;

      const date = new Date(dateTimeString);

      if (isNextDay(date)) {
        nextClass.push(date)
      }

      if (isToday(date)) {
        todayClass.push(date)
      }

      date.setHours(date.getHours() + 1);
      reformedClassList.push({ start: convertToNewDate(dateTimeString), end: date, title: currentClass.lesson, class_id: currentClass.class_id });
    });

    setNextDayClassAmount(nextClass.length)
    setTodayClassAmount(todayClass.length);
    return reformedClassList;

  }

  function convertToNewDate(dateTimeString: string) {
    // Obtener los valores de fecha y hora de la cadena
    const year = parseInt(dateTimeString.substring(0, 4));
    const month = parseInt(dateTimeString.substring(5, 7)) - 1;
    const day = parseInt(dateTimeString.substring(8, 10));
    const hour = parseInt(dateTimeString.substring(11, 13));
    const minute = parseInt(dateTimeString.substring(14, 16));

    // Crear el objeto Date
    const newDate = new Date(year, month, day, hour, minute);

    return newDate;
  }

  const min = new Date();
  min.setHours(6, 0, 0, 0);

  const max = new Date();
  max.setHours(22, 0, 0, 0);

  const handleEventClick = (event: any) => {
    setModalValues({ classId: event.class_id })
    toggleModal();
  };

  const CustomEvent = ({ event }: any) => {
    return (
      <div onClick={() => handleEventClick(event)}>
        <Row className='col-12'>
          <Col className='col-12 mt'>
            <b>Clase N°:</b> {event.class_id}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Card className={CARD_BODY + ' m-3 contract-backgroud'}>
      <CardHeader tag="h4" className={CARD_TITLE + ' text-center'}>
        <b>LISTADO DE CITAS</b>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Button color='danger' className='col-12 mb-3' onClick={() => setViewScheduleDetails(!viewScheduleDetails)}>
              <b> Detalles</b> {!viewScheduleDetails ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>}
            </Button>
          </Col>
          <Collapse isOpen={viewScheduleDetails}>
            <Row>

              <Col >
                <FormGroup className="form-outline mb-4">
                  <Input type="text" name="document_type" value={classList.length} readOnly />
                  <FormText>
                    Total de clases agendadas
                  </FormText>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="form-outline mb-4">
                  <Input type="text" name="document_type" value={todayClassAmount} readOnly />
                  <FormText>
                    Clases para hoy
                  </FormText>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="form-outline mb-4">
                  <Input type="text" name="document_type" value={nextDayClassAmount} readOnly />
                  <FormText>
                    Clases para mañana
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
        </Row>
        <Row >
          {
            isLoading ? (
              <Col><Alert color="warning" className="text-center m-1">
                <b className="h2">Cargando clases...</b>
              </Alert></Col>) :
              <Col >
                <Calendar
                  style={{ minHeight: 800, width: '100%' }}
                  defaultView="month"
                  className='p-3 rounded h'
                  localizer={localizer}
                  events={classList}
                  min={min}
                  max={max}
                  components={{
                    event: CustomEvent,
                  }}
                  messages={messages}
                />
              </Col>

          }


        </Row>
      </CardBody>

      {modalOpenState ? <ScheduleModal classId={modalValues.classId} isOpen={modalOpenState} toggle={toggleModal} isAdmin/> : null}

    </Card>
  );
};

export default AdminSchedule


