import React, { useState } from 'react'
import { IObservationPBO } from '../../../../interface/interface'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, FormText, Input, Collapse, Button} from 'reactstrap';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import { dateformatISO } from '../../../../utils/dateUtils';
import Swal from 'sweetalert2';
import { genericSuccessAlert, genericErrorAlert } from '../../../../services/alertService';
import { addProspectObservation } from '../../../../controllers/ProspectObservationController';

interface Props {
    observationList: Array<IObservationPBO>;
    prospectId: string;
    loadProspectObservation:()=>void
}

const ObservationPBO: React.FC<Props> = ({ observationList, prospectId,loadProspectObservation }) => {

    const [visibleAddObservation, setVisibleAddObservation] = useState(false);
    const [observation, setObservation] = useState("");
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setObservation(value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        Swal.fire({
            title: 'Confirmación de solicitud',
            text: "¿Esta seguro de agregar la observación?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Agregar',
            cancelButtonText: 'Cancelar',

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setIsSaving(true);

                    //Agregar aqui la funcion de agregar observación
                    await addProspectObservation(prospectId, observation);
                    await loadProspectObservation();
                    genericSuccessAlert("Solicitud realizada con exito", "Observación agregada con exito");


                } catch (error) {

                    genericErrorAlert("Error al agregar observación", "Intentelo nuevamente");

                } finally {

                    setIsSaving(false);
                }
            }
        })


    }

    return (
        <Card className={CARD_BODY + ' contract-backgroud m-3'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>OBSERVACIONES</b>
            </CardHeader>

            <CardBody>

                <Row className='px-3'>
                    <Col>
                        <Button color='danger' className='bg col-12 mb-3' onClick={() => setVisibleAddObservation(!visibleAddObservation)}>
                            <b> Agregar Observaciones </b> {!visibleAddObservation ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>}
                        </Button>

                        <Collapse isOpen={visibleAddObservation}>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Input type="textarea" name='observation'
                                                value={observation}
                                                onChange={handleInputChange}
                                                required />
                                            <FormText>
                                                Observación (*)
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={12} >
                                        <Button className='btn col-12' color='success' type='submit' disabled={isSaving}><b>{isSaving ? ("Guardando observación...") : ("Guardar observación")}</b></Button>
                                    </Col>
                                </Row>
                            </form>
                            <hr />
                        </Collapse>
                    </Col>
                </Row>

                <Row className='px-3'>
                    {observationList.map((observation, index) =>

                        <Col md={6} lg={6} key={index}>

                            <Row>
                                <Col >
                                    <FormGroup >
                                        <Input type="textarea" name='last_name'
                                            value={observation.observation}
                                            readOnly
                                        />
                                        <FormText>
                                            <Row>
                                                <Col lg={10}>
                                                    {observation.responsable_first_name + " " + observation.responsable_second_name + " " + observation.responsable_last_name + " " + observation.responsable_second_last_name + " - (" + observation.responsible_document_type + ") - " + observation.responsible_document_number}

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='text-left'>
                                                    {("(" + dateformatISO(observation.created_at)) + ")"}
                                                </Col>
                                            </Row>
                                        </FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>

            </CardBody>

        </Card>
    )
}

export default ObservationPBO
