import { useNavigate } from 'react-router-dom';
import PBOForm from '../pbo-form/pbo-form-main/PBOForm'

const PBOManager = () => {

  return (
    <PBOForm/>
  )
  
}

export default PBOManager