import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import ContractManager from '../components/contract/contract-manager/ContractManager';
import ContractOptionsManager from '../components/contract/contract-options/options-manager/ContractOptionsManager';
import AdminContract from '../components/contract/contract-report/AdminContract';
import PersonalContract from '../components/contract/contract-report/PersonalContract';
import Home from '../components/home/Home';
import NavbarManager from '../components/navbar/navbar-manager/NavbarManager';
import PBOManager from '../components/pbo/pbo-manager/PBOManager';
import AdminPBO from '../components/pbo/pbo-report/report-type/AdminPBO';
import PersonalPBO from '../components/pbo/pbo-report/report-type/PersonalPBO';
import ScheduleForm from '../components/schedule/schedule-form/schedule-form-main/ScheduleForm';
import AdminSchedule from '../components/schedule/schedule-report/AdminSchedule';
import DailyScheduleReport from '../components/schedule/schedule-report/DailyScheduleReport';
import PersonalSchedule from '../components/schedule/schedule-report/PersonalSchedule';
import UserList from '../components/user/user-report/UserList';
import VerificationManager from '../components/verification/verification-manager/VerificationManager';
import PersonalVerificationReport from '../components/verification/verification-report/verification-report-type/PersonalVerificationReport';
import { User } from '../interface/interface';
import ProtectedRoute from './ProtectedRoute';
import AdminVerificationReport from '../components/verification/verification-report/verification-report-type/AdminVerificationReport';
import LeadsManager from '../components/leads/leads-manager/LeadsManager';
import LeadsNormalReport from '../components/leads/leads-report/leads-report-type/leads-normal/LeadsNormalReport';
import LeadsComercialAssignmentReport from '../components/leads/leads-report/leads-report-type/leads-comercial-assignment/LeadsComercialAssignmentReport';
import AttendanceManager from '../components/attendance/attendance-manager/AttendanceManager';
import EmailManager from '../components/email/EmailManager';

interface Props {
    token: string | null;
    setToken: (token: string | null) => void;
    user: User;
    setUser: (user: User | null) => void;
}

const RouterManager: React.FC<Props> = ({ token, setToken, user, setUser }) => {

    return (
        <Router>

            <NavbarManager user={user as User} token={token} ></NavbarManager>

            <Routes>

                {/* Cualquier rota que no exista */}
                <Route path="*" element={<Login setToken={setToken} setUser={setUser} />}
                />

                {/* Inicio de sesión */}
                <Route path="login" element={<Login setToken={setToken} setUser={setUser} />} />


                {/*           Rutas con protección            */}

                {/* Inicio */}
                <Route path="/home" element={<ProtectedRoute allowedRole={["ACCESS", "RECEPTION", "COMERCIAL", 'COMERCIAL_ADMIN', "TEACHER", "VERIFICATION", "MARKETING"]} />}>
                    <Route path="/home" element={<Home token={token} setToken={setToken} user={user} setUser={setUser} />}></Route>
                </Route>

                {/* Creación de contratos */}

                {/* <Route path="/contract/create" element={<ProtectedRoute allowedRole={["COMERCIAL", 'COMERCIAL_ADMIN']} />}>
                    <Route path="/contract/create" element={<ContractForm user={user} />}></Route>
                </Route>}


                {/* Contratos registrados por usuario */}
                <Route path="/contract/mine" element={<ProtectedRoute allowedRole={["COMERCIAL", 'COMERCIAL_ADMIN']} />}>
                    <Route path="/contract/mine" element={<PersonalContract token={token} setToken={setToken} user={user} setUser={setUser} />}></Route>
                </Route>

                {/* Realizar modificaciones a opciones de contrato */}
                <Route path="/contract/options" element={<ProtectedRoute allowedRole={[]} />}>
                    <Route path="/contract/options" element={<ContractOptionsManager />}></Route>
                </Route>

                {/* Registrar nuevo usuario */}
                <Route path="/user/register" element={<ProtectedRoute allowedRole={[]} />}>
                    <Route path="/user/register" element={<Register />}></Route>
                </Route>

                {/* Lista de usuarios Registrados */}
                <Route path="/user/list" element={<ProtectedRoute allowedRole={[]} />}>
                    <Route path="/user/list" element={<UserList />}></Route>
                </Route>

                {/* Todos los contratos registrados */}
                <Route path="/contract/list" element={<ProtectedRoute allowedRole={["COMERCIAL_ADMIN"]} />}>
                    <Route path="/contract/list" element={<AdminContract token={token} setToken={setToken} user={user} setUser={setUser} />}></Route>
                </Route>

                {/*               Schedule                 */}

                {/* Crear agendamiento de clase,inducción, etc */}
                <Route path="/schedule/create" element={<ProtectedRoute allowedRole={["RECEPTION"]} />}>
                    <Route path="/schedule/create" element={<ScheduleForm />}></Route>
                </Route>

                {/* Lista de clases, inducciones, etc agendadas (Global)*/}

                <Route path="/schedule/list" element={<ProtectedRoute allowedRole={["RECEPTION", "COMERCIAL_ADMIN"]} />}>
                    <Route path="/schedule/list" element={<AdminSchedule />}></Route>
                </Route>

                {/* Vista de carga de clases, inducciones, etc (Carga academica) */}
                <Route path="/schedule/mine" element={<ProtectedRoute allowedRole={["TEACHER"]} />}>
                    <Route path="/schedule/mine" element={<PersonalSchedule user={user} />}></Route>
                </Route>

                {/* Vista de carga de clases agendadas */}
                <Route path="/schedule/dailyReport" element={<ProtectedRoute allowedRole={["TEACHER", "RECEPTION", "COMERCIAL_ADMIN"]} />}>
                    <Route path="/schedule/dailyReport" element={<DailyScheduleReport />}></Route>
                </Route>

                {/* PBO Reports */}

                <Route path="/pbo/report/personal" element={<ProtectedRoute allowedRole={["COMERCIAL", "COMERCIAL_ADMIN"]} />}>
                    <Route path="/pbo/report/personal" element={<PersonalPBO user={user} />}></Route>
                </Route>

                <Route path="/pbo/report/admin" element={<ProtectedRoute allowedRole={["COMERCIAL_ADMIN"]} />}>
                    <Route path="/pbo/report/admin" element={<AdminPBO user={user} />}></Route>
                </Route>


                {/* Verification */}

                <Route path="/verification/pending" element={<ProtectedRoute allowedRole={["VERIFICATION"]} />}>
                    <Route path="/verification/pending" element={<VerificationManager />}></Route>
                </Route>

                <Route path="/verification/report/admin" element={<ProtectedRoute allowedRole={["ADMIN", "COMERCIAL_ADMIN"]} />}>
                    <Route path="/verification/report/admin" element={< AdminVerificationReport />}></Route>
                </Route>

                <Route path="/verification/report/personal" element={<ProtectedRoute allowedRole={["VERIFICATION"]} />}>
                    <Route path="/verification/report/personal" element={< PersonalVerificationReport />}></Route>
                </Route>

                {/* Marketing*/}

                <Route path="/marketing/leads/personal" element={<ProtectedRoute allowedRole={["MARKETING"]} />}>
                    <Route path="/marketing/leads/personal" element={<LeadsNormalReport />}></Route>
                </Route>

                <Route path="/marketing/leads/myAssignments" element={<ProtectedRoute allowedRole={["COMERCIAL", "COMERCIAL_ADMIN"]} />}>
                    <Route path="/marketing/leads/myAssignments" element={<LeadsComercialAssignmentReport />}></Route>
                </Route>

                {/* Managers */}

                <Route path="/contract/manager" element={<ProtectedRoute allowedRole={["COMERCIAL", "COMERCIAL_ADMIN"]} />}>
                    <Route path="/contract/manager" element={<ContractManager />}></Route>
                </Route>

                <Route path="/pbo/manager" element={<ProtectedRoute allowedRole={["COMERCIAL", "COMERCIAL_ADMIN"]} />}>
                    <Route path="/pbo/manager" element={<PBOManager />}></Route>
                </Route>

                <Route path="/marketing/leads/manager" element={<ProtectedRoute allowedRole={["MARKETING"]} />}>
                    <Route path="/marketing/leads/manager" element={<LeadsManager />}></Route>
                </Route>

                <Route path="/attendance/manager" element={<ProtectedRoute allowedRole={["ADMIN", "TEACHER", "RECEPTION"]} />}>
                    <Route path="/attendance/manager" element={<AttendanceManager/>}></Route>
                </Route>

                <Route path="/email/manager" element={<ProtectedRoute allowedRole={["ADMIN"]} />}>
                    <Route path="/email/manager" element={<EmailManager/>}></Route>
                </Route>
           
            </Routes>
        </Router>
    );
};

export default RouterManager;
