import React, { useEffect, useState } from 'react'
import { getCookieValue } from '../../../services/cookieService';
import { DailyAttendance, User } from '../../../interface/interface';
import { Container, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, Button } from 'reactstrap';
import { getAttendanceByDate, getTeacherAttendanceByDate } from '../../../controllers/ClassController';
import { handleErrorResponseAlert, genericErrorAlert, genericSuccessAlert } from '../../../services/alertService';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import { dateformatISOFromDate } from '../../../utils/dateUtils';
import LoadingStatus from '../../utils/LoadingStatus';
import AttendanceReport from '../attendance-report/AttendanceReport';

const AttendanceManager = () => {

  const [currentUser, setCurrentUser] = useState<User | null>();

  const [classAttendace, setClassAttendace] = useState<Array<DailyAttendance>>([]);
  const [classCanceledAttendace, setClassCanceledAttendace] = useState<Array<DailyAttendance>>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [attendance, setAttendance] = useState({ date: dateformatISOFromDate(new Date) });

  useEffect(() => {

    loadTodayAttendance();

  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAttendance({ ...attendance, [name]: value });
  };

  const loadTodayAttendance = async () => {

    try {
      setLoading(true);

      const cookie = getCookieValue("nbp-management-auth");
      const { user } = cookie;
      setCurrentUser(user);

      let attendanceList;

      if (user && user.role_id !== 4) {

        attendanceList = await getAttendanceByDate(attendance.date);

      } else {
        attendanceList = await getTeacherAttendanceByDate(attendance.date);

      }

      setClassAttendace(attendanceList.data.attendance);
      setClassCanceledAttendace(attendanceList.data.canceledAttendance)

    } catch (error) {
      handleErrorResponseAlert(error, "Error al cargar la asistencias de hoy", "Intentelo nuevamente");
    } finally {

      setLoading(false)

    }

  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    try {
      setLoading(true);

      let attendanceList;

      if (currentUser && currentUser.role_id !== 4) {
        attendanceList = await getAttendanceByDate(attendance.date);

      } else {
        attendanceList = await getTeacherAttendanceByDate(attendance.date);
      }

      if (attendanceList.data.length === 0) {
        return genericErrorAlert("Información no encontrada", "No existe asistencias asignadas para la fecha (" + attendance.date + ")");
      }

      setClassAttendace(attendanceList.data.attendance);
      setClassCanceledAttendace(attendanceList.data.canceledAttendance)

      genericSuccessAlert("Generación de asistencia exitosa", "")

    } catch (error: any) {

      handleErrorResponseAlert(error, "Error al cargar asistencia", "Intentelo nuevamente");
      console.log(error);

    } finally {
      setLoading(false);
    }
  }

  return (
    <Container fluid className='pb-2'>

      <Card className={CARD_BODY + "mt-3"}>
        <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
          <b>REPORTE DE ASISTENCIA</b>
        </CardHeader>

        <CardBody>
          <Row>
            <Col className='col-12'>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={8} >
                    <FormGroup>

                      <Input type='date' name='date'
                        value={attendance.date}
                        onChange={handleInputChange}
                        required
                      />

                      <FormText>
                        Fecha de asistencia (*)
                      </FormText>

                    </FormGroup>
                  </Col>
                  <Col>
                    <Button type="submit" color="warning" className='col-12' disabled={loading}>
                      <LoadingStatus status={loading} text='Generar asistencia' loadingText='Generando asistencia'></LoadingStatus>
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* Clases sin cancelar */}
      {currentUser && !loading && <AttendanceReport title='Listado de Asistencia - sin cancelar' currentUser={currentUser} classAttendace={classAttendace} setClassAttendace={setClassAttendace}/>}

      {/* Clases canceladas */}
      {currentUser && !loading && <AttendanceReport title='Listado de Asistencia - Canceladas' currentUser={currentUser} classAttendace={classCanceledAttendace} setClassAttendace={setClassCanceledAttendace}/>}


    </Container>
  )
}


export default AttendanceManager
