import React from 'react'
import { HolderWork } from '../../../interface/interface'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'

interface Props {
    holderWork: HolderWork
}

const HolderWorkCard: React.FC<Props> = ({ holderWork }) => {
    return (
        <Card className= {CARD_BODY+'m-3 contract-backgroud'}>
            <CardHeader tag="h4"className={CARD_TITLE} >
                <b>INFORMACIÓN LABORAL DEL TITULAR</b>
            </CardHeader>
            <CardBody>
                <Row>
                    
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input name='name'
                                value={holderWork.name}
                                readOnly />
                            <FormText>
                                Nombre de la empresa 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='position'
                                value={holderWork.position}
                                readOnly
                            />
                            <FormText>
                                Cargo 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='phone_number'
                                value={holderWork.phone_number}
                                readOnly />
                            <FormText>
                                Número Teléfonico de la Empresa 
                            </FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input type="text" name='address'
                                value={holderWork.address}
                                readOnly />
                            <FormText>
                                Dirección de la empresa 
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default HolderWorkCard