import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format';
import { Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import VerificationFormChecked from './VerificationFormChecked';

interface Props {
  type?: "text",
  value: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  readOnly?: boolean;
  formText: string;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const VerificationFormGroupMonetary: React.FC<Props> = ({ type = "text", name, value, onChange, required = false,readOnly=false, formText, md = 12, lg = 12 }) => {

  const [disabled, setDisabled] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setDisabled(!disabled);
  };

  return (

    <div>
      <Col md={md} lg={lg}>
        <FormGroup>
          <Row>
          <Col xs={9} sm={10} md={11}>
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <NumericFormat className='form-control'
                  type={type}
                  name={name}
                  thousandSeparator="."
                  decimalSeparator=','
                  decimalScale={2}
                  value={value + ",00"}
                  allowNegative={false}
                  onChange={onChange}
                  required={required}
                  readOnly={readOnly}
                  disabled={disabled} />
              </InputGroup>
            </Col>
            <VerificationFormChecked disabled={disabled} handleCheckboxChange={handleCheckboxChange} />
          </Row>

          <FormText>
            {formText} {required ? (" (*)") : ("")}
          </FormText>
        </FormGroup>
      </Col>
    </div>

  )
}

export default VerificationFormGroupMonetary
