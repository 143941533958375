import React, { useEffect, useState } from 'react'
import { ProspectMeeting, ProspectReport, User } from '../../../../interface/interface'
import { Card, CardHeader, Alert, Col, CardBody, Row, Table, Button, Collapse } from 'reactstrap';
import { getAllPBOList } from '../../../../controllers/ProspectController';
import { genericErrorAlert } from '../../../../services/alertService';
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService';
import MeetingModalPBO from '../../pbo-modal/MeetingModalPBO';
import NBPProspectRow from '../pbo-report-row/NBPProspectRow';
import PBOFilterManager from '../pbo-report-filter/PBOFilterManager';
import ObservationModalPBO from '../../pbo-modal/ObservationModalPBO';

interface Props {
    user: User
}

const AdminPBO: React.FC<Props> = ({ user }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    {/* Lista completa de prospectos (Original) - Almacena todos los valores */ }
    const [prospectList, setProspectList] = useState<Array<ProspectReport>>([]);

    {/* Lista mostrada al usuario (Filtrada o Original)*/ }
    const [currentProspectList, setCurrentProspectList] = useState<Array<ProspectReport>>([]);

    const [isOpenMeetingModal, setIsOpenMeetingModal] = useState<boolean>();
    const [isOpenObservationModal, setIsObservationModal] = useState<boolean>();

    const [prospectMeetingList, setProspectMeetingList] = useState<Array<ProspectMeeting>>([]);
    const [selectedProspectMeetingList, setSelectedProspectMeetingList] = useState<Array<ProspectMeeting>>([]);
    const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
    const [openProspectDetails, setOpenProspectDetails] = useState<boolean>(true);
    const [currentProspect, setCurrentProspect] = useState("");

    useEffect(() => {

        loadPersonalPBO();

    }, [])

    const toggleObservationModal = () => {

        setIsObservationModal(!isOpenObservationModal);

    };

    const toggleMeetingModal = () => {

        setIsOpenMeetingModal(!isOpenMeetingModal);

    };

    const activateCurrentProspectMeetingListModal = (prospectId: string) => {
        getCurrentProspectMeetingList(prospectId);
        setCurrentProspect(prospectId);
        toggleMeetingModal();
    }

    const activateCurrentObservationListModal = (prospectId: string) => {
        console.log("Testing")
        setCurrentProspect(prospectId);
        toggleObservationModal();
    }

    const getCurrentProspectMeetingList = (prospectId: string | number) => {
        const prospectMeetings = prospectMeetingList.filter(prospectMeeting => prospectMeeting.prospect_id == prospectId)
        setSelectedProspectMeetingList(prospectMeetings);
    }

    const loadPersonalPBO = async () => {

        try {

            const prospectInformation = await getAllPBOList();
            setProspectMeetingList(prospectInformation.data.prospect_meetings);
            setProspectList(prospectInformation.data.prospects);
            setCurrentProspectList(prospectInformation.data.prospects);

        } catch (error: any) {

            const { title, message } = error.response.data;
            genericErrorAlert(title, message);

        } finally {
            setIsLoading(false);
        }

    }

    const updateProspectList = (prospectList: Array<ProspectReport>, prospectUpdated: ProspectReport) => {

        const updatedProspectIndex = prospectList.findIndex((updatedProspect) => updatedProspect.id === prospectUpdated.id);

        if (updatedProspectIndex === -1) {
            return prospectList;
        }

        const updatedProspect = [...prospectList];
        updatedProspect.splice(updatedProspectIndex, 1, prospectUpdated);

        return updatedProspect;
    }

    const updateVisualProspectInformation = async (prospectUpdated: ProspectReport) => {

        setProspectList(updateProspectList(prospectList, prospectUpdated));

        setCurrentProspectList(updateProspectList(currentProspectList, prospectUpdated));

    }

    return (
        <Card className={CARD_BODY + "my-2 nbp-background-logo m-2"}>
            <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
                <b className='p-0 m-0'>LISTADO DE PROSPECTOS</b>
            </CardHeader>

            {
                isLoading ? (
                    <Alert color="warning" className="text-center m-3">
                        <b className="h2">Cargando prospectos...</b>
                    </Alert>) : (prospectList.length === 0 ? (
                        <Col>
                            <Alert color="warning" className="text-center m-3">
                                <b className="h2">No tiene ningun prospecto registrado </b>
                            </Alert>
                        </Col>) : (<CardBody>

                            <div>
                                <Row className='mb-3'>
                                    <Col>
                                        <Button color='danger' className='bg col-12' onClick={() => setVisibleFilter(!visibleFilter)}>
                                            <b> Desplegar filtros </b> {!visibleFilter ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>}
                                        </Button>
                                    </Col>
                                </Row>

                                <Collapse isOpen={visibleFilter}>

                                    <PBOFilterManager prospectList={prospectList} setCurrentProspectList={setCurrentProspectList}></PBOFilterManager>

                                </Collapse>

                                <hr />
                                
                            </div>

                            

                            {currentProspectList.length === 0 ? (<Row>
                                <Col>
                                    <Alert color="warning" className="text-center m-1">
                                        <b className="h2">No se encontraron resultados</b>
                                    </Alert>
                                </Col>
                            </Row>) : (
                                <Card className='py-3'>
                                    <Row>
                                        <Col>
                                            <Table striped responsive className='text-center' hover>
                                                <thead>
                                                    <tr>

                                                        <th className='text-center'>#</th>
                                                        <th className='text-center'>Origen</th>
                                                        <th className='text-center'>Nombre Completo</th>
                                                        <th className='text-center'>Asesor</th>
                                                        <th className='text-center'>Teléfono</th>
                                                        <th className='text-center'>Email</th>
                                                        <th className='text-center'>Profesión</th>
                                                        <th className='text-center'>Decisión</th>
                                                        <th className='text-center'>Nivel de Inglés</th>
                                                        <th className='text-center'>Estado Civil</th>
                                                        <th className='text-center'>Estado</th>
                                                        <th className='text-center'>Reunión</th>
                                                        <th className='text-center'>Observación</th>
                                                        <th className='text-center'>Habilidades</th>
                                                        <th className='text-center'>Opciones</th>

                                                    </tr>
                                                </thead>

                                                {openProspectDetails ? (<tbody>

                                                    {currentProspectList.map((prospect, index) => (
                                                        <NBPProspectRow key={index}
                                                            prospect={prospect}
                                                            index={index}
                                                            user={user}
                                                            toggleModal={activateCurrentProspectMeetingListModal}
                                                            updateVisualProspectInformation={updateVisualProspectInformation}
                                                            activateCurrentObservationListModal={activateCurrentObservationListModal}
                                                            isAdmin />
                                                    ))}
                                                </tbody>) : (null)}

                                            </Table>
                                        </Col>
                                        {isOpenMeetingModal ? <MeetingModalPBO prospectMeetingList={selectedProspectMeetingList} user={user as User} isOpen={isOpenMeetingModal} toggle={toggleMeetingModal} prospectId={currentProspect} /> : null}

                                        {isOpenObservationModal ? <ObservationModalPBO isOpen={isOpenObservationModal} toggle={toggleObservationModal} prospectId={currentProspect} /> : null}

                                    </Row>
                                </Card>)}

                        </CardBody>))
            }

        </Card>

    )
}

export default AdminPBO