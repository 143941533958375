import React from 'react'
import { Reference } from '../../../../../interface/interface'
import { Col, Row } from 'reactstrap'
import ReferenceCard from '../../../../contract/cards/ReferenceCard'
import VerificationReferenceCard from './VerificationReferenceCard'

interface Props {
  referenceOne: Reference
  setReferenceOne: (reference: Reference) => void
  referenceTwo: Reference
  setReferenceTwo: (reference: Reference) => void
}

const VerificationReferenceSection: React.FC<Props> = ({ referenceOne, setReferenceOne, referenceTwo, setReferenceTwo }) => {
  return (
    <Row>
      <Col md={6} lg={6}>
        <VerificationReferenceCard reference={referenceOne} setReference={setReferenceOne} />
      </Col>
      <Col md={6} lg={6}>
         <VerificationReferenceCard reference={referenceTwo} setReference={setReferenceTwo} />
      </Col>
    </Row>
  )
}

export default VerificationReferenceSection
