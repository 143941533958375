import axiosInstance from '../services/axiosInstance';

import { BASE_URL as mainRoute } from '../services/axiosInstance';

export const getLessonByLevelId = async (level_id: number|string) => {
    const lessons = await axiosInstance.get(mainRoute + 'lesson/getLessonByLevelId/'+ level_id )
    return lessons;
} 
export const getAllLessonGroupByLevelId = async () => {
    const lessons = await axiosInstance.get(mainRoute + 'lesson/getAllLessonGroupByLevelId')
    return lessons;
} 

