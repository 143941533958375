
import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';
import { Beneficiary, Contract, Holder, HolderWork, NotePayable, PaymentModality, PaymentSupport, Reference, User } from '../interface/interface';

export const createContract = async (

    user: User,
    holder: Holder,
    holderWork: HolderWork,
    contract: Contract,
    beneficiaryAmount: number,
    beneficiaries: Array<Beneficiary>,
    referenceOne: Reference,
    referenceTwo: Reference,
    paymentModality: PaymentModality,
    paymentSupport: PaymentSupport,
    notePayable: NotePayable) => {

    const cityList = await axiosInstance.post(mainRoute + 'contract/createContract', { user, holder, holderWork, contract, beneficiaryAmount, beneficiaries, referenceOne, referenceTwo, paymentModality, paymentSupport, notePayable })
    return cityList;
}

export const createCourtesyContract = async (

    user: User,
    holder: Holder,
    holderWork: HolderWork,
    contract: Contract,
    beneficiaryAmount: number,
    beneficiaries: Array<Beneficiary>,
    referenceOne: Reference,
    referenceTwo: Reference) => {

    const cityList = await axiosInstance.post(mainRoute + 'contract/createCourtesyContract', { user, holder, holderWork, contract, beneficiaryAmount, beneficiaries, referenceOne, referenceTwo })
    return cityList;
}

export const createContractWithRegisteredHolder = async (

    user: User,
    holder: Holder,
    holderWork: HolderWork,
    contract: Contract,
    beneficiaryAmount: number,
    beneficiaries: Array<Beneficiary>,
    referenceOne: Reference,
    referenceTwo: Reference,
    paymentModality: PaymentModality,
    paymentSupport: PaymentSupport,
    notePayable: NotePayable) => {

    const cityList = await axiosInstance.post(mainRoute + 'contract/createContractWithRegisteredHolder', { user, holder, holderWork, contract, beneficiaryAmount, beneficiaries, referenceOne, referenceTwo, paymentModality, paymentSupport, notePayable })
    return cityList;
}

export const createCourtesyContractWithRegisteredHolder = async (

    user: User,
    holder: Holder,
    holderWork: HolderWork,
    contract: Contract,
    beneficiaryAmount: number,
    beneficiaries: Array<Beneficiary>,
    referenceOne: Reference,
    referenceTwo: Reference) => {

    const cityList = await axiosInstance.post(mainRoute + 'contract/createCourtesyContractWithRegisteredHolder', { user, holder, holderWork, contract, beneficiaryAmount, beneficiaries, referenceOne, referenceTwo })
    return cityList;
}

export const getAllContractInformationById = async (contract_id: string | number) => {
    return await axiosInstance.get(mainRoute + 'contract/getAllContractInformationById/' + contract_id);
}

export const getAllContracts = async () => {
    const contractList = await axiosInstance.get(mainRoute + 'contract/getAllContracts');
    return contractList;
}

export const getPersonalUserContracts = async () => {
   return await axiosInstance.get(mainRoute + 'contract/getPersonalUserContracts');
}

export const getContractById = async (contract_id: string) => {

    const contract = await axiosInstance.post(mainRoute + 'contract/getContractById', { contract_id: contract_id });
    return contract;

}

export const changeContractStatus = async (contract_id: string, new_status: string) => {

    const contract = await axiosInstance.put(mainRoute + 'contract/changeContractStatus', { contract_id: contract_id, new_status: new_status });
    return contract;

}

export const getUnverifiedContractsAssigned = async () => {

    return await axiosInstance.get(mainRoute + 'contract/getUnverifiedContractsAssigned');

}