import { useContext, useEffect } from 'react'

import '../../styles/homeStyles.css'
import { AuthContextType, User } from '../../interface/interface'
import { redirect, useNavigate } from 'react-router-dom';
import { getCookieValue } from '../../services/cookieService';
import { validateToken } from '../../services/authService';
import Swal from 'sweetalert2';
import { Button } from 'reactstrap';



interface Props {
    token: string | null
    setToken: (token: string | null) => void
    user: User | null
    setUser: (user: User | null) => void
}

const Home: React.FC<Props> = ({ token, setToken, user, setUser }) => {

    const navigate = useNavigate();

    useEffect(() => {

        const cookie = getCookieValue("nbp-management-auth");


        if (!cookie) {
            navigate("/");;
        }

        validateToken("Home");

    }, [])


    return (
        <div className='col-12'>
            <div className="p-3 text-center nbp-background-logo home-principal-container">
                {/**<button onClick={() => console.log(getCookieValue("nbp-mansagement-auth"))}>Test</button>*/}
                <div className="container-fluid ">
                    <h2 className="display-4">Bienvenido a<b> {process.env.REACT_APP_OWNER} - MANAGEMENT</b></h2>
                    <p className="lead">Portal de administración de la compañía</p>
                    <hr className="my-2" />
                    <p>Aquí encontrarás las herramientas y recursos necesarios para administrar eficientemente el funcionamiento de la compañía.</p>
                    <div className="text-center">
                        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} style={{width:"40rem"}} className="logo" alt="logo" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Home