import { useState, useEffect } from "react";
import { Alert, Button, Card, CardBody, CardHeader, Col, Collapse, Row, Table } from "reactstrap";
import { getAllContracts } from "../../../controllers/ContractController";
import ContractModalInformation from "../contract-modal/ContractModalInformation";
import { User } from "../../../interface/interface";
import ContractStatusInfo from "../contract-status/ContractStatusInfo";
import { CARD_BODY, CARD_TITLE } from "../../../services/classService";
import { genericErrorAlert } from "../../../services/alertService";
import ManualCloseAlert from "../../alerts/ManualCloseAlert";
import { getAllCheckerUser } from "../../../controllers/VerifyAssigmentController";

interface Props {
  token: string | null
  setToken: (token: string | null) => void
  user: User | null
  setUser: (user: User | null) => void
}

const AdminContract: React.FC<Props> = ({ user }) => {

  const [contractList, setContractList] = useState<Array<any>>([]);
  const [modalOpenState, setModalOpenState] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState({ contractSerial: "", contractId: "", contractStatus: "", userFullName: "" });
  const [loadding, setLoadding] = useState<boolean>(true);
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);

  const [checkerList, setCheckerList] = useState([]);
  useEffect(() => {

    loadCheckerList()

  }, [])

  const loadCheckerList = async () => {

    if (user?.role_id === 6) {
      return;
    }

    try {

      const checkerListeValues = await getAllCheckerUser();
      setCheckerList(checkerListeValues.data.user_list);

    } catch (error) {

      genericErrorAlert("Error al cargar los verificadores", "Recargar la página")
      console.log(error);

    }

  }


  function formatISODate(inputDate: string) {

    const dateObject = new Date(inputDate);

    const year = dateObject.getUTCFullYear();
    const month = `0${dateObject.getUTCMonth() + 1}`.slice(-2);
    const day = `0${dateObject.getUTCDate()}`.slice(-2);

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    loadContractList();
  }, []);

  const loadContractList = async () => {
    try {
      const contractListData = await getAllContracts();
      const contracts = contractListData.data.contracts;
      setContractList(contracts);

    } catch (error) {
      genericErrorAlert("Error al cargar contratos", "Intentelo nuevamente")
      console.log(error)
    } finally {
      setLoadding(false);
    }
  };

  const toggleModal = async () => {

    setModalOpenState(!modalOpenState);

  };

  const setValues = (contractId: string, contractSerial: string, contractStatus: string, userFullName: string) => {

    setModalValues({ contractId: contractId, contractSerial: contractSerial, contractStatus: contractStatus, userFullName: userFullName })

    toggleModal()
  }


  return (
    <Card className={CARD_BODY + "my-2 nbp-background-logo m-2"}>
      <CardHeader className={CARD_TITLE + "text-center"} tag="h5">
        <b className='p-0 m-0'>LISTADO DE CONTRATOS</b>
      </CardHeader>
      {
        loadding ? (
          <Alert color="warning" className="text-center m-3">
            <b className="h2">Cargando contratos...</b>
          </Alert>) :
          <CardBody className="m-3">
            <div style={{ display: "none" }}>
              <Row className='mb-3'>
                <Col>
                  <Button color='danger' className='bg col-12' onClick={() => setVisibleFilter(!visibleFilter)}>
                    <b> Desplegar filtros </b> {!visibleFilter ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>}
                  </Button>
                </Col>
              </Row>
              <Collapse isOpen={visibleFilter}>
                <ManualCloseAlert color="warning" text="El sistema de filtrado de contratos no se encuentra disponible actualmente."></ManualCloseAlert>
              </Collapse>
            </div>
            <Card className="py-3">
              <Row >
                <Col>
                  <Table striped responsive className='text-center' hover>
                    <thead>
                      <tr>
                        <th className='text-center'>#</th>
                        <th className='text-center'>N° de contrato</th>
                        <th className='text-center'>Tipo</th>
                        <th className='text-center'>Estado</th>
                        <th className='text-center'>Fecha</th>
                        <th className='text-center'>Identificación (Empleado)</th>
                        <th className='text-center'>Empleado</th>
                        <th className='text-center'>Identificación (Titular)</th>
                        <th className='text-center'>Titular</th>
                        <th className='text-center'>Opciones</th>
                      </tr>
                    </thead>
                    <tbody>

                      {contractList?.map((contract, index) =>
                      (<tr key={index}>
                        <td>
                          <p><b>{index + 1}</b></p>
                        </td>
                        <td>
                          <p>{contract.serial}</p>
                        </td>
                        <td>
                          <p>{contract.type}</p>
                        </td>
                        <td>
                          {<ContractStatusInfo contractStatus={contract.status} contractId={contract.id} contractSerial={contract.serial} canEdit={user?.role_id === 1 ? true : false} checkerList={checkerList} contractList={contractList} setContractList={setContractList} />}
                        </td>
                        <td>
                          {formatISODate(contract.created_at)}
                        </td>
                        <td>
                          <p>{contract.user_document_number + "- (" + contract.user_document_type + ")"}</p>
                        </td>
                        <td>
                          <p>
                            {contract.user_first_name + " " + contract.user_second_name + " " + contract.user_last_name + " " + contract.user_second_last_name}
                          </p>
                        </td>
                        <td>
                          <p>{contract.holder_document_number + " - (" + contract.holder_document_type + ")"}</p>
                        </td>
                        <td>
                          <p>{contract.holder_first_name + " " + contract.holder_second_name + " " + contract.holder_last_name + " " + contract.holder_second_last_name}</p>
                        </td>
                        <td>
                          <Button color="primary" id={contract.id} onClick={() => setValues(contract.id, contract.serial, contract.status, (contract.user_first_name + " " + contract.user_second_name + " " + contract.user_last_name + " " + contract.user_second_last_name))}>
                            <i className="bi bi-zoom-in"></i>
                          </Button>
                        </td>
                      </tr>)
                      )}
                    </tbody>
                  </Table>
                </Col>

              </Row>
            </Card>
          </CardBody>
      }


      {modalOpenState ? <ContractModalInformation canEdit={user?.role_id === 1 ? true : false} contractStatus={modalValues.contractStatus} userFullName={modalValues.userFullName} toggleModal={toggleModal} contractId={modalValues.contractId} contractSerial={modalValues.contractSerial} isOpen={modalOpenState} /> : null}

    </Card>
  );
};

export default AdminContract;
