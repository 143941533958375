import { MDBNavbarNav, MDBNavbarItem, MDBNavbarLink } from 'mdb-react-ui-kit'
import React from 'react'
import navOptions, { navIcons } from '../narvbar-options/NavbarOptions'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink, NavItem, Button } from 'reactstrap'
import { Link } from 'react-router-dom'


interface Props {
    handleLogout: () => void
}


const NotRoleNavbar: React.FC<Props> = ({ handleLogout }) => {
    return (
        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 fw-bold'>
            
            <NavItem>
            <Button color='danger' className='col-12'><b>ROLE NOT FOUND</b></Button>
            </NavItem>

            <MDBNavbarLink className='d-flex justify-content-between' onClick={handleLogout} style={{ cursor: 'pointer' }}><div><b>{navOptions.logout} <i className={navIcons.logout}></i> </b></div></MDBNavbarLink>

        </MDBNavbarNav>
    )
}

export default NotRoleNavbar