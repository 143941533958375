import React from 'react'
import { Input } from 'reactstrap';

interface Props {
    value: string;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    disabled?: boolean;
    editMode?: boolean
}

const PBOInputStatus: React.FC<Props> = ({ name, value, onChange, required = false, disabled = false, editMode = false }) => {

    return (
        <Input type='select' name={name} value={value} onChange={onChange} required={required} disabled={disabled}>
            <option value="">Seleccione un estado</option>
            <option value="No interesado">No interesado</option>
            <option value="Llamar mas tarde">Llamar mas tarde</option>
            <option value="Agendado">Agendar Cita</option>
            <option value="Ya esta en otro programa">Ya esta en otro programa</option>
            <option value="Ya habla ingles">Ya habla ingles</option>
            {editMode ? ( <option value="Matriculado">Matriculado</option>):(null)} 
        </Input>
    )
}

export default PBOInputStatus
