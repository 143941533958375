import React from 'react'
import { Card, CardHeader, CardBody, Button, Col } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'

const ScheduleActivityForm = () => {
    return (
        <Card className={CARD_BODY + 'm-2 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE + 'text-center'} >
                <b>AGENDAR ACTIVIDAD</b>
            </CardHeader>
            <CardBody>
                <Col md={12} >
                    <Button className='btn col-12' color='success' type='submit'><b>Guardar Actividad</b></Button>
                </Col>
            </CardBody>
        </Card>
    )
}

export default ScheduleActivityForm