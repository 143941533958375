import React, { useState } from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, FormText, Input, Button } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../services/classService'
import { Leads } from '../../../../interface/interface';
import { genericErrorAlert, genericSuccessAlert } from '../../../../services/alertService';
import { createLeads } from '../../../../controllers/LeadsController';

interface Props {
    loadUnssignmentLeads : () => Promise<void>
}

const LeadsForm: React.FC<Props> = ({ loadUnssignmentLeads  }) => {

    const defaultLeadsValues: Leads = {
        first_name: "",
        second_name: "",
        last_name: "",
        second_last_name: "",
        phone_number: "",
        email: "",
        origin: "",
        observation: ""
    }

    const [leadsValues, setLeadsValues] = useState<Leads>(defaultLeadsValues);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLeadsValues({
            ...leadsValues, [name]: value
        });
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        try {

            setIsSaving(true);

            await createLeads(leadsValues);

            setLeadsValues(defaultLeadsValues);

            await loadUnssignmentLeads();

            genericSuccessAlert("Información guardada", "La información fue guardada satisfactoriamente.");

        } catch (error) {

            console.log(error);
            genericErrorAlert("Error al guardar información", "No se pudo guardar la información");

        } finally {

            setIsSaving(false);

        }

    };


    return (
        <Card className={CARD_BODY + 'mx-2 contract-backgroud'}>

            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>CREAR LEADS</b>
            </CardHeader>

            <CardBody>
                <form onSubmit={handleSubmit}>

                    <Row>
                        <Col md={6} lg={3}>
                            <FormGroup>
                                <Input name='first_name'
                                    value={leadsValues.first_name}
                                    onChange={handleInputChange}
                                    required />
                                <FormText >
                                    Primer Nombre (*)
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col md={6} lg={3}>
                            <FormGroup>
                                <Input name='second_name'
                                    value={leadsValues.second_name}
                                    onChange={handleInputChange}
                                />
                                <FormText >
                                    Segundo nombre
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col md={6} lg={3}>
                            <FormGroup>
                                <Input name='last_name'
                                    value={leadsValues.last_name}
                                    onChange={handleInputChange}
                                />
                                <FormText >
                                    Primer apellido
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col md={6} lg={3}>
                            <FormGroup>
                                <Input name='second_last_name'
                                    value={leadsValues.second_last_name}
                                    onChange={handleInputChange}
                                />
                                <FormText >
                                    Segundo apellido
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} lg={6}>
                            <FormGroup>
                                <Input
                                    name='phone_number'
                                    value={leadsValues.phone_number}
                                    onChange={handleInputChange}
                                    required />
                                <FormText >
                                    Número de contacto (*)
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col md={6} lg={6}>
                            <FormGroup>
                                <Input
                                    type='email'
                                    name='email'
                                    value={leadsValues.email}
                                    onChange={handleInputChange}
                                />
                                <FormText >
                                    Correo electronico
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col md={12} >
                            <FormGroup>
                                <Input
                                    name='origin'
                                    value={leadsValues.origin}
                                    onChange={handleInputChange}
                                    required />
                                <FormText >
                                    Origen(*)
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col md={12} >
                            <FormGroup>
                                <Input
                                    type='textarea'
                                    name='observation'
                                    value={leadsValues.observation}
                                    onChange={handleInputChange}
                                    required />
                                <FormText >
                                    Observaciones (*)
                                </FormText>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <Button type='submit' color='warning' className=' col-12 text-center mb-2' disabled={isSaving}><b>{!isSaving ? "Guardar Información" : "Guardando Información..."}</b></Button>
                        </Col>
                    </Row>

                </form>

            </CardBody>
        </Card>
    )
}

export default LeadsForm