import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, InputGroup, InputGroupText } from 'reactstrap'
import { PaymentModality} from '../../../interface/interface'
import { NumericFormat } from 'react-number-format'
import { CARD_BODY, CARD_TITLE } from '../../../services/classService'

interface Props {
    paymentModality: PaymentModality
}

const PaymentModalityCard: React.FC<Props> = ({ paymentModality }) => {

    return (
        <Card className= {CARD_BODY+'m-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>MODALIDAD DE PAGO</b>
            </CardHeader>
            <CardBody>

                <Row>
                    <Col lg={12}>

                        <FormGroup className="form-outline mb-4">
                            <Input name='plan'
                                type='text'
                                min={0}
                                value={paymentModality.plan_type}
                                readOnly />

                            <FormText>
                                Tipo de plan 
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='total'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    value={paymentModality.total+",00"}
                                    allowNegative={false}
                                    readOnly
                                    required />
                            </InputGroup>
                            <FormText>
                                Total 
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='enrollment'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    value={paymentModality.enrollment+",00"}
                                    allowNegative={false}
                                    readOnly
                                    required />
                            </InputGroup>
                            <FormText>
                                Valor Matricula 
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='balance'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    value={paymentModality.balance+",00"}
                                    allowNegative={false}
                                    readOnly
                                    required />
                            </InputGroup>
                            <FormText>
                                Saldo 
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input name='installments_number'
                                type='number'
                                min={0}
                                value={paymentModality.installments_number}
                                readOnly />
                            <FormText>
                                N° de cuotas 
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='installments_number'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    value={paymentModality.installments_value+",00"}
                                    allowNegative={false}
                                    readOnly
                                    required />
                            </InputGroup>
                            <FormText>
                                Valor de cuotas 
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>

            </CardBody>
        </Card>
    )
}

export default PaymentModalityCard