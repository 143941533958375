import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText } from 'reactstrap'
import { CARD_BODY, CARD_TITLE } from '../../../../../services/classService'
import { Reference } from '../../../../../interface/interface'
import VerificationFormGroupText from '../../verification-form-inputs/VerificationFormGroupText'
import VerificationFormGroupGender from '../../verification-form-inputs/VerificationFormGroupGender'

interface Props {
    reference: Reference
    setReference: (reference: Reference) => void
}

const VerificationReferenceCard: React.FC<Props> = ({ reference, setReference }) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        console.log(name,value)
        setReference({
            ...reference, [name]: value
        });
    };

    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>Referencia</b>
            </CardHeader>
            <CardBody>
                <Row>

                    <VerificationFormGroupText
                        type='text'
                        md={12} lg={12}
                        name='first_name'
                        value={reference.first_name}
                        onChange={handleInputChange}
                        formText='Primer nombre'
                        required
                    />
                    <VerificationFormGroupText
                        type='text'
                        md={12} lg={12}
                        name='second_name'
                        value={reference.second_name}
                        onChange={handleInputChange}
                        formText='Segundo nombre'
                    />

                    <VerificationFormGroupText
                        type='text'
                        md={12} lg={12}
                        name='last_name'
                        value={reference.last_name}
                        onChange={handleInputChange}
                        formText='Primer Apellido'
                        required
                    />

                    <VerificationFormGroupText
                        type='text'
                        md={12} lg={12}
                        name='second_last_name'
                        value={reference.second_last_name}
                        onChange={handleInputChange}
                        formText='Segundo Apellido'
                        required
                    />

                    <VerificationFormGroupText
                        type='text'
                        md={12} lg={12}
                        name='profession'
                        value={reference.profession}
                        onChange={handleInputChange}
                        formText=' Profesión'
                        required
                    />

                    <VerificationFormGroupText
                        type='text'
                        md={12} lg={12}
                        name='phone_number'
                        value={reference.phone_number}
                        onChange={handleInputChange}
                        formText='Número de teléfono'
                        required
                    />

                    <VerificationFormGroupGender
                        type='select'
                        md={12} lg={12}
                        name="gender"
                        value={reference.gender}
                        onChange={handleInputChange}
                        formText='Género'
                        required
                    />

                </Row>
            </CardBody>
        </Card>
    )
}

export default VerificationReferenceCard
