
import axiosInstance, { BASE_URL as mainRoute } from '../services/axiosInstance';

export const getAllDefaultPlans = async () => {
    const defaultPlans = await axiosInstance.get(mainRoute + 'defaultPlans/getAllDefaultPlans')
    return defaultPlans;
} 

export const getActiveDefaultPlans = async () => {
    const defaultPlans = await axiosInstance.get(mainRoute + 'defaultPlans/getActiveDefaultPlans')
    return defaultPlans;
} 