import axiosInstance from '../services/axiosInstance';

import { BASE_URL as mainRoute } from '../services/axiosInstance';

export const assignmentLeads = async (assignment_leads: Array<number>, user_document_number: string, user_document_type: string) => {
    return await axiosInstance.post(mainRoute + 'leadsAssignment/assignment', { assignment_leads: assignment_leads, assignment_document_number: user_document_number, assignment_document_type: user_document_type });
}

export const getAllPersonalAssignmentLeads = async () => {
    return await axiosInstance.get(mainRoute + 'leadsAssignment/getAllPersonalAssignmentLeads');
}

export const getAllPersonalUnassignmentLeads = async () => {
    return await axiosInstance.get(mainRoute + 'leadsAssignment/getAllPersonalUnassignmentLeads');
}

