import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, FormText, Button, Alert, ModalBody } from 'reactstrap'
import { Holder, HolderWork, User } from '../../../interface/interface';
import { useState } from 'react'
import { getHolderByDocument } from '../../../controllers/HolderController';
import { completeAllInputsAlert, holderLoadedSuccessAlert, holderNotFoundAlert } from '../../../services/alertService';
import { getHolderWorkByDocument } from '../../../controllers/HolderWorkController';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
interface Props {
    holder: Holder;
    setHolder: (holder: Holder) => void;
    holderType: string
    setHolderType: (holderType: string) => void;
    setHolderWork: (holderWork: HolderWork) => void;
}

const HolderTypeForm: React.FC<Props> = ({ holder, setHolder, holderType, setHolderType, setHolderWork }) => {

    const [newHolderValues, setNewHolderValues] = useState({ "document_number": "", "document_type": "CC" });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewHolderValues({
            ...newHolderValues, [name]: value
        });


    };

    const handleInputSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setHolderType(value);

        if (value === "new") {
            setHolder({
                document_number: "",
                document_type: "CC",
                gender: "Masculino",
                first_name: "",
                second_name: "",
                last_name: "",
                second_last_name: "",
                birthdate: "",
                phone_number: "",
                whatsapp_number: "",
                address: "",
                email: "",
                salary: 0

            })

            setHolderWork({
                name: "",
                position: "",
                address: "",
                phone_number: ""
            })

            setNewHolderValues({ "document_number": "", "document_type": "CC" });
        }
    };

    function formatISODate(inputDate: string) {

        const dateObject = new Date(inputDate);

        const year = dateObject.getUTCFullYear();
        const month = `0${dateObject.getUTCMonth() + 1}`.slice(-2);
        const day = `0${dateObject.getUTCDate()}`.slice(-2);

        return `${year}-${month}-${day}`;
    }


    const loadHolderInformation = async () => {

        try {
            if (newHolderValues.document_type.trim() === "" || newHolderValues.document_number.trim() === "") {
                completeAllInputsAlert();
                return
            }

            setIsLoading(true);
            
            const holder = await getHolderByDocument(newHolderValues.document_type, newHolderValues.document_number)
            const holderWork = await getHolderWorkByDocument(newHolderValues.document_type, newHolderValues.document_number)

            const holderValues = holder.data.holder[0] as Holder;
            const holderWorkValues = holderWork.data.holder_work[0]

            if (holderValues) {

                setHolder({ ...holderValues, birthdate: formatISODate(holderValues.birthdate) });
                setHolderWork(holderWorkValues);
                holderLoadedSuccessAlert()

            } else {
                holderNotFoundAlert()
                setHolder({
                    document_number: "",
                    document_type: "CC",
                    gender: "Masculino",
                    first_name: "",
                    second_name: "",
                    last_name: "",
                    second_last_name: "",
                    birthdate: "",
                    phone_number: "",
                    whatsapp_number: "",
                    address: "",
                    email: "",
                    salary: 0
                })
            }
        } catch (error) {
            console.log(error + "")
        } finally {
            setIsLoading(false);
        }


    }

    return (
        <Card className={CARD_BODY + ' m-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE}>
                <b>Tipo de Titular</b>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={12} >
                        <FormGroup>
                            <Input type="select" name="payment_method" id="payment_method" value={holderType} onChange={handleInputSelect}>
                                <option value="new">Nuevo</option>
                                <option value="registered">Ya registrado</option>
                            </Input>
                            <FormText>
                                Tipo de titular
                            </FormText>
                        </FormGroup>

                        {holderType === "registered" ? (<Row>
                            <Col md={12} lg={6}>

                                <FormGroup className="form-outline mb-4">
                                    <Input type="select" name="document_type" value={newHolderValues.document_type} onChange={handleInputChange}>

                                        <option value="CC">(C.C) Cédula de Ciudadania</option>
                                        <option value="CE">(C.E) Cédula de Extranjería</option>
                                        <option value="TI">(T.I) Tarjeta de Identidad</option>
                                        <option value="PA">(P.A) Pasaporte</option>

                                    </Input>
                                    <FormText>
                                        Tipo de documento de identidad (*)
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Input type="text" name='document_number'
                                        value={newHolderValues.document_number}
                                        onChange={handleInputChange}
                                    />
                                    <FormText>
                                        Número de documento (*)
                                    </FormText>
                                </FormGroup>
                            </Col>
                            {isLoading ? (<ModalBody><Alert color="warning" className="text-center m-3">
                                <b className="h2">Cargando Información del Titular...</b>
                            </Alert></ModalBody>) : null}

                            <Button onClick={() => loadHolderInformation()} type="button" color='warning'><b>Cargar Información del Titular ya registrado</b></Button>

                        </Row>) : (null)}

                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default HolderTypeForm